.headingFeedLabel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.postCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 3px 6px #c7a58f0d;
  border: 0.5px solid #c6c6c6;
  border-radius: 15px;
  padding: 18px;
  background: #fff;
  margin-bottom: 28px;
}

.posttitle {
  font-weight: 700;
  font-size: 14px;

  padding-left: 10px;
  display: flex;
  align-items: center;
}

.postContent {
  color: #040100;
  display: flex;
  font-size: 16px;
  padding: 0 10px 20px;
  letter-spacing: 0.5px;
}
.postActualContent >span{
  display: -webkit-box; 
            -webkit-box-orient: vertical; 
            -webkit-line-clamp: 5; 
            overflow: hidden; 
            text-overflow: ellipsis;
}

.headtop {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  font-family: "poppins", sans-serif;
  margin-bottom: 10px;
  margin-top: 20px;
  /* text-transform: uppercase; */
}

.postContentWrapper {
  width: 100%;
}

.postsImage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 120px;
  padding-bottom: 10px;
}

.video {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.image {
  height: 100%;
  border-radius: 10px;
  transition: transform 0.3s ease;
  object-fit: cover;
}

.feeds-img-video-container1 {
  /* width: 95%; */
  width: 100%;
  padding: 0;
  min-height: 250px;
  margin-top: 5px;
}

.display-img-video {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: rgba(22, 22, 22, 0.68);
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 10px;
  transition: transform 0.3s ease 0s;
}

.display-img-video:hover,
.image:hover {
  transform: scale(1.02);
}

.post-comments {
  cursor: pointer;
  font-weight: 400;
  color: black;
  display: flex;
  align-items: center;
}

.post-comments:hover {
  /* border-bottom: 1px solid #885d3d; */
}

.comment-content {
  background-color: #FFF0E8;
}

.prev-icon {
  position: absolute;
  left: 0;
  font-size: 20px;
  cursor: pointer;
  color: #9c9fa4;
  z-index: 10;
  padding-left: 10px;
}

.next-icon {
  position: absolute;
  right: 0;
  font-size: 20px;
  cursor: pointer;
  color: #9c9fa4;
  padding-right: 10px;
}

.feedsPostContentFontSize {
  font-size: 22px;
  margin: 20px 10px;
}
.feedsPostContentFontSize>span{
  max-height: 350px;
  overflow-y: scroll;
}
.feedsPostContentFontSize > span::-webkit-scrollbar {
  display: none;
}

.dropdown-menu {
  position: absolute;
  right: 0;

}

.downArrow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.downArrow img {
  height: 10px;
}

.commenticon {
  font-size: 1.25rem;
  /* 20px */
  line-height: 1.75rem;
  /* 28px */
}

.commentCount {
  font-size: 1.125rem;
  /* 18px */
  line-height: 1.75rem;
  /* 28px */
}

.noPostsText {
  padding-top: 1.25rem;
  /* 20px */
  font-size: 1.5rem;
  /* 24px */
  line-height: 2rem;
  /* 32px */
}

.noPostsThumbnail {
  width: 100px;
  height: 100px;
}

@keyframes antSlideUp {
  0% {
    transform: translateY(100%);
    transformorigin: 0% 0%;
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    transformorigin: 0% 0%;
    opacity: 1;
  }
}

@keyframes antSlideOut {
  0% {
    transform: translateY(0);
    transformorigin: 0% 0%;
    opacity: 1;
  }

  100% {
    transform: translateY(100%);
    transformorigin: 0% 0%;
    opacity: 0;
  }
}

.ant-modal-slide-up-enter,
.ant-modal-slide-up-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-play-state: paused;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  /* animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); */
}

.ant-modal-slide-up-leave {
  animation-play-state: paused;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  /* animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1); */
}

.ant-modal-slide-up-enter.ant-modal-slide-up-enter-active,
.ant-modal-slide-up-appear.ant-modal-slide-up-appear-active {
  animation-name: antSlideUp;
  animation-play-state: running;
}

.ant-modal-slide-up-leave.ant-modal-slide-up-leave-active {
  animation-name: antSlideOut;
  animation-play-state: running;
  pointer-events: none;
}

.radio-group .ant-radio-button-wrapper.radio-group-show.ant-radio-button-wrapper-checked {
  background-color: #9fe79f !important;
  border-color: #9fe79f !important;
  color: #000 !important;
}

.radio-group .ant-radio-button-wrapper.radio-group-hide.ant-radio-button-wrapper-checked {
  background-color: #ffa9a9 !important;
  border-color: #ffa9a9 !important;
  color: #000 !important;
}

.postProfileImage > span >img{
  width: 3rem; /* 48px */
  height: 3rem; /* 48px */
  object-fit: cover;
}
.flag-button .flagknobs:before {
  content: "";
  position: absolute;
  top: 4px;
  right: 4px;
  width: 26px;
  height: 26px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: #ffffff;
  border-radius: 50%;
  transition:
    0.3s ease all,
    right 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

/* 
.flag-button .flag-checkbox:active + .flagknobs:before {
  width: 46px;
  border-radius: 100px;
}

.flag-button .flag-checkbox:checked:active + .flagknobs:before {
  margin-left: -26px;
}

.flag-button .flag-checkbox:checked + .flagknobs:before {
  content: "";
  left: 6px;
  background-color: #ffffff;
} */


.flag-button .flag-checkbox:checked+.flagknobs:before {
  content: "";
  right: 43px;
  background-color: #ffffff;
}

/* .flag-button .flag-checkbox:checked ~ .flaglayer {
  background-color: #d7d7d7;
} */

/* .toggle-button-cover {
  display: table-cell;
  position: relative;
  width: 300px;
  height: 140px;
  box-sizing: border-box;
} */

/* .button-cover {
  height: 100px;
  margin: 20px;
  background-color: #fff;
  box-shadow: 0 10px 20px -8px #7e7e7e;
  border-radius: 4px;
}

.button-cover:before {
  counter-increment: button-counter;
  content: counter(button-counter);
  position: absolute;
  right: 0;
  bottom: 0;
  color: #7e7e7e;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
} */

/* .button-cover, */
.flagknobs,
.flaglayer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.flag-button {
  position: relative;
  top: 50%;
  /* width: 74px;
  height: 36px; */
  width: 74px;
  height: 33px;
  margin: -20px auto 0 auto;
  overflow: hidden;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12) !important;
}

.flag-button.r,
.flag-button.r .flaglayer {
  border-radius: 100px;
}

.flag-button.b2 {
  border-radius: 2px;
}

.flag-checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.flagknobs {
  z-index: 2;
}

.flaglayer {
  width: 100%;
  background-color: #d7d7d7;
  transition: 0.3s ease all;
  z-index: 1;
}

.flagimg {
  position: absolute;
  top: 9px;
  left: 10px;
  z-index: 2;
  transition: transform 0.3s ease;
}

.move-right .flagimg {
  left: 50px;
}

.move-right .flaglayer {
  background-color: #ffbdbd;
}

.reasonContainer{
  color: red;
  border: 1px solid red;
  border-radius: 20px;
  margin: 0 5px;
  padding: 0 5px;
}

.createPostButton {
  font-weight: 500;
  background: #686f2e;
  color: white;
  font-size: 19px;
  text-align: center;
  width: 130px;
  height: 50px;
  float: right;
}
.createPostButton:hover {
  background: #686f2e !important;
}
.postcreateButton {
  border-radius: 18px;
  width: 80px;
  height: 80px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* border: 3px solid red; */
  background: linear-gradient(to bottom, #f0f0f0, #e1e1e1);
  transition: transform 200ms;
  margin-top: 5px;
}

.postcreateButton:hover {
  transform: scale(1.06);
}
.plusButtonSize{
  font-size: 45px;
}
.tagCategoryList{
  /* border: 0.5px solid #a1a1a1; */
  border-radius: 5px;
  padding: 5px 12px;
  margin: 0px 2.5px 2.5px 2.5px;
  font-size: 18px;
  /* background-image: linear-gradient(300deg, #804C31, #67702E); */
  /* color:rgb(0, 0, 0); */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 5px!important;
  font-weight: lighter;
}
.tagList{
  /* border: 0.5px solid #a1a1a1; */
  border-radius: 15px;
  padding: 4px 12px;
  margin: 0px 2.5px 2.5px 2.5px;
  font-size: 14px;
  /* background-image: linear-gradient(300deg, #804C31, #67702E); */
  /* color:rgb(0, 0, 0); */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 5px!important;
  font-weight: lighter;
}


.headingContainerWidth{
  width: 90%;
}



@media(max-width:767px) {
  .tagsContainerWidth{
    max-width: 25rem;
    /* max-width: 21.2rem; */
  }
  .headingContainerWidth{
    width: 100%;
  }
  .postCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 2px 2px 6px 0px #c7a58f0d;
    border: 0.5px solid #dfdfdf;
    border-radius: 15px;
    padding: 18px 5px;
    background: #fbf9f0;
    margin-bottom: 28px;
  }

  .communityPreviewpopupModal.modalpopup .ant-modal-content .ant-modal-close {
    display: none;
  }

  .commenticon {
    height: 23px;
  }

  .feedsPostContentFontSize {
    font-size: 15px;
  }

  .comment-content {
    min-width: 70%;
    padding: 5px 10px;
    font-size: 15px;
  }

  .time-ago-format {
    font-size: 13px;
  }

  .commentinfo {
    padding-top: 15px;
    padding-bottom: 15px;
    ;
  }
  .postContent {
    color: #040100;
    display: flex;
    font-size: 16px;
    padding: 0 0px 20px 0px;
    letter-spacing: 0.5px;
  }
}

@media (min-width: 400px ) and (max-width:766px){
    .tagsContainerWidth{
    max-width: 24rem;
  }
}
@media (min-width: 361px ) and (max-width:400px){
    .tagsContainerWidth{
    max-width:22.8rem;
  }
}

@media (min-width:1200px) and (max-width:1500px) {
  .postCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 3px 6px #c7a58f0d;
    border: 0.5px solid #c6c6c6;
    border-radius: 15px;
    padding: 1rem;
    background: #fff;
    margin-bottom: 1.5rem;
  }

  .posttitle {
    font-weight: 700;
    font-size: 0.65rem;
    padding-left: 10px;
    display: flex;
    align-items: center;
  }

  .postContent {
    color: #040100;
    display: flex;
    font-size: 0.8rem;
    padding: 0 10px 1rem;
    letter-spacing: 0.5px;
  }

  .commenticon {
    font-size: 1rem;
    /* 20px */
    line-height: 1.75rem;
    /* 28px */
  }

  .commentCount {
    font-size: 1rem;
    /* 18px */
    line-height: 1rem;
    /* 28px */
  }

  .noPostsText {
    padding-top: 1.25rem;
    /* 20px */
    font-size: 1.2rem;
    /* 24px */
    line-height: 2rem;
    /* 32px */
  }

  .noPostsThumbnail {
    width: 5rem;
    height: 5rem;
  }

  .time-ago-format {
    font-size: 0.7rem;
  }

  .feedsPostContentFontSize {
    font-size: 1rem;
    margin: 20px 10px;
  }
    .postProfileImage > span >img{
      width: 2rem; /* 48px */
      height: 2rem; /* 48px */
      object-fit: cover;
    }
    .createPostButton {
      font-weight: 500;
      background: #686f2e;
      color: white;
      font-size: 14px;
      text-align: center;
      width: 90px;
      height: 35px;
      float: right;
    }
    .postcreateButton {
      border-radius: 16px;
      width: 55px;
      height: 55px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      /* border: 3px solid red; */
      background: linear-gradient(to bottom, #f0f0f0, #e1e1e1);
      transition: transform 200ms;
      margin-top: 5px;
    }
    .plusButtonSize{
      font-size: 30px;
    }
}

@media (max-width: 361px) {
  .comment-content {
    min-width: 70%;
    padding: 5px 10px;
    font-size: 13px;
  }
      .tagsContainerWidth{
    max-width:21.2rem;
  }
}
@media (max-width:600px) {
  .posttitle {
    font-size: 12.5px;
  }
}

@media (min-width:1501px) and (max-width:1900px) {
  .postCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 3px 6px #c7a58f0d;
    border: 0.5px solid #c6c6c6;
    border-radius: 15px;
    padding: 1rem;
    background: #fff;
    margin-bottom: 1.5rem;
  }

  .posttitle {
    font-weight: 700;
    font-size: 0.75rem;
    padding-left: 10px;
    display: flex;
    align-items: center;
  }

  .postContent {
    color: #040100;
    display: flex;
    font-size: 0.9rem;
    padding: 0 10px 1rem;
    letter-spacing: 0.5px;
  }

  .commenticon {
    font-size: 1rem;
    /* 20px */
    line-height: 1.75rem;
    /* 28px */
  }

  .commentCount {
    font-size: 1rem;
    /* 18px */
    line-height: 1rem;
    /* 28px */
  }

  .noPostsText {
    padding-top: 1.25rem;
    /* 20px */
    font-size: 1.2rem;
    /* 24px */
    line-height: 2rem;
    /* 32px */
  }

  .noPostsThumbnail {
    width: 5rem;
    height: 5rem;
  }

  .time-ago-format {
    font-size: 0.7rem;
  }

  .feedsPostContentFontSize {
    font-size: 1.25rem;
    margin: 20px 10px;
  }
    .postProfileImage > span >img{
      width: 2rem; /* 48px */
      height: 2rem; /* 48px */
      object-fit: cover;
    }
    .createPostButton {
      font-weight: 500;
      background: #686f2e;
      color: white;
      font-size: 16px;
      text-align: center;
      width: 100px;
      height: 40px;
      float: right;
    }
    .postcreateButton {
      border-radius: 18px;
      width: 65px;
      height: 65px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      /* border: 3px solid red; */
      background: linear-gradient(to bottom, #f0f0f0, #e1e1e1);
      transition: transform 200ms;
      margin-top: 5px;
    }
    .plusButtonSize{
      font-size: 35px;
    }
}