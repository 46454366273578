
.communityCategories {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 525px;
  /* min-height: 27%; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 60%;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.communityCategoryCard {
  width: calc(50% - 20px); 
  margin: 5px;
  background-color: rgba(255, 234, 222, 0.822);
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 32px;
  padding-right: 12px;
  cursor: pointer;
  transition: transform 200ms;
}
.communityCategoryCard:hover{
  transform: scale(1.03);
}


.cardContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.communityCategoryText {
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.highlightedCard {
  border: 6px solid #298717; 
}
.showMobileIcon{
  display: none;
  }
  .communityNumber{
    font-size: 4.5rem;
    line-height: 1;
    margin-right: 0.5rem;
    --tw-text-opacity: 1;
    color: rgb(41 135 23 / var(--tw-text-opacity));
  }
  .communityBanner{
    width: 100%;
    object-fit: cover;
    height: 525px;
  }
@media(max-width:600px){
  .showMobileIcon{
    display: block;
  }
  .communityBanner{
    display: none!important;
  }
  .communityCategories{
    display: none!important;
  }

  .topPanelHead {
    margin: 0 0 0.5rem;
}
  .ant-tabs-nav {
    display: flex!important;
    position: fixed!important;
    top: 60px;
    background: #e9e9e9;
    width: 100%;
    overflow: auto;
    left: 0px;
    justify-content: center;
    z-index: 1;
}
.ant-tabs-nav  .ant-tabs-nav-wrap{

  justify-content: flex-start;
  flex: unset!important;
  margin: 0!important;
}
  .ant-tabs-nav-wrap{
    margin: 0;
    justify-content: center;
    color: #fff;
  }
  .tabPanelBox .ant-tabs-tab-btn {
    font-size: 18px;
    font-weight: 600;
    color: #7f4e31;
}
.tabPanelBox .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-size: 18px;
  font-weight: 600;
  color: #94ab55;
}
.tabPanelBox .ant-tabs .ant-tabs-tab{
  padding:12px 10px 12px 10px!important;
}
.tabPanelBox .ant-tabs .ant-tabs-tab+.ant-tabs-tab{
 margin:0px;
}
.yourComCreate {
  position: fixed;
  right: 2px;
  bottom: 65px;
  opacity: 1;
  top: auto;
  z-index: 9999;
  margin: 0;
}
.plusCreate {
  display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 10px;
    z-index: 999;
    right: 12px;
    width:70px;
    height:70px;
    /* border-radius: 50%!important;
    border-width: 0.5px;
    background-color: rgb(255, 255, 255); */
  /* right: 0px;
  border: unset;
  background: unset!important;
  width: 100px!important;
  height: unset!important;
  border-radius: 100px;
  bottom: 20px;
  position: fixed;
  z-index: 999;
  box-shadow: none; */
}
.showDesktop{
  display: none!important;
}
.tabPanelBox{
  padding: 0 10px!important;
}
.popupWidth{
font-size: 16px!important;
}
.tabsBody{
  margin-top: 15px;
}
.search-results {
  position: absolute;
  background-color: white;
  width: 95%;
  margin: 30px 0px 0px 5px;
  padding: 10px;
  border-radius: 10px;
  max-height: 450px;
  overflow-y: scroll;
  z-index: 10;
}

}
@media (min-width : 601px) and (max-width: 900px){
  .communitySearchBar{
    margin-bottom: 30px!important;
  }
  .showDesktop{
    display: none!important;
  }
  /* .yourComCreate {
    position: fixed;
    right: 2px;
    bottom: 65px;
    opacity: 1;
    top: auto;
    z-index: 9999;
    margin: 0;
  } */
  .showMobileIcon{
    display: block;
  }
  .plusCreate {
    display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      bottom: 10px;
      z-index: 999;
      right: 12px;
      width:90px;
      height:90px;
      /* border-radius: 50%!important;
      border-width: 0.5px;
      border-color: #a9a9a9;
      background-color: rgb(246, 246, 246); */
    /* right: 0px;
    border: unset;
    background: unset!important;
    width: 100px!important;
    height: unset!important;
    border-radius: 100px;
    bottom: 20px;
    position: fixed;
    z-index: 999;
    box-shadow: none; */
  }
  }
  @media (min-width : 901px) and (max-width: 1100px){
    .communityCategories {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 525px;
      /* min-height: 27%; */
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-left: 40%;
      padding-right: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .communityNumber{
      font-size: 3.5rem;
      line-height: 1;
      margin-right: 0.5rem;
      --tw-text-opacity: 1;
      color: rgb(41 135 23 / var(--tw-text-opacity));
    }
    .communityCategoryText {
      width: 100%;
      font-size: 17px;
      font-weight: bold;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  .showDesktop{
    display: none!important;
  }
  }
  @media (min-width:1200px) and (max-width:1500px){
    .communityCategories {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 400px;
      /* min-height: 27%; */
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-left: 60%;
      padding-right: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .communityCategoryCard {
      width: calc(50% - 1rem); 
      margin: 5px;
      background-color: rgba(255, 234, 222, 0.822);
      border-radius: 15px;
      text-align: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-left: 32px;
      padding-right: 12px;
      cursor: pointer;
      transition: transform 200ms;
    }
    .communityBanner{
      width: 100%;
      object-fit: cover;
      height: 400px;
    }
    .communityNumber{
      font-size: 3.5rem;
      line-height: 1;
      margin-right: 0.5rem;
      --tw-text-opacity: 1;
      color: rgb(41 135 23 / var(--tw-text-opacity));
    }
    .communityCategoryText {
      width: 100%;
      font-size: 1rem;
      font-weight: bold;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media (min-width:1501px) and (max-width:1900px){
    .communityCategories {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 400px;
      /* min-height: 27%; */
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-left: 60%;
      padding-right: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .communityCategoryCard {
      width: calc(50% - 1rem); 
      margin: 5px;
      background-color: rgba(255, 234, 222, 0.822);
      border-radius: 15px;
      text-align: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-left: 32px;
      padding-right: 12px;
      cursor: pointer;
      transition: transform 200ms;
    }
    .communityBanner{
      width: 100%;
      object-fit: cover;
      height: 400px;
    }
    .communityNumber{
      font-size: 3.5rem;
      line-height: 1;
      margin-right: 0.5rem;
      --tw-text-opacity: 1;
      color: rgb(41 135 23 / var(--tw-text-opacity));
    }
    .communityCategoryText {
      width: 100%;
      font-size: 1rem;
      font-weight: bold;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }