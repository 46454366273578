.matchprofileImage{
    height: 75px;
    width:75px;
    border-radius: 50%;
    object-fit: cover;
    /* aspect-ratio: 1/2; */
  }
  .matrimonyheading{
    font-size: 1.125rem; /* 18px */
line-height: 1.75rem; /* 28px */
  }
  .matchingProfilesContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* flex-wrap: wrap; */
  }
  .matchingProfiles{
    width: 60%;
    margin-inline: 10px;
  }

  @media( max-width:900px) and (min-width: 768px){
    .matchprofileImage{
        height: 70px;
        width:70px;
        border-radius: 50%;
        object-fit: cover;
        /* aspect-ratio: 1/2; */
      }
      .matrimonyheading{
        font-size: 15px; /* 18px */
line-height: 25px; /* 28px */
      }
      .matchingProfilesContainer{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
      }
      .matchingProfiles{
        width: 300px;
        margin-inline: 3px;
      }}


  @media(max-width:767px){
      .matchprofileImage{
        height: 70px;
        width:70px;
        border-radius: 50%;
        object-fit: cover;
        /* aspect-ratio: 1/2; */
      }
      .matrimonyheading{
        font-size: 15px; /* 18px */
        line-height: 25px; /* 28px */
      }
      .matchingProfilesContainer{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
      }
      .matchingProfiles{
        width: 300px;
        margin-inline: 3px;
      }
  }