.chatComponent{
    margin: 20px;
    overflow: hidden;
    height: 37rem;
    width: 100%;
    border-radius: 0.5rem;
    background-color: #efe8e1;
    border: 1px solid #edc2a6;
}


.chatInput {
    padding-left: 2px;
    width: 98%;
    height: 7dvh;
    border: 1px solid #edc2a6;
    border-radius: 0.5rem;
    display: flex;
    background-color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.chatInput .send{
    padding: 0.1rem;
    margin: 0.25rem;
    background-color: white;
    color: #804c31;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.chat-info{
    font-size: 1.2rem;
    background-color:  #804C31;
    padding: 0.5rem;
    color: white;
}

.img-preview{
    backdrop-filter: blur(5px); /* Adjust the blur value as needed */
    background: rgba(237,194,166, 0.2);
}

.chat-info .chat-info-img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;}

.chat-right{
    width: 65%;
}

@media (min-width:1200px) and (max-width:1500px){
    .chatComponent{
        height: 28.5rem;
    }

    .chat-input-main-div{
        bottom: -55px;
    }
}

@media(max-width:767px){
    .remove-header{
        margin-top: -60px;
    }
    



    .chatComponent{
        margin:0;
        height:100dvh;
        border-radius: 0;
    }
    .chat-input-main-div{
        bottom: -35px;
    }

    .chatInputField{
        height:7dvh;
    }
    .chatInfo{
        position: sticky;
        height: 6dvh;
    }
    .chatInput{
        position: sticky;
        height: 7dvh;
        margin-bottom: 2dvh;
    }

    .keyboardExtra{
        margin-bottom: 3dvh;
        height:10dvh;
        line-height: 7dvh;
    }
    .chat-right{
        width: 100%;
    }
}