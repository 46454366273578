.joingroup {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
}
.joingroup button{
  border-radius: 25px;
  height: 35px;
  text-transform: uppercase;
  font-weight: 600;
}
  .discoverthumbnailRowForThree {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    /* justify-content: space-between; */
  }
  
  .discoverthumbnailRowForThreeColumn {
    width: calc(25% - 8%);
    border: 5px solid #f9f9f9;
    border-radius: 20px;
    margin: 1%;
    border: 1px solid #eaeaea;
    box-shadow: rgba(149, 157, 165, 0.2) 0px -1px 10px 0px;
    /* height: 350px; */
    overflow: hidden;
  }
  
  .discoverthumbnailRowForThreeColumn:hover {
    /* border: 1px solid #499c9f97; */
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  
  .discoverthumbnailImg img {
    width: 50%;
  }
  
  .discoverthumbnailTitle {
    font-size: 22px;
    font-weight: 600;
    margin: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  
  .discoverthumbnailDescription {
    font-size: 18px;
    /* margin: 10px 10px 0 10px; */
    width: 100%;
    min-height: 70px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .discoverthumbnailDescription p {
    display: flex;
    flex-direction: column;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media(max-width:600px){
    .joingroup button {
      border-radius: 25px;
      height: 35px;
      text-transform: uppercase;
      font-weight: 600;
      margin-top: 20px;
  }
 
}
@media (min-width : 601px) and (max-width: 900px){
  .discoverthumbnailRowForThree {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
}
  .discoverthumbnailRowForThreeColumn {
    width: calc(45% - 1%);
    border: 5px solid #f9f9f9;
    border-radius: 20px;
    margin: 1%;
    border: 1px solid #eaeaea;
    box-shadow: rgba(149, 157, 165, 0.2) 0px -1px 10px 0px;
    /* height: 350px; */
    overflow: hidden;
}
}
@media (min-width : 901px) and (max-width: 1100px){
  .discoverthumbnailRowForThreeColumn {
    width: calc(45% - 1%);
    border: 5px solid #f9f9f9;
    border-radius: 20px;
    margin: 1%;
    border: 1px solid #eaeaea;
    box-shadow: rgba(149, 157, 165, 0.2) 0px -1px 10px 0px;
    /* height: 350px; */
    overflow: hidden;
}
}
@media (min-width:1200px) and (max-width:1500px){
  .discoverthumbnailTitle {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .discoverthumbnailDescription {
    font-size: 0.9rem;
    /* margin: 10px 10px 0 10px; */
    width: 100%;
    min-height: 70px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media (min-width:1501px) and (max-width:1900px){
  .discoverthumbnailTitle {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .discoverthumbnailDescription {
    font-size: 0.9rem;
    /* margin: 10px 10px 0 10px; */
    width: 100%;
    min-height: 70px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}