* {
  -webkit-tap-highlight-color: transparent; /* remove tap highlight */
  outline: none !important; /* remove outline */
  box-shadow: none !important; /* remove box shadow */
}

.labelHeading {
  color: #333333 !important;
  padding: 5px 0 !important;
  font-weight: 300 !important;
  font-size: 16px !important;
}

.label {
  color: #333333 !important;
  font-weight: 500;
}

.label::after {
  content: "*";
  color: red;
  margin-left: 2px;
}

.inputBox {
  width: 40%;
  height: 49px;
  border-radius: 6px;
  box-shadow: none;
  border: 1.5px solid #e9edee;
  padding: 15px;
  margin: 10px 0px;
  box-sizing: inherit;
}

.mobileInputBox {
  /* width: 230px !important; */
  height: 49px;
  border-radius: 6px;
  box-shadow: none;
  border: 1.5px solid #e9edee;
  padding: 15px;
  margin: 10px 0px;
  box-sizing: inherit;
}

.radioInputBox {
  width: 10%;
  height: 20px;
  border-radius: 6px;
  box-shadow: none;
  border: 1.5px solid #e9edee;
  padding: 15px;
  margin: 10px 0px;
  box-sizing: inherit;
}

.blueText {
  color: #0075c1 !important;
  font-weight: 500 !important;
  font-size: 14px;
  text-decoration: none !important;
}

.signUpButtons {
  width: 86px;
  height: 34px;
  border-radius: 10px;
  border: 1px solid #e9edee;
  color: #fff;
  background: #33333357;
  font-weight: 400 !important;
  font-size: 12px;
  margin: 20px 10px;
  border-radius: 18px;
}

.signUpButtons:hover {
  background-color: initial !important;
  color: #f83e5e !important;
  border-color: #f83e5e !important;
}

.homePageBackgroundUrl h2 {
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2.5rem;
  margin: 0px 0 25px;
  text-shadow: white 0.1em 0.1em 0.2em;
}

.homePageBackgroundUrl p {
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity));
  font-weight: 300;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 1.875rem;
  line-height: 2.25rem;
  text-shadow: white 0.1em 0.1em 0.2em;
}

.homePageBackgroundUrl div {
  align-items: center;
  justify-content: center;
  display: flex;
}

.contentDiv {
  gap: 0.75rem;
  margin-top: 0.75rem;
  display: block;
  padding: 2rem 2rem;
  flex-direction: row;
  /* margin-bottom: 0.75rem; */
  justify-content: space-around;
  align-items: center;

  text-align: center;
}

.contentDiv img {
  margin: auto;
}

.contentDiv div {
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  display: inline-block;
  float: left;
  max-width: 45%;
  margin: 0 30px;
  min-height: 625px;
  flex-direction: column;
  text-align: center;
}

.contentDiv p {
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity));
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.contentDiv h1 {
  color: #333333bf;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  margin: auto;
}

.featureshead {
  margin-bottom: 2.5rem;
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 2.5rem;
  text-align: center;
}

.featuresdiv {
  gap: 4rem;
  flex-direction: row;
  display: flex;
  padding: 0px 0 4rem;
}

.featuresdiv .whitebg {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 0.375rem;
  justify-content: center;
  align-items: center;
  display: inherit;
}

.whitebg .text {
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  font-weight: 600;
  display: inherit;
  text-align: center;
}

.text a {
  margin: auto;
}

.img {
  background-image: url("./assets/images/left-quote.png");
  width: 100%;
  height: 378px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* // filter: blur(2px); */
  /* // -webkit-filter: blur(2px); */
  margin-top: -1em;
}

.staticPageHeading {
  font-weight: 700;
  font-size: 3rem;
}

.staticMobileHeading {
  font-size: 2rem;
}

@media (max-width: 767px) {
  .homePageBackgroundUrl p {
    font-size: 1.5rem !important;
    padding: 1rem !important;
    line-height: 2rem !important;
  }

  .aboutUsPage section {
    height: auto !important;
  }

  .innerpagesalign {
    padding: 1rem !important;
  }

  .innerpagesdiv p {
    line-height: 1.5rem !important;
  }

  .innerpagesdiv {
    font-weight: 300 !important;
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: center;
    gap: 1.25rem;
    padding: 0 20px;
    flex-direction: column;
    display: flex;
  }

  .aboutUsPage {
    height: 90vh;
    align-items: center;
    display: flex;
  }

  .footerPge {
    left: 0;
    width: 100%;
    bottom: 0;
    justify-content: center;
    background: #fff;
    display: flex;
    height: auto;
    padding: 3px 0;
    display: flex;
    flex-direction: row;
  }

  .privacyPage {
    text-align: center;
  }

  .termsPage {
    text-align: center;
  }

  .signUp {
    align-items: center;
    text-align: center;
  }

  .signUpForm .flex-col {
    align-items: center;
  }

  .signUpForm .flex-col .inputBox {
    width: auto !important;
  }

  .signUpForm .onSave {
    text-align: left;
    align-items: center;
  }

  .signUpForm .buttonsDiv {
    justify-content: center;
  }

  .footerPge .footerUl {
    display: inline-block;
    width: 100%;
    padding: 0 0 15px;
    flex-direction: row;
  }

  .mainfooter {
    display: flex;
    max-width: 100%;
    padding: 0 0 1rem;
    justify-content: space-between;
    flex-direction: column-reverse !important;
    align-items: center;
    position: relative;
    z-index: 1000;
  }

  .footerUl a {
    --tw-text-opacity: 1;
    color: rgb(51 51 51 / var(--tw-text-opacity));
    font-size: 1rem;
    line-height: 0.5rem;
    font-weight: 500;
  }

  .footerPge .footerUl ul {
    display: inline-flex;
    min-width: 50%;
    flex: 1 1 25%;
    /* font-size: 22px; */
    justify-content: space-evenly;
    align-items: center;
    padding: 2px 0;
    flex-direction: row;
  }

  .footerPge {
    --tw-text-opacity: 1;
    color: rgb(51 51 51 / var(--tw-text-opacity));
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    display: block;
    overflow: auto;
    max-width: 100%;
  }

  .copyright {
    display: inline-block;
  }

  .loginOuter {
    flex-direction: column !important;
    margin: 0 !important;
    align-items: center;
    display: flex;
    padding: 3rem 0 !important;
    text-align: center;
  }

  .innerDivlogin {
    border: 0;
  }

  .innerDivlogin button {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(248 62 94 / var(--tw-bg-opacity));
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
  }

  .loginform {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .loginOptions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contentDiv {
    flex-direction: column;
  }

  .featuresdiv {
    flex-direction: column;
  }
}

.mainouterdiv {
  flex-direction: column;
  display: flex;
}

.sectionmain {
  align-items: center;
  flex-direction: column;
  display: flex;
}

/* headercss */

.headerOuter {
  display: flex;
  position: sticky;
  top: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: #fff;
  padding: 0 2rem 1rem 2rem;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.headerText {
  --tw-text-opacity: 1;
  color: rgb(109 58 33 / var(--tw-text-opacity));
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
}

.logodiv {
  gap: 0.5rem;
  display: inherit;
  flex-wrap: wrap;
  align-items: center;
}

.logoClass {
  display: inherit;
  align-items: inherit;
  width: 60px;
  height: 47px;
}

.logodiv a {
  color: inherit;
  text-decoration: inherit;
}

.linksDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: #fff;
}

/* footercss */

.footerUl {
  display: flex;
  flex-direction: revert;
  gap: 0.5rem;
  align-items: center;
}

.footerUl ul {
  display: inherit;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

.footerRow {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 15px 15px;
}

.copyright p {
  margin: 0;
  padding: 0;
  color: #6d7072;
  font-size: 14px;
}

.footerUl a {
  font-weight: 500;
  margin: 0;
  padding: 0 10px;
  color: #6d7072;
  font-size: 14px;
}

/* loginpagecss */

.loginRow {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loginCol {
  width: 50%;
  text-align: center;
  padding: 30px;
  height: 100%;
}

.loginCol.loginCol-R {
  background-color: #fff;
}

.loginform input {
  width: 100%;
  border-radius: 0;
  height: 50px;
  max-width: 600px;
  margin-bottom: 20px;
  border: 3px solid #ebebeb;
}

.loginCol .loginColInfo {
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-top: -60px;
}

.loginColForm {
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
}

.loginCol .loginColInfo h1 {
  font-size: 32px;
  margin: 20px 0;
  text-shadow: #c7c7c7 0.1em 0.1em 0.2em;
  font-weight: 600;
}

.loginCol .loginColInfo button {
  font-size: 24px;
  margin: 20px 0;
  color: #7c5030;
  font-weight: 700;
  text-shadow: #c7c7c7 0.1em 0.1em 0.2em;
}

.loginbuttonpage {
  background-color: #7c5030;
  border-radius: 0;
  width: 100%;
  max-width: 600px;
  height: 50px;
  font-size: 20px;
  color: #ffffff;
}

.loginCol.loginCol-R {
  background-color: #fff;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.forgotpass {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin: 15px 0;
}

.signuptext {
  --tw-text-opacity: 1;
  color: rgb(16 163 127 / var(--tw-text-opacity));
  font-weight: 600;
}

.loginOptions {
  display: flex;
  align-items: center;
}

.cursor-pointer {
  margin: 0 auto;
}

.innerDiv2login {
  border: 0;
}

/* aboutuspage */

.innerpagesalign {
  align-items: center;

  display: flex;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 0.375rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  padding: 3rem;
  margin-top: 100px;
  margin: 2.5rem;
}

.aboutUsPage section {
  height: 450px;
  text-align: center;
}

.innerpagesalign h1 {
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.aboutUsPage .innerpagesdiv {
  text-align: center;
}

.innerpagesdiv {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 300 !important;
  font-size: 1rem;
  line-height: 1.25rem;
  margin-top: 1rem;
  gap: 1.25rem;
  flex-direction: column;
  display: flex;
}

.innerpagesdiv p {
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity));
  line-height: 2.5rem;
  font-weight: 100 !important;
  font-size: 1rem;
  line-height: 2.5rem;
}

.smalltxt {
  font-size: 1rem !important;
}

.registrationform .formitem div:first-child {
  display: block;
}

.registrationform .formitem label {
  display: block;
  float: left;
}

.registrationform .formiteminline {
  display: flex;
  float: left;
}

.onSave {
  display: flex;
  width: 100%;
  float: none;
}

.buttonsDiv {
  width: 100%;
  display: flex;
  float: left;
  justify-content: left;
  /* align-items: start; */
}

.buttonsDiv .ant-form-item-row {
  width: 100%;
  display: flex;
  float: left;
  justify-content: left;
  /* align-items: start; */
}

.labelHeading {
  font-weight: 500 !important;
}

.buttonsDiv .ant-form-item-control {
  margin: 0;
}
.noRequestsText{
  font-size: 2.25rem; /* 36px */
line-height: 2.5rem; /* 40px */
display: flex;
align-items: center;
justify-content: center;
}
.noRequestContainer{
  width: 100%;
  height: 70vh;
}

@media (max-width: 1100px) and (min-width: 700px) {
  .loginOuter {
    height: 66vh;
    margin: 3rem;
  }
}

.icon1 {
  position: absolute;
  top: 10px;
  left: 10px;
}

.icon2 {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.newsColumn {
  margin: 2rem 0;
}

.Newsdescription {
  padding-bottom: 3rem;
}

.newsColumn .ant-card-body {
  position: relative;
}

.newsColumn .ant-card-head {
  border: 0;
}

.newsColumn .ant-card-head-title {
  display: inline-block;
  flex: 1;
  padding: 5px 0;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
}

.imagediv {
  background: #b3b3b3bf;
  margin: auto;
  min-height: 200px;
  display: flex;
  align-items: center;
}

.imagediv img {
  margin: auto;
  height: 200px;
}

.imagediv {
  background: #c5c5c5;
}

.h-16 {
  z-index: 999;
}

.sourcedata {
  display: block;
  /* justify-content: flex-start; */
  width: 85%;

  align-items: baseline;
  bottom: 16px;
  position: absolute;
}

.sourcediv {
  display: inline-block;
  float: left;
  width: 50%;
}

.datediv {
  float: right;
  width: 50%;
  text-align: right;
  text-wrap: wrap;
  display: inline-block;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1rem;
}

.title-card .ant-card-head-title {
  min-height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Newsdescription {
  display: flex;
}

.stickleftNav {
  position: fixed;
  top: 10;
  width: 20%;
  z-index: 1;
  /* position: -webkit-sticky; */
}

.parentSticky {
  display: unset;
}

/* .bodyHeight {
    height:  calc(100% - 120px);
    border: 2px solid magenta;
    display: block;
 } */

/* .Multistepdesign {
  display: flex;
  padding: 2rem;
  flex-direction: column;
} */

/* .Multistepdesign input,
.Multistepdesign .ant-select {
  max-width: 300px;
} */

.discoverimg {
  max-height: 150px;
}

.joingroup {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.dicovergroup .ant-card {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgb(0 0 0 / 16%) !important;
}

.discovergroup .ant-card-head {
  min-height: unset !important;
  border-bottom: unset;
  padding: 15px 24px 0 !important;
}

.joingroup button {
  width: 30%;
  margin-bottom: 15px;
}

.discovergroup {
  margin: 2rem 0;
}

.hidden-input {
  display: none;
}

body {
  font-family: "Archivo", sans-serif;
}

.labelHeading {
  color: #333333 !important;
  padding: 5px 0 !important;
  font-weight: 300 !important;
  font-size: 16px !important;
}

.label {
  color: #333333 !important;
  font-weight: 500;
}

.label::after {
  content: "*";
  color: red;
  margin-left: 2px;
}

.inputBox {
  width: 40%;
  height: 49px;
  border-radius: 6px;
  box-shadow: none;
  border: 1.5px solid #e9edee;
  padding: 15px;
  margin: 10px 0px;
  box-sizing: inherit;
}

.mobileInputBox {
  /* width: 230px !important; */
  height: 49px;
  border-radius: 6px;
  box-shadow: none;
  border: 1.5px solid #e9edee;
  padding: 15px;
  margin: 10px 0px;
  box-sizing: inherit;
}

.radioInputBox {
  width: 10%;
  height: 20px;
  border-radius: 6px;
  box-shadow: none;
  border: 1.5px solid #e9edee;
  padding: 15px;
  margin: 10px 0px;
  box-sizing: inherit;
}

.blueText {
  color: #0075c1 !important;
  font-weight: 500 !important;
  font-size: 14px;
  text-decoration: none !important;
}

.signUpButtons {
  width: 86px;
  height: 34px;
  border-radius: 10px;
  border: 1px solid #e9edee;
  color: #fff;
  background: #33333357;
  font-weight: 400 !important;
  font-size: 12px;
  margin: 20px 10px;
  border-radius: 18px;
}

.signUpButtons:hover {
  background-color: initial !important;
  color: #f83e5e !important;
  border-color: #f83e5e !important;
}

.homePageBackgroundUrl {
  background-image: url("./assets/images/istockphoto-472336288-612x612.jpg");
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.img {
  background-image: url("./assets/images/left-quote.png");
  width: 100%;
  height: 378px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* // filter: blur(2px); */
  /* // -webkit-filter: blur(2px); */
  margin-top: -1em;
}

.staticPageHeading {
  font-weight: 700;
  font-size: 3rem;
}

.staticMobileHeading {
  font-size: 2rem;
}

.headerOuter {
  /* background: transparent url('./assets/newUiImages/header.svg') ; */
  background-image: linear-gradient(270deg, #804c31, #67702e);
  height: 80px;
  padding-bottom: 0;
}

.headertext {
  color: white;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 2.5px;
}

.Appwrap-inner {
  height: auto;
  width: 100%;
  float: right;
  display: block;
  right: 0;
  z-index: 1;
  position: fixed;
}

.Appwrap {
  height: auto;
  width: 100%;
  background: #fff;
  float: right;
  display: block;
  right: 0;
  z-index: 1;
  position: fixed;
}

.appChild {
  display: block;
  min-height: 90vh;
  margin-top: 80px;
  background: #fff;
}

.bodyBackground {
  background: #ebebeb;
  min-height: 83vh;
}

.navimg img {
  margin: 0 auto;
}

.listnavbar p {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  line-height: 25px;
  letter-spacing: 1.6px;
  text-align: center;
}

.listnavbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
}

.listnavbar .navoptions {
  padding: 1rem 0;
  /* padding: 10px 0px; */
}

.listnavbar img {
  margin: 0 auto;
  width: 30px;
}

.Appwrap .headertext {
  margin-left: 150px;
}

.Multistepdesign {
  display: flex;
  padding: 2rem;
  flex-direction: column;
}

.Multistepdesign h2 {
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 20px;
}

.formwrapper {
  overflow: hidden;
}

.qoutediv {
  padding: 2rem;
  width: 100%;
  position: relative;
}

.qouteBox .text-base {
  padding: 0 15px;
}

.icon1 {
  position: absolute;
  top: 10px;
  left: 10px;
}

.icon2 {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.newsColumn {
  margin: 2rem 0;
}

.Newsdescription {
  padding-bottom: 3rem;
}

.newsColumn .ant-card-body {
  position: relative;
}

.newsColumn .ant-card-head {
  border: 0;
}

.newsColumn .ant-card-head-title {
  display: inline-block;
  flex: 1;
  padding: 5px 0;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
}

.imagediv {
  background: #b3b3b3bf;
  margin: auto;
  min-height: 200px;
  display: flex;
  align-items: center;
}

.imagediv img {
  margin: auto;
  height: 200px;
  width: 100%;
}

.imagediv {
  background: #c5c5c5;
}

.h-16 {
  z-index: 999;
}

.sourcedata {
  display: block;
  /* justify-content: flex-start; */
  width: 85%;

  align-items: baseline;
  bottom: 16px;
  position: absolute;
}

.sourcediv {
  display: inline-block;
  float: left;
  width: 50%;
}

.datediv {
  float: right;
  width: 50%;
  text-align: right;
  text-wrap: wrap;
  display: inline-block;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1rem;
}

.title-card .ant-card-head-title {
  min-height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Newsdescription {
  display: flex;
}

.headingfeed {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 8rem;
}

.headtop {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  font-family: "poppins", sans-serif;
}

.createcommunitydiv {
  /* background: url(./assets/newUiImages/explorebackground.svg) 0% 0% no-repeat padding-box; */
  /* margin: 0 20px; */
  /* min-width: 150px; */
  border-radius: 15px;
  /* width: 110px; */
  /* height: 110px; */
  width: 100px;
  height: 80px;

  /* border: 3px solid red; */
  /* background: linear-gradient(to bottom, rgb(245, 84, 52), rgb(249, 80, 200)); */
}

.Explorelist {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px 7rem;
}

.createcommunitydiv button {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95%;
  position: relative;
  top: 2px;
  border: 1px solid transparent;
  background: #fff;
  border-radius: 14px;
  left: 2px;
  right: 3px;
  bottom: 3px;
  font-size: 55px;
}

.communitylist {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
}

.titlecomm {
  font-size: 17px;
  color: #af7d5c;
  text-align: center;
  padding-top: 10px;
  text-wrap: wrap;
  width: 100px;
}

.feedsContainer {
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  margin-left: 15%;
  /* width: 105%; */
  width: 100%;
}
.postfeedsContainer {
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  width: 100%;
}

.postcontainer {
  display: flex;
  flex-direction: column;
  margin: 20px 7rem;
  justify-content: center;
  /* align-items: stretch; */
  width: 50%;
  /* border: 1px solid #C6C6C6; */
  box-shadow: 0px 3px 6px #c7a58f0d;
  border: 0.5px solid #c6c6c6;
  border-radius: 15px;
  padding: 18px;
  background: #fff;
}

.dpandname {
  display: flex;
  padding-bottom: 20px;
}

.posttitle {
  font-weight: 700;
  font-size: 14px;
  padding-left: 10px;
  display: flex;
  letter-spacing: 1.7px;
  align-items: center;
}

.postimage {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.postimage img {
  width: 100%;
}

.commentinfo {
  color: #040100;
  display: flex;
  font-size: 15px;
  padding-top: 20px;
  font-weight: 500;
  letter-spacing: 1.5px;
}

.postdesc {
  color: #040100;
  display: flex;
  font-size: 14px;
  padding: 0 10px 20px;
  font-weight: 500;
  letter-spacing: 1.5px;
}

.searchdiv.ant-input-group-wrapper {
  background: url(./assets/newUiImages/searchback.svg) no-repeat;
  height: 44px;
  border: 1px solid #9b6f57;
  width: 545px;
  border-radius: 26px;
}

.searchdiv .ant-input-wrapper {
  background: none;
  border: 0;
  border-radius: unset;
}

.searchdiv input {
  background: transparent;
  border: none;
  position: relative;
  font-size: 17px;
  letter-spacing: 1.5px;
  left: 5px;
  top: 3px;
  color: #fff !important;
}

.searchdiv span button {
  box-shadow: none;
  outline: none;
  text-decoration: none;
}

.searchdiv span button:focus {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  text-decoration: none;
}

.searchdiv input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff !important;
  opacity: 1;
  /* Firefox */
}

.searchdiv input::placeholder:focus {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff !important;
  opacity: 1;
  /* Firefox */
}

.searchdiv input:focus {
  color: #fff;
  /* background-color: #0075C1; */
  border: none;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.searchdiv .anticon-search {
  width: 37px;
  position: relative;
  height: 37px;
  right: -11px;
  /* background: #ad7a5e; */
  border-radius: 50%;
}

.ant-input-search .ant-input-search-button {
  height: 43px;
  border: none !important;
  outline: none !important;
}

.searchdiv .anticon-search svg {
  width: 20px;
  height: 18px;
  margin-top: 10px;
  border: 1px solid transparent;
  /* background: #ad7a5e; */
  border-radius: 50%;
}

.bellicon {
  padding: 0 15px;
}

.minddiv {
  padding: 20px 8rem;
  display: flex;
}

.minddiv input {
  margin-left: 15px;
  width: 545px;
  border-radius: 26px;
  padding: 0 15px;
  color: #000;
}

.headertext {
  margin-left: 100%;
}

.tabcontainer {
  display: flex;
  /* margin-left: 150px; */
  /* margin: 15px 20px; */
  margin: 95px 20px 20px 130px;
  width: auto;
  display: block;
  justify-content: center;
  align-items: center;
  background: rgb(247, 247, 247);
  /* opacity: 0.6; */
  border-radius: 23px 23px 23px 23px;
}

@media screen and (max-width: 1100px) {
  .headertext {
    margin-left: 100%;
  }

  .tabcontainer {
    display: flex;
    /* margin-left: 150px; */
    /* margin: 15px 20px; */
    margin: 95px 20px 20px 130px;
    width: auto;
    display: block;
    justify-content: center;
    align-items: center;
    background: rgb(247, 247, 247);
    /* opacity: 0.6; */
    border-radius: 23px 23px 23px 23px;
  }

  .listnavbar .navoptions {
    padding: 1rem 0;
  }

  .contentDiv div {
    max-width: 47%;
    margin: 0 10px;
  }
}

@media screen and (max-width: 1100px) {
  .tabcontainer {
    display: flex;
    /* margin-left: 150px; */
    /* margin: 15px 20px; */
    margin: 95px 20px 20px 130px;
    width: auto;
    display: block;
    justify-content: center;
    align-items: center;
    background: rgb(247, 247, 247);
    /* opacity: 0.6; */
    border-radius: 23px 23px 23px 23px;
  }

  .headertext {
    margin-left: 100%;
  }

  .contentDiv div {
    max-width: 47%;
    margin: 0 10px;
  }

  .listnavbar .navoptions {
    padding: 1.2rem 0;
  }
}

@media screen and (max-width: 1023px) {
  .contentDiv div {
    max-width: 45%;
    margin: 0 10px;
  }

  .listnavbar .navoptions {
    padding: 1rem 0;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1023px) {
  .contentDiv div {
    max-width: 45%;
    margin: 0 10px;
  }

  .listnavbar .navoptions {
    padding: 0.8rem 0;
  }
}


.ant-btn.css-dev-only-do-not-override-17a39f8.ant-btn-primary.ant-input-search-button:hover {
  background: unset;
  outline: none;
}

/* .Multistepdesign .ant-form-item-row.ant-row {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  align-items: self-start;
  padding: 1rem 0;
  min-height: 60px;
} */

.Multistepdesign button {
  margin-top: 30px;
}

.navoptions.selected p {
  border-bottom: 3px solid #a3c844;
}

.tranding-images {
  width: 150px;
  height: 150px;
}

.ant-form-item {
  margin-bottom: 10px;
  width: 100%;
}

/* .Multistepdesign input,
.Multistepdesign .ant-select,
.Multistepdesign .ant-picker {
  width: 250px;
} */

/* New UI */

.pageLayout {
  display: flex;
  flex-flow: row wrap;
}

.pageLayout-L {
  width: 130px;
  box-shadow: 0px 3px 6px #c7a58f6b;
  position: relative;
}

.pageLayout-R {
  width: calc(100% - 130px);
}

.navbar {
  background-color: #fff;
  position: fixed;
  z-index: 9;
  width: 130px;
}

.pageLayoutContent {
  margin-top: 60px;
  background-color: #ebebeb;
  min-height: 85vh;
}

.mainContentCol {
  background-color: #f7f7f7;
  margin: 0px auto;
  width: calc(100% - 100px);
  border-radius: 10px;
  padding: 0px;
  min-height: calc(100vh - 215px);
}

.loginPage .mainContentCol .loginRow {
  height: calc(100vh - 220px);
}

.featuresRow {
  display: flex;
  flex-flow: row wrap;
  max-width: 1124px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.featuresCol {
  width: 33.33%;
  padding: 0 25px;
  margin-bottom: 30px;
}

.featuresBox {
  width: 100%;
  background-color: #fff;
  border: 10px solid #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.featuresBox .text a {
  display: block;
  text-align: center;
  font-size: 1.4em;
  margin: 15px 0 0;
}

.aboutInfoRow {
  margin: 50px 0 0;
  display: flex;
  flex-flow: row wrap;
}

.aboutInfoCol {
  width: 50%;
  background: #fff;
  padding: 50px;
}

.aboutInfoContent {
  margin: 20px 0 0;
}

.aboutInfoHead img {
  margin: 0 auto;
}

.aboutInfoHead .featureshead {
  margin: 0 0 10px;
}

.aboutInfoContent p {
  font-size: 17px;
  color: #6d7072;
  line-height: 28px;
  text-align: justify;
}

.aboutInfoCol:nth-child(1) {
  border-bottom-left-radius: 10px;
}

.aboutInfoCol:nth-child(2) {
  border-bottom-right-radius: 10px;
}

hr {
  margin: 20px auto;
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right,
      rgb(121, 85, 48, 0),
      rgba(121, 85, 48, 0.75),
      rgba(121, 85, 48, 0));
}

.qouteBox {
  padding: 42px;
}

.containerWithOutSideBar {
  padding: 50px 0;
}

.containerWithOutSideBar.landingPage {
  padding: 0 0 50px;
}

.containerWithOutSideBar.landingPage .homePageBackgroundUrl {
  margin-bottom: 50px;
}

.navimg {
  padding: 10px 0 0;
}

.navimg img {
  max-width: 70px;
}

.tabPanelBox {
  padding: 20px;
}

.thumbnailRowForThree {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}

.thumbnailRowForThreeCol {
  width: calc(33.33% - 2%);
  border: 5px solid #f9f9f9;
  border-radius: 8px;
  margin: 1%;
  border: 1px solid #eaeaea;
  box-shadow: rgba(149, 157, 165, 0.2) 0px -1px 10px 0px;
  min-height: 350px;
  overflow: hidden;
}

.thumbnailRowForThreeCol:hover {
  border: 1px solid #499c9f97;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.thumbnailImg img {
  width: 100%;
}

.thumbnailTitle {
  font-size: 22px;
  font-weight: 600;
  margin: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.thumbnailDescription {
  font-size: 16px;
  margin: 10px;
  width: 60%;
  min-height: 70px;
  overflow: hidden;
}

.thumbnailDescription p {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.thumbnailImg.forYou {
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbnailImg.forYou img {
  max-width: 175px;
  max-height: 200px;
}

.thumbnailImg.news {
  height: 285px;
  overflow: hidden;
}

.tabPanelBox .ant-tabs-tab-btn {
  font-size: 18px;
  font-weight: 600;
}

.tabPanelBox .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-size: 18px;
  font-weight: 600;
  color: #6d3a21;
}

:where(.css-dev-only-do-not-override-190m0jy).ant-tabs .ant-tabs-ink-bar {
  position: absolute;
  background: #6d3a21;
  pointer-events: none;
}

.infoBox {
  margin: 1%;
}

.infoBox p {
  font-size: 18px;
  line-height: 30px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.topPanelHead {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 1%;
}

.topPanelHead {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 1%;
}

.topPanelHead h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  font-family: "poppins", sans-serif;
}

.tabPanelBox .ant-tabs-nav .ant-tabs-nav-wrap {
  margin: 0 1%;
}

ul#profileInfoList {
  display: flex;
  flex-flow: row wrap;
}

ul#profileInfoList li {
  width: 50%;
  border-bottom: 1px solid #f7f7f7;
  padding: 5px 0;
}

ul#profileInfoList li:nth-child(odd) {
  font-weight: 600;
  width: 30%;
}

ul#profileInfoList li:nth-child(even) {
  width: 70%;
}

.sourcedataBox {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  font-size: 12px;
  padding: 2px 10px;
  color: #6d3a21;
}

.time-ago-format {
  color: gray;
}

.thumbnailRowForThree.requestbox .thumbnailRowForThreeCol {
  min-height: auto;
  width: 90%;
}

.thumbnailRowForThree.requestbox .thumbnailRowForThreeCol .thumbnailDescription {
  align-items: center;
  display: flex;
  /* white-space: pre; */
}

.reveived-comm-icon {
  width: 50px;
  height: 50px;
  padding: 4px;
  object-fit: cover;
  /* border: 1px solid #4096ff; */
  border-radius: 50%;
}

.reject-btn:hover {
  color: #ff000095 !important;
  box-shadow: #ff00007b 0px 1px 4px 0px;
  border: 1px solid #ff000095 !important;
}

.approve-btn:hover {
  color: #1bc51bfa !important;
  box-shadow: #7af47afa 0px 1px 4px 0px;
  border: 1px solid #1bc51bfa !important;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.footerPages{
  padding: 1% 0;
}
@media (max-width: 767px) {
  .featuresRow{
    flex-flow: column;
  }
  .featuresCol{
    width: auto;
  }
  .aboutInfoRow{
    flex-flow: column wrap;
  }
  .aboutInfoCol{
    width: 100%;
    padding: 20px;
  }
  .mainContentCol{
    width: calc(100% - 50px);
  }
  .vamshavrukshaHeading{
    display: inline-flex;
    position: relative;
    font-size: 14px;
    align-items: center;
    height: 40px;
    padding: 0 0 0 10px;
  }
  .communitypop .ant-modal-content{
    position: relative; 
    height: 90vh; 
   overflow-y: scroll;
  }
  .communitypop  .ant-form-item-control-input-content{
    align-items: center;
    display: flex;
  }
  .thumbnailRowForThree {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
}
.thumbnailRowForThree.requestbox .thumbnailRowForThreeCol{
  display: flex;
    padding: 10px 10px!important;
    align-items: center;
    width: 100%;
    flex-flow: column;
    justify-content: space-between;
}
.thumbnailRowForThreeCol{
  width: 100%;
    min-height: auto;
    margin-bottom: 20px;
}
.thumbnailImg.news{
  height: auto;
}
.navoptions div{
  display: inline-block;
  align-items: center;
  float: left;
  padding: 0;
}
.listnavbar img{
  margin: 0 auto;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  float: left;
}

.navoptions div p{
  padding-left: 20px;
  font-size: 18px;
  display: inline-block;
  float: left;

  font-weight: 700;
  line-height: 32px;
}
.navbar {
  width: auto!important;
  background:transparent!important;
}
.listnavbar .navoptions{
  height: 40px;
  display: table;
  /* padding: 1rem; */
  padding: 1.5rem 1rem 0px 1rem;
  width:100%
}
.listnavbar{
  display: block;
  padding-top: 0px;
}
.showlogomobile .profile {
  width: 100%;
  display: inline-flex;
  margin: 20px 20px 0;
  position: relative;
  min-height: 70px;
  min-width: 70px;
}
.showlogomobile .substring {
  display: inline-block;
  float: left;
  font-size: 1.5rem;
  margin-left: 0px!important;
}
.showlogomobile.profile img{
  height: 80px!important;
  border-radius: 50%;
  object-fit: cover;
  display: inline-block;
  float: left;
  width: 80px!important;
  max-width: 100px!important;
}
.showfootermobile .namediv{
    display: inline-block;
    float: left;
    width: 100px;
}
.ant-wave .css-dev-only-do-not-override-pr0fja .wave-motion-appear .wave-motion-appear-active .wave-motion{
  display: none!important;
}
.showlogomobile .namediv .cursor-default {
  top: 20px!important;
  margin-left: 35px!important;
  float: left;
  position: absolute!important;
  /* font-size: small; */
  font-size: 10px;
  width: 100%;

}
.listnavbar .messageBox {
  width: auto;
  font-size: 15px;
  display: inline-block!important;
  /* align-items: center; */
  /* justify-content: center; */
  border-radius: 50%;
  background: transparent;
  padding: 0;
  margin: 1rem 0 0 1rem;
  height: auto;
 
}
  .listnavbar .bellicon{
  width: auto;
  font-size: 15px;
  display: block!important;
  /* align-items: center; */
  /* justify-content: center; */
  border-radius: 50%;
  background: transparent;
  padding: 0;
  margin: 2rem 0 0 1rem;
  height: auto;
 
}
.listnavbar .messageBox img {
  width: 30px!important;
  height: 20px!important;
  margin-top: 5px;
}
.listnavbar .msgtxt{
  font-weight: 700;
    font-size: 18px;
    padding-left: 20px;
    position: relative;
    top: 0;
    color: #040100!important;
    width: auto;
    display: inline-block!important;
}
.listnavbar #logId{
  font-size: 18px;
    font-weight: 700;
    background: unset;
    margin-left: 40px;
    border: unset;
    color: #000;
}
.setalignlogout{
  margin: 1rem;
  margin-top: 2rem;
}
.listnavbar #logId{
  position: relative;
  top: 7px;
  margin-left: 54px;
}
.logoutimg{
  margin: 0 0 0 1rem;
}
.listnavbar div.py-3:first-child{
display: none;
}
.thumbnailDescription {
  font-size: 16px;
  margin: 10px;
  width: auto;
  min-height: 70px;
  overflow: hidden;
}
.pageLayoutContent {
  margin-top: 60px;
  background-color: #fff;
  z-index: 0;
  position: relative;
}
.mainContentCol {
  width: 100%;
  background: #fff;
}
.navoptions.selected p{
border-bottom: none;
}
.showlogomobile .namediv .cursor-default {
  display: inline-block;
  float: left;
  margin-left: 189px;
  color: #000;
  font-size: 20px;
  font-weight: 700;
  width: fit-content;
}
.hrline{
  border-bottom: 0.5px solid #6d70725c;
    width: 300px;
    margin-left: 20px;
    margin-top: 31px;
    display: block!important;
}
.feedsWrapper {
  background-color: #fff;
  height: 100%;
  position: relative;
}
.feedsContent {
  display: flex;
  flex-flow: row wrap;
  background: #fff;
}
.containerWithOutSideBar{
  padding: 0;
}
.showfootermobile{
  display: block!important;
    position: absolute;
    bottom: 0;
}
.showfooterdesktop{
  display: none;
}
.messageBox{
  height: auto;
  width: 34px;
}
.leftNavAndHeaderWrapper  .showlogomobile.headerlogo img{
  position: absolute;
    width: 30px;
    left: 50px;
}
.signUp{
  display: flex;
  justify-content: left;
  text-align: left;
}
.labelHeading{
  font-weight: 700!important;
  padding-bottom: 15px!important;
}
.thumbnailRowForThree.requestbox .thumbnailRowForThreeCol .thumbnailDescription{
  align-items: center;
    display: flex;
    flex-flow: row wrap;
    max-width: 70%;
    font-size: 16px;
}
.thumbnailRowForThree.requestbox .thumbnailRowForThreeCol{
  align-items: center;
    flex-flow: row wrap;
    justify-content: space-evenly!important;
}
.searchdiv .anticon-search svg{
  margin-top: 0!important;
}
.w-1\/3{
  width: 100%;
}
.infoBox p{
  display: none;
}
.hideheadermobile{
  display: none!important;
}
.pageLayoutContent.mediadevicemg{
  margin-top: 0!important;
}
.leftNavAndHeaderWrapper .leftNavVisible{
  width: 80%; 
}
.leftNavAndHeaderWrapper.leftNavHide {
  width: 0;
}
.ant-input-search .ant-input-search-button {
  height: 46px;
}

.rootSearchButton .ant-input-search-button{
    max-height: 40px;
  }
}
@media (min-width:600px) and (max-width:766px) {
  .leftNavAndHeaderWrapper .leftNavVisible{
    width: 50%; 
    
  }
  .containerWithOutSideBar{
     padding: 0!important; 
  }
}
@media (max-width: 767px) and (max-height: 700px){
  .listnavbar .navoptions{
    height: 40px;
    display: table;
    /* padding: 1rem; */
    padding: 1rem 1rem 0px 1rem;
    width:100%
  }
  .listnavbar img {
    margin: 0 auto;
    width: 20px;
    height: 30px;
    display: flex;
    align-items: center;
    float: left;
}
.listnavbar #logId {
  position: relative;
  top: 7px;
  margin-left: 37px;
}

}
@media (min-width : 601px) and (max-width: 900px){
  .feedsContainer {
    display: flex;
    flex-direction: column;
    font-family: "Poppins", sans-serif;
    margin-left: 0%;
    width: 100%;
  }
  .tabPanelBox .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 1rem;
    font-weight: 600;
    color: #6d3a21;
}
.tabPanelBox .ant-tabs-tab-btn {
  font-size: 0.95rem;
  font-weight: 600;
}
.ant-tabs .ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 1rem;
}

}
@media (min-width : 901px) and (max-width: 1100px){
  .feedsContainer {
    display: flex;
    flex-direction: column;
    font-family: "Poppins", sans-serif;
    margin-left: 0%;
    width: 100%;
  }

}
/* .showlogodesktop{
  width: 35%;
  display: flex;
  justify-content: start;
} */

.rootSearchButton .ant-input-search-button{
    min-height: 40px;
  }


.hideheadermobile{
  display: block;
}
.hrline{
  display: none;
}
.showfootermobile{
  display: none;
}


.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
@media (min-width:1200px) and (max-width:1500px){
  .homePageBackgroundUrl{
    zoom:0.8;
  }
  .titlecomm {
    font-size: 0.9rem;
    color: #af7d5c;
    text-align: center;
    padding-top: 9px!important;
    text-wrap: wrap;
    width: 100px;
  }
  .pageLayout-L {
    width: 6.5rem;
    box-shadow: 0px 3px 6px #c7a58f6b;
    position: relative;
  }
  .listnavbar p {
    font-size: 0.7rem;
    font-family: "Poppins", sans-serif;
    line-height: 22px;
    letter-spacing: 1.2px;
    text-align: center;
  }
  
  .listnavbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1.5rem;
  }
  
  .listnavbar .navoptions {
    padding: 0.6rem 0;
    /* padding: 10px 0px; */
  }
  
  .listnavbar img {
    margin: 0 auto;
    width: 1.2rem;
  }
  .navbar {
    background-color: #fff;
    position: fixed;
    z-index: 9;
    width: 6.5rem;
  }
  .navimg {
    padding: 10px 0 0;
  }
  
  .navimg img {
    max-width: 3.5rem;
  }
  .bellicon {
    padding: 0 12px 0 0;
  }
  .pageLayoutContent {
    margin-top: 3rem;
    background-color: #ebebeb;
    min-height: 85vh;
  }
  .mainContentCol {
    background-color: #f7f7f7;
    margin: 0px auto;
    width: calc(100% - 4.5rem);
    border-radius: 10px;
    padding: 0px;
    min-height: calc(100vh - 215px);
  }
  .pageLayout-R {
    width: calc(100% - 6.5rem);
  }

  .feedsContainer {
    display: flex;
    flex-direction: column;
    font-family: "Poppins", sans-serif;
    margin-left: 8%;
    width: 85%;
  }
  .ant-input-search .ant-input-search-button {
    height: 2.2rem;
    border: none !important;
    outline: none !important;
  }
  .ant-input-affix-wrapper >input.ant-input {
    padding: 0;
    font-size: 0.8rem;
    border: none;
    border-radius: 0;
  }
  .time-ago-format{
    font-size: 0.8rem;
  }
  .tabPanelBox {
    padding: 0.5rem 1rem;
  }
  .newsComponent{
    zoom:0.7;
  }
  .tabPanelBox .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 1rem;
    font-weight: 600;
    color: #6d3a21;
  }
  .tabPanelBox .ant-tabs-tab-btn {
    font-size: 1rem;
    font-weight: 600;
  }
  .infoBox p {
    font-size: 1rem;
    line-height: 30px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  }
  .footerUl a {
    font-weight: 500;
    margin: 0;
    padding: 0 10px;
    color: #6d7072;
    font-size: 0.65rem;
  }
  .copyright p {
    margin: 0;
    padding: 5px 0 0 0 ;
    color: #6d7072;
    font-size: 0.65rem;
  }
  .footerRow {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 0.5rem 0.5rem;
  }
  
  .loginCol{
    zoom: 0.7;
  }
  .rootSearchButton .ant-input-search-button{
    min-height: 40px;
  }
}

@media (width:1440px){
  .loginCol{
    zoom: 0.8;
  }
}

@media (min-width:1501px) and (max-width:1900px){
  .landingPage{
    zoom: 0.8;
  }

  .loginCol{
    zoom: 0.8;
  }

  .newsComponent{
    zoom: 0.7;
  }

  .rootSearchButton .ant-input-search-button{
    min-height: 40px;
  }

  .titlecomm {
    font-size: 0.8rem;
    color: #af7d5c;
    text-align: center;
    padding-top: 10px;
    text-wrap: wrap;
    width: 100px;
  }
  .pageLayout-L {
    width: 6.5rem;
    box-shadow: 0px 3px 6px #c7a58f6b;
    position: relative;
  }
  .listnavbar p {
    font-size: 0.7rem;
    font-family: "Poppins", sans-serif;
    line-height: 22px;
    letter-spacing: 1.2px;
    text-align: center;
  }
  
  .listnavbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1.5rem;
  }
  
  .listnavbar .navoptions {
    padding: 0.8rem 0;
    /* padding: 10px 0px; */
  }
  
  .listnavbar img {
    margin: 0 auto;
    width: 1.5rem;
  }
  .navbar {
    background-color: #fff;
    position: fixed;
    z-index: 9;
    width: 6.5rem;
  }
  .navimg {
    padding: 10px 0 0;
  }
  
  .navimg img {
    max-width: 4.5rem;
  }
  .bellicon {
    padding: 0 10px 0 0;
  }
  .pageLayoutContent {
    margin-top: 3rem;
    background-color: #ebebeb;
    min-height: 85vh;
  }
  .mainContentCol {
    background-color: #f7f7f7;
    margin: 0px auto;
    width: calc(100% - 4.5rem);
    border-radius: 10px;
    padding: 0px;
    min-height: calc(100vh - 152px);
  }
  .pageLayout-R {
    width: calc(100% - 6.5rem);
  }
  .feedsContainer {
    display: flex;
    flex-direction: column;
    font-family: "Poppins", sans-serif;
    margin-left: 12%;
    width: 85%;
  }
  .ant-input-search .ant-input-search-button {
    height: 2.2rem;
    border: none !important;
    outline: none !important;
  }
  .ant-input-affix-wrapper >input.ant-input {
    padding: 0;
    font-size: 0.8rem;
    border: none;
    border-radius: 0;
  }
  .time-ago-format{
    font-size: 0.8rem;
  }
  .tabPanelBox {
    padding: 0.5rem 1rem;
  }
  .tabPanelBox .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 1rem;
    font-weight: 600;
    color: #6d3a21;
  }
  .tabPanelBox .ant-tabs-tab-btn {
    font-size: 1rem;
    font-weight: 600;
  }
  .infoBox p {
    font-size: 1rem;
    line-height: 30px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  }
  .footerUl a {
    font-weight: 500;
    margin: 0;
    padding: 0 10px;
    color: #6d7072;
    font-size: 0.65rem;
  }
  .copyright p {
    margin: 0;
    padding: 5px 0 0 0 ;
    color: #6d7072;
    font-size: 0.65rem;
  }
  .footerRow {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 0.5rem 0.5rem;
  }
}

@media (max-height:600px) and (max-width:400px) {
  .navbar {
    height: 70%;
    overflow: auto;
  }
}