.communityfeedsLeftSide{
  width: calc(100% - 415px);
      height: 100%;
      background-color: #F7F7F7;
      padding: 0px;
      border-radius: 23px 0px 0px 23px;
      /* overflow: auto; */
      min-height: 750px;
  }
  .feedsRightContainer {
    position: sticky;
    top: 32px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 32px); /* Adjust the height as needed */
  }
  
  .feedsRight {
    background-color: #F7F7F7;
    border-radius: 0px 23px 0px 0px;
    width: 415px;
    display: flex;
    flex-direction: column;
    height: 100%; /* Make it take the full height of the container */
  }
  
  .feedsRightSideTop {
      width: 415px;
      background-color: white;
      padding: 30px 30px 0px 30px;
      box-shadow: -2px 0px 5px #cfcfcf57;
      border-radius: 0px 23px 0px 0px;
      position: sticky;
      /* top: 32px; */
      /* min-height: 340px; */
      max-height: 460px;
      /* min-height: 450px; */
      /* height: 100vh; */
      right: 0px;
      bottom: 0px;
      overflow-y: scroll;
      padding-top: 25px;
    }
  
    .feedsRightSideMiddle {
      width: 415px;
      background-color: white;
      padding: 30px 30px 0px 30px;
      box-shadow: -2px 0px 5px #cfcfcf57;
      border-radius: 0px 0px 0px 0px;
      overflow-y: scroll;
      /* max-height: 429px; */
      max-height: 100%;
      /* max-height: 429px; */
      margin-top: 10px;
      position: sticky;
      padding-top: 25px;
    }
  
  
    .feedsRightSideTop::-webkit-scrollbar,
  .feedsRightSideMiddle::-webkit-scrollbar {
    width:12px; /* Width of the scrollbar */
    height:50px;
  }

  .emailAlert{
    position: absolute;
    margin-top: 5px;
  }
  
  /* Customize the scrollbar thumb (handle) */
  .feedsRightSideTop::-webkit-scrollbar-thumb,
  .feedsRightSideMiddle::-webkit-scrollbar-thumb {
    background-color: #d4d3d3; /* Color of the thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
  }
  
  /* Customize the scrollbar track (background) */
  .feedsRightSideTop::-webkit-scrollbar-track,
  .feedsRightSideMiddle::-webkit-scrollbar-track {
    background-color: #f7f7f700; /* Color of the track */
    border-radius: 10px; /* Rounded corners for the track */
  }
  
  /* You can also add similar styles for Firefox */
  /* .feedsRightSideTop {
    scrollbar-color: #333 #F7F7F7; 
  }
  
  .feedsRightSideMiddle {
    scrollbar-color: #333 #F7F7F7; 
  } */
  
    .RightSideTextLabel {
      color: #040100;
      font-size: 20px;
      margin-bottom: 25px;
      font-weight: 700;
      line-height: 30px;
    }
  
    .headtop {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      font-family: "poppins", sans-serif;
    }
  
    .containerWithOutSideBar {
      padding: 50px 0;
    }
  
    .mainPreviewContentCol {
      background-color: #f7f7f7;
      margin: 0px auto;
      width: calc(100% - 100px);
      border-radius: 10px;
      padding: 0px;
      min-height: calc(100vh - 215px);
    }
    .communityfeedsPostContainer {
      margin:0px 0px 0px 0px;
      min-width:100%
  }
  
  .postMembertitle {
    font-weight: 600;
    font-size: 14px;
    padding-left: 0px;
    display: flex;
    letter-spacing: 1.7px;
    align-items: center;
    text-align: center;
  }

  .postMembertitle1 {
    font-weight: 700;
    font-size: 14px;
    padding-left: 0px;
    display: flex;
    letter-spacing: 1.7px;
    align-items: center;
    text-align: center;
  }
  
  .greenBorder {
    border: 2px solid green;
  }
  
.showMobile{
  display: block!important;
}
.showMobileButton{
display: none!important;
}

.membersList{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.member{
  width: 120px;
  height: 120px;
}
.member > img{
  width: 120px;
  height: 120px;
  object-fit: cover;
}
.modalmember{
  width: 50px;
  height: 50px;
}
.modalmember > img{
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.eachMember{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 15px;
}
.modalTitle{
  padding: 10px 0px 0px 20px;
  font-size: 25px;
}
.modalContainer{
  padding-bottom: 10px;
  max-height: 350px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.communityPreviewBack{
position: absolute;
                      width: 2%;
                      margin: 1%;
                      background-color: white;
                      border-radius: 50%;
                      cursor: pointer;
}
.communityName{
  margin-bottom: 0.5rem;
  font-size: 2.25rem;
    line-height: 2.5rem;
    font-weight:700;
}
.categoryName{
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-transform: capitalize;
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
  display: flex;
  flex-direction: row;
  align-items: center;
}
.noRecentActivity{
  font-size: 1.25rem; /* 20px */
line-height: 1.75rem; /* 28px */
}
.communityDP{
  width: 220px;
  height: 220px;
  border-radius: 10%;
  position: relative;
  bottom: 100px;
  left: 20px;
  background-color: white;
  object-fit: cover;
  border-width: 2px;
  border-color: white;
}
.CommunityInfo{
  margin-top: 1.25rem; /* 20px */
  margin-left: 2.5rem; /* 40px */
margin-right: 2.5rem; /* 40px */
}
.postsMembersInfo{
  margin-right: 2.5rem; /* 40px */
}
.postsInfo{
  font-size: 3rem; /* 48px */
line-height: 1;
margin-right: 1.25rem; /* 20px */
}
.membersInfo{
  font-size: 3rem; /* 48px */
line-height: 1;
  margin-left: 2.5rem; /* 40px */
}
.joinGroupButtonAlign{
  margin-right: 2.5rem; /* 40px */
}
.communityDataDescription{
  font-size: 1.25rem; /* 20px */
line-height: 1.75rem; /* 28px */
margin-top: 0.75rem; /* 12px */
margin-bottom: 1.25rem; /* 20px */
margin-left: 1.25rem; /* 20px */
margin-right: 1.25rem; /* 20px */
}
.trendingRow{
  padding-bottom: 1.5rem; /* 24px */
}
.trendingCommunityImage{
  width: 50px;
  height: 50px;
  border-radius: 50px;
  object-fit: cover;
}
.latestActivityItem{
  padding-bottom: 0.75rem; /* 12px */
}
.latestActivityProfile{
  width: 45px;
  height:45px;
  border-radius: 50%;
}
.latestActivityImage > span > img{
  width: 3rem; /* 48px */
  height: 3rem; /* 48px */
  object-fit: cover;
}
.dropdownEmail {
  font-size: 18px;
  font-weight: 400px !important;
}
.mobilepostMembertitle1 {
  display: none;
}

.blockedImage{
  filter: blur(2px);
}
.blockedTextMessage{
  text-align: center;
  color: red;
}


@media(max-width:600px){
  .communityfeedsLeftSide{
    width: auto;
  }
  .communitypreviewbanner {
    max-height: 200px!important;
    width: 100%;
    height: auto!important;
    object-fit: cover;
}
  .communityPreviewWrap {
    flex-direction: column!important;
  }
  .feedsRightContainer{
    position: relative;
    height: auto;
    width: auto;
    display: none;
  }
  .feedsRight{
    width: auto;
  }
  .aboutCommunityInfo {
    flex-direction: column;
    position: relative;
    top: -25px;
    justify-content: center;
}
.communitypreviewdesc {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  padding: 0;
}
  .communityPreviewBack{
  display: none;
  }
  .feedsContent {
    display: flex;
    flex-flow: column wrap;
    width: auto;
    justify-content: space-around;
}
.communityfeedsPostContainer {
  margin: 0px 0px 0px 0px;
  min-width: unset;
  overflow: hidden;
}
.pageWrapper{
  padding: 0!important;
  margin: 0!important;
}
.previewwrapper{
  margin: 0!important;
}
.communityPreviewWrap img{
  width: 100px!important;
    height: 100px!important;
    border-radius: 10%;
    position: relative;
    bottom: 50px!important;
    left: 20px;
    background-color: white;
    object-fit: cover;
    border-width: 2px;
    border-color: white;
}
.aboutCommunityWrapper{
  padding: 0 10px;
  width: auto;
  margin: 0;
}
.aboutCommunityWrapper .aboutCommunityInfo span.mb-2.text-4xl.font-bold{
  font-size: 2rem;
}
.text-5xl{
  font-size: 2rem;
  /* line-height: 1; */
}
.modalpopup {
  width: 100%!important;
  top: 0!important;
}
.communityForm .ant-col.ant-form-item-control{
  width: auto;
  margin: 0;
}
.communitypreviewdesc .capitalize.relative.inline-flex.items-center.justify-center.overflow-hidden{
  width: 65px!important;
  height: 65px!important;
  border-radius: 50%!important;

}
.communitypreviewdesc .capitalize.relative.inline-flex.items-center.justify-center.overflow-hidden > img{
  width: 65px!important;
  height: 65px!important;
  border-radius: 50%!important;

}
.membersList{
  margin:5px 5px 5px 10px;
}
.memberName{
  display: none;
}
.eachMember{
  margin: 0 2px
}
.memberSubstring{
  /* margin-top: 5px; */
}
.text-xl.mt-3.mb-5.mx-5{
  font-size: 1.1rem;
}
.setPaddingMobile{
  padding: 0 10px!important;
}
.communityfeedsLeftSide{
  border-radius: unset!important;
  min-height: 100vh;
}
.inputBoxForImage{
  font-size: 16px!important;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 2; /* Ensure the overlay is above other content */
  display: none; /* Initially hidden */
}

.overlay.show {
  display: block; /* Show the overlay when needed */
}
.showDesktop{
  display: none;
}
.modalpopup.showMobile{
    left: 0;
    width: 40%;
    transform-origin: 0 0;
    position: absolute;
    bottom: 0;
    width: 100%!important;
    right: 0;
    height: auto;
    display: flex!important;
}
.modalpopup .ant-modal-content{
  bottom: 0;
  position: absolute;
  width: 100%!important;
  min-height: 150px;
}
.modalpopup .feeds-header{
  display: none;
}
.modalpopup  .FeedPostContent{
  display: none;
}
.modalpopup  .feeds-img-video-container{
  display: none;
}
.modalpopup  .post-like-share{
  display: none;
}
.modalpopup .ant-modal-header{
  background: rgb(64 64 64 / 28%);
  margin-bottom: 0;
}
.modalpopup .ant-modal-footer{
  margin-top: 0;
}
.modalpopup  .comment-section {
  padding: 0;
}

.post-comments span img {
    height: auto!important;
    width: auto!important;

}
.privatetxt{
  text-align: center;
  font-size: 22px;;
}
.showMobileButton{
  display: flex!important;
  }
  .modalTitle{
    padding: 1px 0px 0px 20px;
    font-size: 20px;
  }
  .modalmember{
    width: 40px;
    height: 40px;
  }
  .modalmember > img{
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
  .membersInfo{
    font-size: 3rem; /* 48px */
  line-height: 1;
    margin-left: 2.5rem; /* 40px */
  }
}
@media (min-width : 601px) and (max-width: 900px){
  .feedsRight {
    display: none;
  }
  .communityfeedsLeftSide{
    width: 100%;
  }
  .communityPreviewWrap {
    flex-direction: column!important;
  }
  .aboutCommunityInfo {
    flex-direction: column;
    position: relative;
    top: -100px;
    justify-content: center;
}
.communitypreviewdesc {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  padding: 0;
}
.aboutCommunityWrapper{
  margin:0px!important;
  padding:1rem  0 0 1.5rem;
  height: 150px;
}
.joingroup{
  margin-top: 10px!important;
}
.communityPreviewpopupModal{
  width: 70%!important;
}
.showMobileButton{
  display: flex!important;
  }

.membersList{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.member{
  width: 100px;
  height: 100px;
}
.member > img{
  width: 100px;
  height: 100px;
  object-fit: cover;

}
.modalmember{
  width: 50px;
  height: 50px;
}
.modalmember > img{
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.eachMember{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 12px;
}
.modalTitle{
  padding: 10px 0px 0px 20px;
  font-size: 25px;
}
.modalContainer{
  padding-bottom: 10px;
  max-height: 350px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.memberName{
  display: none;
}
.communityPreviewBack{
  position: absolute;
                        width: 5%;
                        margin: 1%;
                        background-color: white;
                        border-radius: 50%;
                        cursor: pointer;
  }
}
@media (min-width : 901px) and (max-width: 1100px){
  .feedsRight {
    display: none;
  }
  .communityfeedsLeftSide{
    width: 100%;
        background-color: #F7F7F7;
        padding: 0px;
        border-radius: 23px 23px 23px 23px;
        /* overflow: auto; */
        min-height: 90vh;
    }
  .communityPreviewBack{
    position: absolute;
                          width: 5%;
                          margin: 1%;
                          background-color: white;
                          border-radius: 50%;
                          cursor: pointer;
    }
    .member{
      width: 100px;
      height: 100px;
    }
    .member > img{
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
    .eachMember{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0px 10px;
    }
    .memberName{
      display: none;
    }
    .communityName{
      margin-bottom: 0.5rem;
      font-size: 1.75rem;
        line-height: 1.75rem;
        font-weight:700;
        text-overflow: ellipsis;
    }
    /* .communityfeedsPostContainer {
      margin: 0px 0px 0px 0px;
      min-height: 90vh;
      overflow: hidden;
    } */
    .categoryName{
      font-size: 1rem;
      line-height: 1.15rem;
      text-transform: capitalize;
      --tw-text-opacity: 1;
      color: rgb(100 116 139 / var(--tw-text-opacity));
      display: flex;
      flex-direction: row;
      align-items: center;
    }
}
@media (min-width:1200px) and (max-width:1500px){
  .RightSideTextLabel {
    color: #040100;
    font-size: 1rem;
    margin-bottom: 1rem;
    font-weight: 700;
    line-height: 30px;
  }
  .containerWithOutSideBar {
    padding: 2.5rem 0;
  }
  .communityfeedsLeftSide{
    width: calc(100% - 300px);
        height: 100%;
        background-color: #F7F7F7;
        padding: 0px;
        border-radius: 23px 0px 0px 23px;
        /* overflow: auto; */
        min-height: 750px;
    }
    .feedsRightContainer {
      position: sticky;
      top: 32px;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 32px); /* Adjust the height as needed */
    }
    
    .feedsRight {
      background-color: #F7F7F7;
      border-radius: 0px 23px 0px 0px;
      width: 300px;
      display: flex;
      flex-direction: column;
      height: 100%; /* Make it take the full height of the container */
    }
    
    .feedsRightSideTop {
        width: 100%;
        background-color: white;
        padding: 1rem 1rem 0px 1rem;
        box-shadow: -2px 0px 5px #cfcfcf57;
        border-radius: 0px 23px 0px 0px;
        position: sticky;
        /* top: 32px; */
        /* min-height: 340px; */
        max-height: 20rem;
        /* min-height: 450px; */
        /* height: 100vh; */
        right: 0px;
        bottom: 0px;
        overflow-y: scroll;
        /* padding-top: 25px; */
      }
    
      .feedsRightSideMiddle {
        width: 100%;
        background-color: white;
        padding: 1rem 2.5rem 1rem 1rem;
        box-shadow: -2px 0px 5px #cfcfcf57;
        border-radius: 0px 0px 0px 0px;
        overflow-y: scroll;
        /* max-height: 429px; */
        max-height: 100%;
        /* max-height: 429px; */
        margin-top: 10px;
        position: sticky;
        padding-top: 25px;
      }
      .noRecentActivity{
        font-size: 1rem; /* 20px */
      line-height: 1.75rem; /* 28px */
      }
      .communitypreviewbanner {
        height: 300px!important;
        width: 100%;
        object-fit: cover;
    }
    .communityDP{
      width: 12rem;
      height: 12rem;
      border-radius: 10%;
      position: relative;
      bottom: 100px;
      left: 20px;
      background-color: white;
      object-fit: cover;
      border-width: 2px;
      border-color: white;
    }
    .communityName{
      /* margin-bottom: 0.5rem; */
      font-size: 1.75rem;
        line-height: 2.5rem;
        font-weight:700;
    }
    .CommunityInfo{
      margin-top: 0.5rem; /* 20px */
      margin-left: 2.5rem; /* 40px */
    margin-right: 2.5rem; /* 40px */
    }
    .categoryName{
      font-size: 1rem;
      line-height: 1rem;
      text-transform: capitalize;
      --tw-text-opacity: 1;
      color: rgb(100 116 139 / var(--tw-text-opacity));
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .postsMembersInfo{
      margin-right: 0.5rem; /* 40px */
      padding-top: 0.5rem;
    }
    .postsInfo{
      font-size: 2rem; /* 48px */
    line-height: 1;
    margin-right: 1rem; /* 20px */
    }
    .membersInfo{
      font-size: 2rem; /* 48px */
    line-height: 1;
      margin-left: 2.5rem; /* 40px */
    }
    .joinGroupButtonAlign{
      margin-right: 0rem; /* 40px */
      zoom:0.8
    }
    .joingroup{
      zoom:0.8
    }
    .communityDataDescription{
      font-size: 1rem; /* 20px */
    line-height: 1.75rem; /* 28px */
    margin-top: 0.75rem; /* 12px */
    margin-bottom: 1.25rem; /* 20px */
    margin-left: 1.25rem; /* 20px */
    margin-right: 1.25rem; /* 20px */
    }
    .member{
      width: 5.5rem;
      height: 5.5rem;
    }
    .member > img{
      width: 5.5rem;
      height: 5.5rem;
      object-fit: cover;
    }
    .modalmember{
      width: 50px;
      height: 50px;
    }
    .modalmember > img{
      width: 50px;
      height: 50px;
      object-fit: cover;
    }
    .postMembertitle {
      font-weight: 600;
      font-size: 0.7rem!important;
      padding-left: 0px;
      display: flex;
      letter-spacing: 1.7px;
      align-items: center;
    }
    .trendingRow{
      padding-bottom: 1rem; /* 24px */
    }
    .trendingCommunityImage{
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50px;
      object-fit: cover;
    }
    .feedsRightSideTop::-webkit-scrollbar,
    .feedsRightSideMiddle::-webkit-scrollbar {
      width:0.5rem; /* Width of the scrollbar */
      height:50px;
    }
    .latestActivityItem{
      padding-bottom: 0.25rem; /* 12px */
    }
    .latestActivityProfile{
      width: 2rem;
      height:2rem;
      border-radius: 50%;
    }
    .latestActivityImage > span > img{
      width: 2rem; /* 48px */
      height: 2rem; /* 48px */
      object-fit: cover;
    }
    .modalTitle{
      padding: 0.5rem 0px 0px 20px;
      font-size: 1.3rem;
    }
    .privatetxt{
      text-align: center;
      font-size: 1.5rem;
    }
    .eachMember{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0px 10px;
    }
}
@media (min-width:1501px) and (max-width:1900px){
  .RightSideTextLabel {
    color: #040100;
    font-size: 1rem;
    margin-bottom: 1rem;
    font-weight: 700;
    line-height: 30px;
  }
  .containerWithOutSideBar {
    padding: 2.5rem 0;
  }
  .communityfeedsLeftSide{
    width: calc(100% - 350px);
        height: 100%;
        background-color: #F7F7F7;
        padding: 0px;
        border-radius: 23px 0px 0px 23px;
        /* overflow: auto; */
        min-height: 750px;
    }
    .feedsRightContainer {
      position: sticky;
      top: 32px;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 32px); /* Adjust the height as needed */
    }
    
    .feedsRight {
      background-color: #F7F7F7;
      border-radius: 0px 23px 0px 0px;
      width: 350px;
      display: flex;
      flex-direction: column;
      height: 100%; /* Make it take the full height of the container */
    }
    
    .feedsRightSideTop {
        width: 100%;
        background-color: white;
        padding: 1rem 1rem 0px 1rem;
        box-shadow: -2px 0px 5px #cfcfcf57;
        border-radius: 0px 23px 0px 0px;
        position: sticky;
        /* top: 32px; */
        /* min-height: 340px; */
        max-height: 22rem;
        /* min-height: 450px; */
        /* height: 100vh; */
        right: 0px;
        bottom: 0px;
        overflow-y: scroll;
        /* padding-top: 25px; */
      }
    
      .feedsRightSideMiddle {
        width: 100%;
        background-color: white;
        padding: 1rem 2.5rem 1rem 1rem;
        box-shadow: -2px 0px 5px #cfcfcf57;
        border-radius: 0px 0px 0px 0px;
        overflow-y: scroll;
        /* max-height: 429px; */
        max-height: 100%;
        /* max-height: 429px; */
        margin-top: 10px;
        position: sticky;
        padding-top: 25px;
      }
      .noRecentActivity{
        font-size: 1rem; /* 20px */
      line-height: 1.75rem; /* 28px */
      }
      .communitypreviewbanner {
        height: 300px!important;
        width: 100%;
        object-fit: cover;
    }
    .communityDP{
      width: 12rem;
      height: 12rem;
      border-radius: 10%;
      position: relative;
      bottom: 100px;
      left: 20px;
      background-color: white;
      object-fit: cover;
      border-width: 2px;
      border-color: white;
    }
    .communityName{
      /* margin-bottom: 0.5rem; */
      font-size: 1.75rem;
        line-height: 2.5rem;
        font-weight:700;
    }
    .CommunityInfo{
      margin-top: 0.5rem; /* 20px */
      margin-left: 2.5rem; /* 40px */
    margin-right: 2.5rem; /* 40px */
    }
    .categoryName{
      font-size: 1rem;
      line-height: 1rem;
      text-transform: capitalize;
      --tw-text-opacity: 1;
      color: rgb(100 116 139 / var(--tw-text-opacity));
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .postsMembersInfo{
      margin-right: 0.5rem; /* 40px */
      padding-top: 0.5rem;
    }
    .postsInfo{
      font-size: 2rem; /* 48px */
    line-height: 1;
    margin-right: 1rem; /* 20px */
    }
    .membersInfo{
      font-size: 2rem; /* 48px */
    line-height: 1;
      margin-left: 2.5rem; /* 40px */
    }
    .joinGroupButtonAlign{
      margin-right: 0rem; /* 40px */
      zoom:0.8
    }
    .joingroup{
      zoom:0.8
    }
    .communityDataDescription{
      font-size: 1rem; /* 20px */
    line-height: 1.75rem; /* 28px */
    margin-top: 0.75rem; /* 12px */
    margin-bottom: 1.25rem; /* 20px */
    margin-left: 1.25rem; /* 20px */
    margin-right: 1.25rem; /* 20px */
    }
    .member{
      width: 6.5rem;
      height: 6.5rem;
    }
    .member > img{
      width: 6.5rem;
      height: 6.5rem;
      object-fit: cover;
    }
    .modalmember{
      width: 50px;
      height: 50px;
    }
    .modalmember > img{
      width: 50px;
      height: 50px;
      object-fit: cover;
    }
    .postMembertitle {
      font-weight: 600;
      font-size: 0.7rem!important;
      padding-left: 0px;
      display: flex;
      letter-spacing: 1.7px;
      align-items: center;
    }
    .trendingRow{
      padding-bottom: 1rem; /* 24px */
    }
    .trendingCommunityImage{
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50px;
      object-fit: cover;
    }
    .feedsRightSideTop::-webkit-scrollbar,
    .feedsRightSideMiddle::-webkit-scrollbar {
      width:0.5rem; /* Width of the scrollbar */
      height:50px;
    }
    .latestActivityItem{
      padding-bottom: 0.25rem; /* 12px */
    }
    .latestActivityProfile{
      width: 2rem;
      height:2rem;
      border-radius: 50%;
    }
    .latestActivityImage > span > img{
      width: 2rem; /* 48px */
      height: 2rem; /* 48px */
      object-fit: cover;
    }
    .modalTitle{
      padding: 0.5rem 0px 0px 20px;
      font-size: 1.3rem;
    }
    .privatetxt{
      text-align: center;
      font-size: 1.5rem;
    }
    .eachMember{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0px 15px;
    }
}
@media(max-width:769px) {
  /* .plusCreate {
    display: flex;
    font-size: 45px;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 10px;
    z-index: 999;
    right: 12px;
    width: 75px;
    height: 75px;
    border-radius: 50%!important;
    border-width: 0.5px;
    border-color: #a9a9a9;
    background-color: rgb(246, 246, 246);
  } */
  .feedscreatecommunityButton {
    /* display: none */
    /* border: 1px solid red !important; */
    width: 65px !important;
    height: 65px !important;
    border-radius: 50% !important;
    position: inherit;
    z-index: 99;
    background-color: rgb(255, 255, 255);
    /* border-width: 0.5px; */
    /* border-color: gray; */
    border: 1px solid gray;
    margin-left: 3px;
  }
  /* .postMembertitle1 {
    position: absolute;
    z-index: 999;
    bottom: 10px;
    font-size: 8px;
  } */
  .postMembertitle1 {
    display: none;
  }
}