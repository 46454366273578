.indexedStep {
    color: rgb(110, 110, 110);
    width: 65px;
    height: 65px;
    font-size: 12px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    border-style: solid;
    border-width: 7px;
    /* border-color: rgb(206, 206, 206); */
    border-color: #686F2E;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkIcon {
    transform: scale(2.5);
}

/* .stepContainer.accomplished .indexedStep.accomplished img {
    cursor: pointer;
    border: 2px solid green;
} */

.progress-icons {
    width: 30px;
    height: auto;
}

.indexedStep.accomplished {
    /* background-color: #6D3A21; */
    color: white;
    /* border-style: none; */
    border-color: #6D3A21;
}

.stepContainer {
    pointer-events: none;
}

.step {
    cursor: not-allowed;
}

.accomplished {
    cursor: pointer;
    pointer-events: all;
}



.stepContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 65px;
    overflow: visible;
    /* padding-top: 65%; */
}

.caption {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    font-family: "Poppins", sans-serif;
    white-space: nowrap;
}

.RSPBprogressBar {
    height: 10px;
    width: 80%;
    line-height: 1;
    border-radius: 10px;
    position: relative;
    background-color: rgb(207, 207, 207);
    display: flex;
    justify-content: space-between;
    align-items: start;
    z-index: 0;
    margin: 20px auto;
    margin-top: 40px;
    margin-bottom: 40px;
}

.RSPBprogressBar .RSPBstep {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: -30px;
    z-index: 0;
    position: absolute;
    transform: translateX(-50%);
    transition-property: all;
    transition-timing-function: ease;
}

.RSPBprogressBar .RSPBprogressBarText {
    color: white;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.RSPBprogressBar .RSPBprogression {
    position: absolute;
    transition: width 0.3s ease;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
    background: #6D3A21;
    z-index: -1;
}

@media screen and (max-width: 480px) {
    .indexedStep {
        width: 15px;
        height: 15px;
        font-size: 6px;
    }
    .caption {
        padding-top: 10px;
        display: flex;
        justify-content: center;
        display: none;
        font-family: "Poppins", sans-serif;
        white-space: nowrap;
    }
}
@media screen and (max-width :767px){
    .RSPBprogressBar{
        display: none;

    }
}