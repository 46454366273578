.terms-and-conditions-modal .ant-modal-content .ant-modal-body{
    padding:20px
}
.terms-and-conditions-modal .ant-modal-content .ant-modal-header .ant-modal-title{
    padding:20px 20px 0px 20px;
    font-size: 25px;
}
.terms-and-conditions-modal .ant-modal-content .ant-modal-body h2{
    font-size: 18px;
    font-weight: 600;
    padding: 10px 0
}
.terms-and-conditions-modal{
    top: 50px !important;
    width:50vw !important
    }

    @media (max-width:767px){
        .terms-and-conditions-modal{
    top: 10px !important;

            width:90vw!important
            }
    }