.backiconmatrimony {
    position: absolute;
    width: 3%;
    margin: 1%;
    margin-bottom: 5px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    top: 0px;
    left: 0px;
}
.ageRange > .ant-form-item{
    width: 30%;
}

.recommend-similarprofiles{
    width: 415px;
    background-color: white;
    padding: 30px;
    border-radius: 0px 23px 23px 0px;
    position: sticky;
    height: 100%;
    right: 0;
    bottom: 0;
}

@media (min-width:1200px) and (max-width:1500px) {
    .filtercontents{
    width: 375px;
    max-height: 370px;
    }
    .matrimonyProfile {
        zoom: 0.6;
    }

    .recommend-similarprofiles{
        zoom: 0.65;
    }

    .profileData{
        zoom: 0.6;
    }

    .yourProfiles{
        zoom: 0.6;
    }

    .matrimonyExplore {
        zoom: 0.6;
    }

    .ant-select-dropdown .ant-select-item {
        font-size: 0.70rem;
    }

    .ant-select-selection-item {
        font-size: 0.60rem;
    }
    .ageRangeTo{
        margin-right: 0.5rem;
    }
}

@media (min-width:1501px) and (max-width:1900px){
    .ageRangeTo{
        margin-right: 0.5rem;
    }
    .heightRangeTo{
        margin-right: 0.5rem;
        margin-left:0.5rem
    }
    .matrimonyProfile{
        zoom: 0.6;
    }

    .matrimonyProfileView{
        zoom:0.8;
    }

    .yourProfiles{
        zoom: 0.8;
    }
    .matrimonyExplore{
        zoom: 0.8;
    }

    .ant-select-dropdown .ant-select-item {
        font-size: 0.75rem;
    }

    .ant-select-selection-item {
        font-size: 0.75rem;
    }
}

@media(max-width:1025px) and (min-width: 768px) {
    .about {
        padding: 1rem 0;
    }

    .recommend-similarprofiles {
        display: none;
    }

    .profileDetails .flex.space-x-10 {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .profileDetails .list-disc {
        padding: 0 !important;
        margin: 0 !important;
    }

    .profileData {
        width: 100%;
        grid-column: span12;
        padding-right: 0px;
        margin: 0;
    }

    .backiconmatrimony {
        position: absolute;
        width: 5%;
        margin: 1%;
        background-color: white;
        border-radius: 50%;
        cursor: pointer;
        top: 0px;
        left: 0px;
    }
}


@media(max-width:767px) {
    .matrimonyMain .matrimonyBanner {
        display: none;
    }

    .matrimonyMain .createButton {
        margin: 15px 0;
        top: 0;
        position: relative;
        left: 0;
    }

    .matrimonyMain .btnwrapper {
        width: 97%;
        text-align: right;
        margin: 0;
    }

    .profileList {
        display: flex;
        flex-basis: 23%;
        flex-basis: 100%;
        flex-direction: column;
        width: 100%;
    }

    .matrimonyMain .filterDiv {
        position: relative;
        width: 100%;
        right: 0;
        text-align: left;
    }

    .matrimonyMain .filterDiv div {
        text-align: left;
    }

    .matrimonyMain .filterDiv .filtertxt {
        padding: 0;
        margin: 0;
    }

    .matrimonyMain .matrimonySearch {
        position: relative;
        right: 0;
        width: 100%;
        bottom: 0;
    }

    .mmatrimonyProfileView {
        padding: 0;
        margin: 0;
    }

    .mmatrimonyProfileView .col-span-9 {
        width: 100%;
        grid-column: none;
    }

    .profileimgWrap .col-span-9 {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
    }

    .profileDetails .flex.space-x-10 {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .profileDetails .list-disc {
        padding: 0 !important;
        margin: 0 !important;
    }

    .wrapColumn {
        display: flex;
        flex-direction: column;
    }

    .wrapColumn .flex.flex-wrap .items-center.text-lg {
        flex-flow: row wrap;
        display: flex;
        width: 100%;
        align-items: baseline;
        justify-content: normal;
    }

    .wrapColumn .text-xl {
        display: block;
    }

    .profileDetailsouter {
        grid-column: none;
        padding: 0;
        margin: 0;
    }

    .profileDetailsouter button {
        top: -59px;
        position: absolute;
    }

    .matrimonyHamWrap {
        position: relative;
        width: 100%;
        padding: 10px 20px 10px;
        display: flex !important;
        justify-content: right;
    }

    .matrimonyHamWrap .hamburgermenu.showmobile {
        width: 30px;
        height: 30px;
        right: 0;
        top: -10;
        padding-top: 0px;
        position: relative;
    }

    .filterDiv {
        transition: all 0.3s ease;
    }

    .profileData {
        width: 100%;
        grid-column: span12;
        padding: 0;
        margin: 0;
    }

    .matrimonyProfileView {
        margin: 10px;
    }

    .backiconmatrimony {
        display: none;
    }

    .filtercontents {
        width: auto !important;
        max-height: unset !important;
        z-index: 3;
        /* right: 50%; */
        right: 0;
        left: 0;
        opacity: 2;
    }

    .col-span-11.font-black.text-3xl {
        grid-column: unset !important;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .grid-cols-12 {
        grid-template-columns: unset;
    }

    .wrapColumn h4 div {
        display: inline-block;
    }

    .ant-card.ant-card-bordered.m-2.p-2.rounded-3xl.w-full {
        margin: 0;
    }

    .ant-card.ant-card-bordered.m-2.p-2.rounded-3xl.w-full .ant-card-body {
        padding: 5px;
    }

    .about {
        padding: 1rem 0;
    }
    .heightRange > .ant-form-item{
        width: 40%;
    }
    .heightRangeTo{
        margin-right: 1.2rem;
    }
}
@media (max-width:380px){
    .heightRange > .ant-form-item{
        width: 50%;
    }
    .ageRange > .ant-form-item{
        width: 35%;
    }
}

.matrimonyHamWrap {
    display: none;
}