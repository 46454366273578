.HeaderWrapper {
    display: flex;
    position: sticky;
    top: 0;
    width: calc(100% - 130px);
    align-items: center;
    flex-direction: row;
    background-color: #fff;
    padding: 0 15px;
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    background-image: linear-gradient(270deg, #804C31, #67702E);
    height: 60px;
    padding-bottom: 0;
    margin-left: 130px;
    z-index: 50;
}

div {
    -webkit-tap-highlight-color: transparent;
    /* remove tap highlight */
}

div:focus {
    outline: none;
    /* remove outline */
    box-shadow: none;
    /* remove box shadow */
}

.leftNavAndHeaderWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

}

.HeaderUL {
    display: flex;
    align-items: center;
    width: 157px;
    justify-content: flex-end;
    /* gap: 23px; */
}

.vamshavrukshaHeading {
    color: white;
    font-weight: 700;
    font-size: 110%;
    line-height: 32px;
    letter-spacing: 2.5px;
}

.receivedePopup {
    width: 400px;
    min-height: 100px;
    max-height: 400px;
    overflow: auto;
    right: -24px;
    top: 45px
}

.messageBox {
    background-color: #ad7a5f;
    width: 45px;
    align-items: center;
    justify-content: center;
    height: 45px;
    border-radius: 50%;
    display: flex;
    font-size: 1.5rem;
    /* 24px */
    line-height: 2rem;
    /* 32px */
}

.profileIcon {
    width: 2.5rem;
    /* 40px */
    height: 2.5rem;
    /* 40px */
}

@media(max-width: 767px) {
    .linksDiv {
        display: none;
    }

    .leftNavAndHeaderWrapper {
        position: relative;
        height: auto;
        transition: width 5s ease;
    }

    .mobileLogoText {
        color: white;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 1.5px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .pageLayout-L {
        display: none;
    }

    .HeaderWrapper {
        margin-left: 0;
        width: 100%;
    }

    .leftNavShow img {
        width: 30px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
    }

    .leftNavVisible {
        display: block;
        position: fixed;
        background: #FFE6D9;
        left: 0;
        width: 80%;
        height: 100vh;
        top: 0;
        z-index: 1050;
        opacity: 100;
        visibility: visible;
        -webkit-transition: all .6s ease;
    }

    .leftNavHide {
        display: none;
        visibility: hidden;
        width: 0px;
        opacity: 0;
        -webkit-transition: all .6s ease;
    }

    .responsiveNav {
        display: block !important;
    }

    .pageLayout-R {
        width: 100%;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        /* Semi-transparent black background */
        z-index: 0;
        /* Ensure the overlay is above other content */
        display: none;
        /* Initially hidden */
    }

    .overlay.show {
        display: block;
        /* Show the overlay when needed */
    }


}

@media(max-width: 375px) {
    .linksDiv {
        display: none;
    }
}

.leftNavShow img {
    display: none;
}

.responsiveNav {
    display: none;
}

/* CSS for hiding/showing the search bar */
.search-container.expanded .searchdiv {
    display: block;
}

.showMobile {
    display: none;
}

.showDesktop {
    display: block;
}


/* Media query for devices with a width less than 767px */
@media (max-width: 600px) {
    .linksDiv {
        display: none;
    }

    .search-icon {
        display: block;
        cursor: pointer;

    }

    .search-icon svg {
        width: 50px;
        height: 25px;
        color: #fff;
        margin-top: 0;
        border: 1px solid transparent;
        background: transparent;
        border-radius: 50%;
    }

    .search-container:not(.expanded) .search-icon {
        display: block;
    }

    .showDesktop {
        display: block;
    }

    .showMobile {
        display: block !important;
    }

    .searchdiv {
        width: 180px;
    }

    .searchdiv.ant-input-group-wrapper {
        display: none !important;
    }

    .search-container.expanded .searchdiv {
        width: 180px !important;
    }

    .HeaderUL {
        /* max-width: 75%;
        margin-left: 10px; */
        display: none !important;
    }

    .messageBox {
        background-color: #ad7a5f;
        width: 45px;
        font-size: 15px;
        align-items: center;
        justify-content: center;
        height: 40px;
        border-radius: 50%;
        display: flex;
    }

    .feedsContainer {
        display: flex;
        flex-flow: column wrap;
        width: 100% !important;
        font-family: "Poppins", sans-serif;
        margin: 0;
    }

    .receivedePopup {
        width: 379px;
        min-height: 100px;
        max-height: 400px;
        overflow: auto;
        right: -51px;
        top: 45px;
    }

    .showlogomobile {
        display: block !important;
        width: 35px;
    }

    .showlogodesktop {
        display: none !important;
    }

}
@media (min-width : 601px) and (max-width: 766px) {
    .search-results {
        position: absolute;
        background-color: white;
        width: 90% !important;
        margin: 25px 0px 0px 0px !important;
        padding: 10px;
        border-radius: 10px;
        max-height: 250px;
        overflow-y: scroll;
        z-index: 10;
    }

    .HeaderWrapper {
        display: flex;
        position: sticky;
        top: 0;
        justify-content: flex-end;
        width: 100%;
        align-items: center;
        flex-direction: row;
        background-color: #fff;
        padding: 0 15px;
        --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        background-image: linear-gradient(270deg, #804C31, #67702E);
        height: 60px;
        padding-bottom: 0;
        /* margin-left: 130px; */
        z-index: 50;
    }

    .vamshavrukshaHeading {
        display: none;
    }

    .messageBox {
        display: none;
    }

    .bellicon {
        display: none;
    }
}

@media (min-width : 767px) and (max-width: 900px) {
    .search-results {
        position: absolute;
        background-color: white;
        width: 90% !important;
        margin: 25px 0px 0px 0px !important;
        padding: 10px;
        border-radius: 10px;
        max-height: 250px;
        overflow-y: scroll;
        z-index: 10;
    }

    .HeaderWrapper {
        display: flex;
        position: sticky;
        top: 0;
        justify-content: flex-end;
        width: calc(100% - 130px);
        align-items: center;
        flex-direction: row;
        background-color: #fff;
        padding: 0 15px;
        --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        background-image: linear-gradient(270deg, #804C31, #67702E);
        height: 60px;
        padding-bottom: 0;
        margin-left: 130px;
        z-index: 50;
    }

    .vamshavrukshaHeading {
        display: none;
    }

    .messageBox {
        display: none;
    }

    .bellicon {
        display: none;
    }
}

.showlogomobile {
    display: none;

}

.popupOver {
    width: 180px;
    height: auto;
}

.popupOver .popupContent {

    border-bottom: 1px solid #eccfbc;
}

.popupOver .popupContent:last-child {
    border-bottom: 0px;
}

.popOverLabel {
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    line-height: 26px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.search-results {
    position: absolute;
    background-color: white;
    width: 39%;
    margin: 50px 0px 0px 0px;
    padding: 10px;
    border-radius: 10px;
    max-height: 250px;
    overflow-y: scroll;
    z-index: 10;
}

.search-results::-webkit-scrollbar {
    width: 12px;
    /* Width of the scrollbar */
}

/* Customize the scrollbar thumb (handle) for .search-results */
.search-results::-webkit-scrollbar-thumb {
    background-color: #d4d3d3;
    /* Color of the thumb */
    border-radius: 10px;
    /* Rounded corners for the thumb */
}

/* Customize the scrollbar track (background) for .search-results */
.search-results::-webkit-scrollbar-track {
    background-color: #f7f7f7;
    /* Color of the track */
    border-radius: 10px;
    /* Rounded corners for the track */
}

/* You can also add similar styles for Firefox */
.search-results {
    scrollbar-color: #333 #f7f7f7;
    /* Thumb and track colors for Firefox */
}

.searchResultItem {
    font-size: 1.125rem;
    /* 18px */
    line-height: 1.75rem;
    /* 28px */
    padding-top: 0.5rem;
    /* 8px */
    padding-bottom: 0.5rem;
    /* 8px */
}

@media (min-width:1200px) and (max-width:1500px) {
    .HeaderWrapper {
        display: flex;
        position: sticky;
        top: 0;
        width: calc(100% - 7%);
        align-items: center;
        flex-direction: row;
        background-color: #fff;
        padding: 0 15px;
        --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        background-image: linear-gradient(270deg, #804C31, #67702E);
        height: 3rem;
        padding-bottom: 0;
        margin-left: 6.5rem;
        z-index: 50;
    }

    .vamshavrukshaHeading {
        color: white;
        font-weight: 700;
        font-size: 90%;
        line-height: 32px;
        letter-spacing: 2.5px;
    }

    .messageBox {
        background-color: #ad7a5f;
        width: 2rem;
        align-items: center;
        justify-content: center;
        height: 2rem;
        border-radius: 50%;
        display: flex;
        font-size: 1rem;
        /* 24px */
        line-height: 2rem;
        /* 32px */
        margin: 0 12px 0 0!important;
    }

    .bellIcon {
        width: 2rem;
        height: 2rem;
    }
    .HeaderUL{
        padding-right: 10px;
    }

    .profileIcon {
        width: 2rem;
        /* 40px */
        height: 2rem;
        /* 40px */
    }

    .searchResultItem {
        font-size: 0.8rem;
        /* 18px */
        line-height: 1.75rem;
        /* 28px */
        padding-top: 0.1rem;
        /* 8px */
        padding-bottom: 0.1rem;
        /* 8px */
    }

    .popupOver {
        width: 9rem;
        height:auto;
    }

    .popupOver .popupContent {

        border-bottom: 1px solid #eccfbc;
    }

    .popupOver .popupContent:last-child {
        border-bottom: 0px;
    }

    .popOverLabel {
        font-size: 0.85rem;
        font-weight: 500;
        text-align: center;
        line-height: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

@media (min-width:1501px) and (max-width:1900px) {
    .HeaderWrapper {
        display: flex;
        position: sticky;
        top: 0;
        width: calc(100% - 6%);
        align-items: center;
        flex-direction: row;
        background-color: #fff;
        padding: 0 15px;
        --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        background-image: linear-gradient(270deg, #804C31, #67702E);
        height: 3.5rem;
        padding-bottom: 0;
        margin-left: 6.5rem;
        z-index: 50;
    }

    .vamshavrukshaHeading {
        color: white;
        font-weight: 700;
        font-size: 90%;
        line-height: 32px;
        letter-spacing: 2.5px;
    }

    .messageBox {
        background-color: #ad7a5f;
        width: 2.5rem;
        align-items: center;
        justify-content: center;
        height: 2.5rem;
        border-radius: 50%;
        display: flex;
        font-size: 1rem;
        /* 24px */
        line-height: 2rem;
        /* 32px */
    }

    .bellIcon {
        width: 2.5rem;
        height: 2.5rem;
    }

    .profileIcon {
        width: 2.5rem;
        /* 40px */
        height: 2.5rem;
        /* 40px */
    }

    .searchResultItem {
        font-size: 0.8rem;
        /* 18px */
        line-height: 1.75rem;
        /* 28px */
        padding-top: 0.1rem;
        /* 8px */
        padding-bottom: 0.1rem;
        /* 8px */
    }

    .popupOver {
        width: 9rem;
        height: auto;
    }

    .popupOver .popupContent {

        border-bottom: 1px solid #eccfbc;
    }

    .popupOver .popupContent:last-child {
        border-bottom: 0px;
    }

    .popOverLabel {
        font-size: 0.85rem;
        font-weight: 500;
        text-align: center;
        line-height: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}