  .filtercontents::-webkit-scrollbar,
  .filtercontents::-webkit-scrollbar {
    width:12px; /* Width of the scrollbar */
    /* height:50px; */
  }
  
  /* Customize the scrollbar thumb (handle) */
  .filtercontents::-webkit-scrollbar-thumb,
  .filtercontents::-webkit-scrollbar-thumb {
    background-color: #c9a987; /* Color of the thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
  }
  
  /* Customize the scrollbar track (background) */
  .filtercontents::-webkit-scrollbar-track,
  .filtercontents::-webkit-scrollbar-track {
    background-color: #E4D1BC; /* Color of the track */
    border-radius: 10px; /* Rounded corners for the track */
  }

  .MatrimonyCover{
    height: 525px;
  }


  .filtercontents{
    width: 400px;
  }

  .filtercontents .ant-select-selection-item{
    font-size: 14px;
    font-family: "Poppins", sans-serif;
}

  .matrimony-typeDropdown{
    width: 160px;
    border-radius: 50px;
  }
  

@media(min-width:1501px) and (max-width:1900px){
  .MatrimonyCover{
    height:500px
  }
  .filtercontents{
    width: 388px;;
  }
  .profileList {
        display: flex;
        flex-basis: 100%!important;
        flex-direction: column;
        width: 100%;
        padding: 0;
        margin-bottom: 0;
        height: 225px;
        line-height: 1.2rem;
    }
}

@media (min-width:1200px) and (max-width:1500px){
  .MatrimonyCover{
    height: 485px;
  }
  .matrimony-typeDropdown{
    width: 96px;
    border-radius: 50px;
    height: 30px;
  }
}

@media( max-width:900px) and (min-width: 768px){
  .profileList {
        display: flex;
        flex-basis: 100%!important;
        flex-direction: column;
        width: 100%;
        padding: 0;
        margin-bottom: 0;
        height: 160px;
        line-height: 1.2rem;
    }
  .profileList .contentwrapper {
        padding: 0;
        display: flex;
        flex-direction: row;
        margin: 0;
    }

    .profileList .matrimonyThumbnailTitle{
      font-size: 1rem;
    }
    .profileList .thumbnailContent{
      font-size: 14px;
      line-height: 1rem;
      padding-bottom: 2px;
    }
    .filtercontents{
      opacity: 1;
    }
}


@media(max-width:767px){
    .profileList {
        display: flex;
        flex-basis: 100%!important;
        flex-direction: column;
        width: 100%;
        padding: 0;
        margin-bottom: 0;
    }
    .profileList .thumbnailContent{
        max-width: 100%;
        justify-content: center;
        display: flex;
        text-align: center;
        width: 100%;
        flex-direction: column;
        line-height: 1.2rem;
    }
    .profileList .text-base{
        text-align: center;
        padding: 0;
        margin: 0 0 2px;
    }
    .profileList .contentwrapper {
        padding: 0;
        display: flex;
        flex-direction: row;
        margin: 0;
    }
    .profileList .contentwrapper .thumbnailImg {
        height: 160px!important;
        max-width: 40% !important;
    }
    .matrimonycoverpic{
        display:none
    }
    .profileList .matrimonyThumbnailTitle{
      line-height: 0;
    }
    .matrimonyTabswrapper{
        width: 100%;
    }
    .yourprofilesWrap{
        display: flex;
    flex-direction: column;
    }
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
        z-index: 60; /* Ensure the overlay is above other content */
        display: none; /* Initially hidden */
      }
      
      .overlay.show {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
        z-index: 60;
        display: block; /* Show the overlay when needed */
      }

    .filters{
        position: fixed;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10700; /* Ensure the overlay is above other content */
        display: none; /* Initially hidden */
    }
    .filters.show{
        position: fixed;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10700;
        display: block; /* Show the overlay when needed */
    }

  }