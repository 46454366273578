.messages {
  background-color: #efe8e1;
  padding: 10px;
  padding-bottom: 30px;
  height: 81%;
  /* overflow: scroll; */
}

  .messages::-webkit-scrollbar,
  .messages::-webkit-scrollbar {
    width:12px; /* Width of the scrollbar */
    /* height:50px; */
  }
  
  /* Customize the scrollbar thumb (handle) */
  .messages::-webkit-scrollbar-thumb,
  .messages::-webkit-scrollbar-thumb {
    background-color: #ded0c2; /* Color of the thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
  }
  
  /* Customize the scrollbar track (background) */
  .messages::-webkit-scrollbar-track,
  .messages::-webkit-scrollbar-track {
    background-color: #efe8e1; /* Color of the track */
    border-radius: 10px; /* Rounded corners for the track */
  }


.message {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.messageInfo {
  display: flex;
  flex-direction: column;
  color: gray;
  font-size: small;
}

.messageInfo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.messageContent {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.messageContent .text {
  background-color: white;
  border: 1px solid black;
  padding: 2px 10px;
  border-radius: 0px 10px 10px 10px;
  max-width: max-content;
  font-size: 13px;
  font-weight:600;
  word-wrap: break-word;
  max-width: 100%;
}

.messageContent .image {
  background-color: white;
  border: 1px solid black;
  padding: 5px;
  border-radius: 0px 10px 10px 10px;
  max-width: max-content;
}



.message.owner {
  flex-direction: row-reverse;
}

.message.owner .messageContent {
  align-items: flex-end;
}

.message.owner .messageContent .text {
  background-color:#d9f0d5;
  border-radius: 10px 0px 10px 10px;
  word-wrap: break-word;
  border: 1px solid #9fd795;
  max-width: 100%;

}

.message.owner .messageContent .image {
  background-color:#d9f0d5;
  border-radius: 10px 0px 10px 10px;
  border: 1px solid #9fd795
}
@media (min-width:1200px)and (max-width:1500px){
  .messages{
        height:75%;
    }
}

@media(max-width:767px){
    .messages{
        width: 100%;
        margin:0;
        height:86dvh;
    }

    .messageContent {
  max-width: 70%;
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.message {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}
}