.memberPopSelect {
  width: 100%;
  height: 500px;
}

.addMemberWrapper {
  margin: 0px 5px;
}

.SelectContainer {
  /* height: 500px;
  margin: 0px 30px; */
  min-height: 100%;
  max-height: 500px;
  margin: 0px 30px;
}

.memberListContainer {
  /* height: calc(100% - 115px);
  overflow: auto; */
  height: 500px;
  /* overflow: auto; */
}

.selectedMemberList {
  margin: 20px 20px;
  border-radius: 5px;
  padding: 0px 10px;
}

.memberDetails {
  display: flex;
  font-size: 20px;
  justify-content: space-between;
}

.existingUser {
  display: flex;
  gap: 10px;
}

.addMembersSelect .ant-select-selection-item {
  height: 60px !important;
  align-items: center !important;
}

.addButton {
  font-weight: 500;
  background: #686f2e;
  color: white;
  font-size: 19px;
  text-align: center;
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-right: 10px;
  margin-top: 10px;
}

.addButton:disabled {
  background: #686f2e !important;
  opacity: 0.5 !important;
  color: white !important;

}

.addButton:hover {
  background: #686f2e !important;
  color: white !important;
  outline: none !important;
  border: none !important;
}

.custom-check {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #686f2e;
  border-radius: 50%;
  color: white;
}

.select-dropdown {
  min-height: 50px;
  max-height: 200px;
}

.addMembersSelect .ant-select-selector {
  min-height: 50px !important;
  max-height: 250px !important;
  z-index: 9;
}

.custom-profile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 1.5rem;
}

.deleteIcon {
  font-size: 1.5rem;
}

.addMembersPopup {
  top: 100px;
}

.addMembersPopup .ant-modal-body {
  /* min-height: 400px !important;
  max-height: 500px !important; */

}

.addMembersPopup .ant-modal-close {
  color: #7e4f31 !important;
  font-size: larger !important;
}

.addMembersPopup .ant-modal-close .ant-modal-close-x {
  color: #7e4f31 !important;
  font-size: 20px;
}

.addMembersPopup {
  width: 800px !important;
  height: 680px !important;
  padding: 0px !important;
}

.addMembersPopup .ant-modal-title {
  border-bottom: 2px solid #eee7e0 !important;
  font-size: 25px !important;
  padding: 10px;
  width: 97%;
  white-space: pre;
  overflow: hidden;
  height: 60px;
  text-overflow: ellipsis;
}

.adminMembersList {
  height: 500px;
  /* overflow-y: scroll; */
  padding: 0 20px;
}

.adminMembersList::-webkit-scrollbar-thumb {
  background-color: #d4d3d3 !important;
  /* Color of the thumb */
  border-radius: 10px !important;
  /* Rounded corners for the thumb */
}

/* Customize the scrollbar track (background) */
.adminMembersList::-webkit-scrollbar-track {
  background-color: #f7f7f700 !important;
  /* Color of the track */
  border-radius: 10px !important;
  /* Rounded corners for the track */
}

.adminMembersList {
  scrollbar-color: #F7F7F7;
  /* Thumb and track colors for Firefox */
}

.dropdownEmail {
  font-size: 18px !important;
  font-weight: 400px !important;
}

.adminMembersListContainer {
  width: 85%
}

.activeDropdown {
  border: 2px solid #06be51 !important;
  border-radius: 20px !important;
}

.blockedDropdown {
  border: 2px solid #ff0000 !important;
  border-radius: 20px !important;
}

.rc-virtual-list {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
  border-radius: 5px;
  background-color: rgb(233, 233, 233, 0.2);
  border: 1px solid rgba(96, 96, 96, 0.5) !important;

}

.saveButtonContainer{
  width: 85%;
}

.msg-blocked-input {
  display: flex;
  padding: 0px;
  align-items: center;
}

.msg-blocked-input input {
  flex: 1;
  font-size: 16px;
  height: 50px;
  margin: 0 0  10px 15px;
  border-radius: 40rem;
}

@media (max-width: 767px) {
  .mobileOverflow{
    max-width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .addMembersPopup .ant-modal-title {
    font-size: 19px !important;
  }

  .addMembersSelect .ant-select-selector {
    max-height: 150px !important;
    overflow-y: scroll;
  }

  .addMembersSelect .ant-select-selection-item {
    display: flex !important;
    height: 45px !important;
    flex-wrap: wrap !important;
    align-content: center !important;
    justify-content: center !important;
  }

  .SelectContainer .ant-select-selector .dropdownEmail {
    width: 40px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .addMembersSelect .ant-select-selector .dropdownProfileImage {
    width: 30px;
    height: 30px;
  }

  .dropdownProfileImage {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50%;
    border-width: 1px;
    border-color: white;
  }

  .custom-profile {
    width: 30px;
    height: 30px;
    font-size: 12px !important;
  }

  .deleteIcon {
    font-size: 20px;
  }

  .addMembersPopup {
    height: 647px !important;
  }

  .familymemberOption {
    margin-top: 7px;
  }

  .addButton {
    width: auto;
    height: auto;

  }

  .dropdownEmail {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 183px;
    /* width: 100px;
    overflow: hidden;
    text-overflow: ellipsis; */
  }

  .adminMembersListContainer {
    width: 100%;
    padding-right: 10px;
  }
  .saveButtonContainer{
    width: 100%;
  }
  .msg-blocked-input {
    display: flex;
    padding: 22px 10px 0px 10px;
    align-items: center;
  }
  .memberListContainer {
    height: 300px;
    overflow-y: scroll;
}
}

@media (min-width:1200px) and (max-width:1500px) {

  /* .postPopup .ant-modal-body {
    min-height: 400px !important;
    max-height: 500px !important;
  } */
  .addMembersPopup .ant-modal-title {
    border-bottom: 2px solid #eee7e0 !important;
    font-size: 1.2rem !important;
    padding: 0.75rem;
    width: 97%;
    white-space: pre;
    overflow: hidden;
    height: 50px;
    text-overflow: ellipsis;
  }

  .addMembersPopup {
    top: 20px;
  }

  .addMembersPopup .ant-modal-body {
    min-height: 400px !important;
    max-height: 500px !important;

  }

  .addMembersPopup {
    width: 800px !important;
    height: 300px !important;
    padding: 0px !important;
  }

  .SelectContainer {
    margin: 0px 30px;
    min-height: 100%;
    max-height: 500px;
    /* height: 380px;
    margin: 0px 30px; */
  }

  .adminMembersList {
    max-height: 400px;
    overflow-y: scroll;
  }

  .memberListContainer {
    max-height: 400px;
    /* overflow-y: scroll; */
  }

  .msg-blocked-input input {
    flex: 1;
    font-size: 15px;
    height: 40px;
    margin: 0 8px;
    border-radius: 40rem;
  }

}

@media (width:1400px) {
  .msg-blocked-input input {
    flex: 1;
    font-size: 15px;
    height: 40px;
    margin: 0 8px;
    border-radius: 40rem;
  }
}

@media (min-width:1501px) and (max-width:1900px) {
  .msg-blocked-input input {
    flex: 1;
    font-size: 15px;
    height: 40px;
    margin: 0 8px;
    border-radius: 40rem;
  }
}