.notificationDescription {
    width: 100%;
    font-size: 20px;
    margin: 10px;
    /* width: 60%; */
    /* min-height: 50px; */
    /* padding: 20px 10px; */
    /* padding: 20px 10px; */
}
.rejectedText {
    color: red;
}
.notificationRow {
    display: flex;
    flex-direction: column;
    /* width: calc(33.33% - 2%); */
    border: 5px solid #f9f9f9;
    border-radius: 8px;
    margin: 1%;
    border: 1px solid #eaeaea;
    box-shadow: rgba(149, 157, 165, 0.2) 0px -1px 10px 0px;
    min-height: 100px;
    padding: 20px 10px;
    /* overflow: hidden; */
}
.successText {
    color: green;
}
.ant-tabs-tab:hover {
    color: #6d3a21 !important;
}
@media(max-width:896px ){
    .notificationDescription {
        width: 100%;
        font-size: 17px;
        margin: 10px;
        /* width: 60%; */
        /* min-height: 50px; */
        /* padding: 20px 10px; */
        /* padding: 20px 10px; */
    }
    .notificationRow {
        padding: 12px 10px;
        /* overflow: hidden; */
    }
}
@media (min-width:1200px) and (max-width:1500px){
    .notificationDescription {
        width: 100%;
        font-size: 1rem;
        margin: 10px;
    }
}
@media (min-width:1501px) and (max-width:1900px){
    .notificationDescription {
        width: 100%;
        font-size: 1rem;
        margin: 10px;
    }
}