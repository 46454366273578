@media(max-width: 700px){
    .discoverthumbnailRowForThree{
        flex-flow: column wrap;
    }
    .discoverthumbnailRowForThreeColumn {
        width: 100%;
        background: #e5e1cf;
        padding: 1% 2%;
    }
    .disoverListouter {
        flex-direction: row!important;
        display: flex!important;
        justify-content: space-between!important;
    }
    .discoverthumbnailRowForThreeColumn .py-10{
        padding: 0!important;
    }
    .discoverUIwrap {
        display: flex;
        flex-flow: column wrap;
        max-width: 70%;
        min-width: 70%;
    }
    .discoverthumbnailTitle {
        white-space: pre-wrap;
        text-align: left;
        margin: 5px;
        font-size: 18px;
    }
    .discoverthumbnailDescription p {
        margin: 0;
        padding: 0 10px;
    }
    .discoverthumbnailDescription {
        font-size: 18px;
        /* margin: 5px 0 0; */
        width: 100%;
        min-height: auto!important;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .discoverUIwrap .joingroup {
        width: auto;
        padding-top: 0;
        margin-top: 0;
        align-items: baseline;
    }
    .discoverUIwrap .joingroup .showmobile{
        display: flex!important;
        width:fit-content;
         color:#804C31;
          border-width:2px;
           border-color:#804C31;
          align-items:center
    }
    .discoverUIwrap .joingroup .showdesktop{
        display: none!important;
    }
    .disoverListouter img {
        border-radius: 150px;
        width: 90px!important;
        height: 90px!important;
        max-width: 30%;
        margin-left: auto;
        margin: 10px 5px;
        margin-right: auto;
    }
    .discoverUIwrap .joingroup button span{
        display: inline-grid;
    
    } 
    .searchbardiscover {
        margin-left: 0;
        margin-top: 10px;
    }
    
}

.discoverUIwrap .joingroup .showmobile{
    display: none;
}
.discoverUIwrap .joingroup .showdesktop{
    display: block;
}
@media (min-width:1200px) and (max-width:1500px){
    .ant-select-dropdown .ant-select-item{
        font-size: 0.75rem;
    }
    .ant-select-selection-item{
        font-size: 0.75rem;
    }
}