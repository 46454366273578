/* Default Alert Styles */
.email-verification-alert {
    padding: 18px 24px;
    background-color: #fffbe6;
    border: 1px solid #fffbe6;
    border-radius: 4px;
    transition: top 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
  }
  
  /* Fixed Positioning when the user scrolls */
  .email-verification-alert.fixed {
    position: fixed;
    top: 70px;
    /* left: 0; */
    /* width: 100%; */
    /* left:0; */
    /* right: 0; */
    z-index: 999;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(0);
    opacity: 1;
  }
  .email-verification-alert.tabsfixed {
    position: fixed;
    top: 115px;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(0);
    opacity: 1;
  }
  
  /* Custom alert container */
  .custom-alert {
    display: flex;
    padding: 14px 10px;
    border-radius: 15px;
    border: 0.5px solid #faad14;
    background-color: #f7dd94;
    align-items: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .custom-alert:hover {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  }
  
  /* Icon area */
  .custom-alert-icon {
    font-size: 20px;
    color: #faad14;
    margin-right: 10px;
  }
  
  /* Content area */
  .custom-alert-content {
    flex-grow: 1;
  }
  
  /* Title area */
  .custom-alert-message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: #c18e3e;
  }
  
  /* Description area */
  .custom-alert-description {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.3s ease, opacity 0.3s ease, padding 0.3s ease;
  }
  
  /* When expanded */
  .custom-alert-description.expanded {
    max-height: 300px;
    opacity: 1;
    margin-top: 12px;
    padding: 10px;
    font-size: 14px;
    color: #595959;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 6px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  /* Description text styles */
  .custom-alert-description p {
    margin-bottom: 10px;
    line-height: 1.5;
  }
  
  /* Description list styles */
  .custom-alert-description ul {
    margin-left: 20px;
    padding-left: 0;
  }
  
  .custom-alert-description li {
    margin-bottom: 5px;
    position: relative;
    padding-left: 15px;
  }
  
  .custom-alert-description li::before {
    content: "•";
    position: absolute;
    left: 0;
    color: #faad14;
  }
  
  /* Expand button style (uses icons) */
  .expand-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    font-size: 18px;
    color: #faad14;
    transition: transform 0.2s ease;
  }
  
  .expand-button:focus {
    outline: none;
  }
  
  .expand-button svg {
    transition: transform 0.2s ease;
  }
  
  .custom-alert-description.expanded ~ .expand-button svg {
    transform: rotate(180deg);
  }
  
  /* Warning style */
  .custom-alert-warning {
    border-color: #faad14;
    background-color: #fff8e5;
  }