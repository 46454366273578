.rejectModalPopup {
  width: 500px !important;
  height: 680px !important;
  padding: 0px !important;
}
.rejectInputMessage {
font-size: 20px;
}
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.349) !important;
}
.rejectModalPopup .ant-modal-close {
    color: #7e4f31 !important;
    font-size: larger !important;
  }
   .ant-modal .ant-modal-content {
    box-shadow: inset !important;
  }
  
  .rejectModalPopup .ant-modal-close .ant-modal-close-x {
    color: #7e4f31 !important;
    font-size: 25px;
  }
  
  .selectDropdown .ant-select-selector {
      height: 50px !important;
  }
 
  .rejectModalPopup .ant-modal-title {
    border-bottom: 2px solid #eee7e0 !important;
    font-size: 25px !important;
    padding: 10px;
    width: 97%;
    white-space: pre;
    overflow: hidden;
    height: 60px;
    text-overflow: ellipsis;
    
  }

  .rejectModalPopup .ant-modal-body{
    padding: 20px;
  }

  .rejectModalPopup .ant-modal-backdrop.show {
    opacity: 1;
  }

  .rejectButton {
    font-weight: 500!important;
    background: #686f2e!important;
    color: white!important;
    font-size: 19px;
    text-align: center!important;
    width: 110px;
    height: 50px;
    margin: 15px 4px;
  }
  .rejectButton :hover {
    background: #686f2e!important;
    color: white !important;
    border: none!important;
  }