.LoginWrapper {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(270deg, #804C31, #67702E);
  padding-bottom: 0;
  /* display: flex; */
}

.loginImageCol {
  margin: 0;
  padding: 0;
  width: 50%;
  text-align: center;
  height: 100%;
  width: 50%;
}

.Login-L-Img {
  width: auto;
  height: auto;
  object-fit: cover;
}

.loginColWrapper {
  display: flex;
  flex-direction: column;
  gap: 60px;
  justify-content: center;
  align-items: center;
}

.logoText {
  font-size: 35px;
  font-weight: 600;
  line-height: 10px;
  text-align: center;
}

.forgotPasswordText {
  color: #7e5031;
  font-size: 17px;
  font-weight: 700;
  text-align: center;
}

.logoGoogleText {
  font-size: 30px;
  font-weight: 600;
  line-height: 10px;
  text-align: center;
}

.loginComponentOptions {
  display: flex;
  align-items: center;
  justify-self: center;
  gap: 50px;
}

.loginComponentForm {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.loginComponentForm .inputfield{
  border: 1px solid #dedede !important;
  border-radius: 10px !important;
  padding: 10px !important;
  margin: 10px 0 !important;
}

.logoAndTittleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-bottom: 10px;
}

.loginComponentButton {
  font-weight: 500;
  background: #869031;
  color: white;
  margin: 10px 0;
  font-size: 19px;
  text-align: center;
  width: 400px;
  border-radius: 10px;
  height: 50px;
}

.LoginMainContentCol {
  background-color: #f7f7f7;
  margin: auto;
  width: calc(100% - 100px);
  padding: 0px;
  min-height: calc(100vh - 100px);
  border-radius: 30px 20px 20px 30px;
}

.loginComponentCol-R {
  background-color: #fff;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  min-height: calc(100vh - 100px);

}

.logincontainerWithOutSideBar {
  /* margin:auto; */
  display: flex;
  height: 100%;
}

.googleButton>button {
  background-color: rgba(255, 255, 255, 0) !important;
}

.google-login-container>.haAclf>.nsm7Bb-HzV7m-LgbsSe>.Bz112c-LgbsSe>.hJDwNd-SxQuSe>.i5vt6e-Ia7Qfc>.JGcpL-RbRzK {
  width: 70px !important;
  height: 70px !important;
}

.nsm7Bb-HzV7m-LgbsSe.Bz112c-LgbsSe {
  padding: 0;
  width: 68px !important;
  height: 68px !important;
}

.LgbsSe-Bz112c {
  width: 50px;
  height: 25px;
}

.forgotPasswordWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 11px;
}
.passwordWrapper input {
background: white;
outline: none !important;
}
.inputWrapper input:focus {
  /* background-color: white !important; */
  background: white !important;
  outline: none !important;
}
.textColor{
  color: #7f4c31;
  font-weight: 600;
}


.checkboxmulti {
  justify-content: end;
  font-size: 16px !important;
  /* padding: 20px 5px; */
  padding-bottom: 20px;
  display: flex;
}

.checkboxmulti .ant-checkbox-inner{
  border:rgb(77, 77, 77) solid 1.7px!important;
}


@media (min-width : 601px) and (max-width: 900px) {
  .LoginWrapper {
    height: 100%;
    background: url(../../assets/newUiImages/logoutBackground), no-repeat;
    padding-bottom: 0;
    background-size: cover;
  }

  .LoginMainContentCol {
    background-color: inherit;
    margin: auto;
    width: 100%;
    padding: 0px;
    min-height: 100vh;
    border-radius: unset;
  }

  .loginImageCol {

    display: none;

  }

  .logoAndTittleWrapper {
    display: none;
  }

  .loginCol {
    width: 60%;
    text-align: center;
    padding: 30px;
    margin: 25% 1rem 1rem 1rem;
    transform: translateY(80%);
    border-radius: 18px;
    height: 100%;
    background: #fff;
  }

  .inputWrapper {
    min-width: 300px !important;
    border: 1px solid gray;
    border-radius: 10px !important;
    padding: 15px 10px !important;
    width: auto;
    margin-bottom: 20px;
    /* font-size: 22px; */
  }

  .loginComponentButton {
    font-weight: 500;
    background: #869031;
    color: white;
    font-size: 19px;
    text-align: center;
    width: 300px;
    border-radius: 10px;
    height: 50px;
  }

  .react-tel-input .form-control {
    width: 300px !important;
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loginColWrapper {
    display: flex;
    flex-direction: column;
    min-height: 300px;
    gap: 60px;
    justify-content: center;
    align-items: center;
  }

  .phoneinputwrapper {
    /* margin-left: 27px; */
    transform: scale(0.93);
  }

  .forgotPasswordText {
    color: #7e5031;
    font-size: 17px;
    font-weight: 700;
    text-align: center;
  }
  .react-tel-input .country-list {
    top: -235px !important;
    background: #dddddddb !important;
  }
}

@media (min-width : 901px) and (max-width: 1100px) {
  .LoginWrapper {
    height: 100%;
    background: url(../../assets/newUiImages/logoutBackground), no-repeat;
    padding-bottom: 0;
    background-size: cover;
  }

  .LoginMainContentCol {
    background-color: inherit;
    margin: auto;
    width: 100%;
    padding: 0px;
    min-height: 100vh;
    border-radius: unset;
  }

  .loginImageCol {

    display: none;

  }

  .logoAndTittleWrapper {
    display: none;
  }

  .loginCol {
    width: 50%;
    text-align: center;
    padding: 30px;
    margin: 35% 1rem 1rem 1rem;
    transform: translateY(80%);
    border-radius: 18px;
    height: 100%;
    background: #fff;
  }

  .inputWrapper {
    min-width: 300px !important;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 15px 10px !important;
    width: auto !important;
    margin-bottom: 20px;
    /* font-size: 22px; */
  }
  

  .loginComponentButton {
    font-weight: 500;
    background: #869031;
    color: white;
    font-size: 19px;
    text-align: center;
    width: 300px;
    border-radius: 10px;
    height: 50px;
  }

  .react-tel-input .form-control {
    width: 300px !important;
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loginColWrapper {
    display: flex;
    flex-direction: column;
    min-height: 300px;
    gap: 60px;
    justify-content: center;
    align-items: center;
  }

  .phoneinputwrapper {
    /* margin-left: 27px; */
    transform: scale(0.93);
  }

  .forgotPasswordText {
    color: #7e5031;
    font-size: 17px;
    font-weight: 700;
    text-align: center;
  }
  .react-tel-input .country-list {
    top: -235px !important;
    background: #dddddddb !important;
  }
}

@media(max-width :767px) {
  .LoginWrapper {
    height: 100%;
    background: url(../../assets/newUiImages/logoutBackground), no-repeat;
    padding-bottom: 0;
    background-size: cover;
  }
  .checkboxmulti {
    font-size: 14px !important;
  }

  .LoginMainContentCol {
    background-color: inherit;
    margin: auto;
    width: 100%;
    padding: 0px;
    min-height: 100vh;
    border-radius: unset;
  }

  .loginImageCol {

    display: none;

  }

  .logoAndTittleWrapper {
    display: none;
  }

  .loginCol {
    width: 90%;
    text-align: center;
    padding: 30px;
    margin: 1rem;
    transform: translateY(80%);
    border-radius: 18px;
    height: 100%;
    background: #fff;
  }

  .inputWrapper {
    min-width: 300px !important;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 15px 10px !important;
    width: auto;
    margin-bottom: 20px;
    
  }
.login .email {
  font-size: 1rem;
  line-height: 1rem;
}
  .login .ant-input-affix-wrapper >input.ant-input {
    font-size: 1rem;
    line-height: 1rem;
  }

  .loginComponentButton {
    font-weight: 500;
    background: #869031;
    color: white;
    font-size: 19px;
    text-align: center;
    width: 300px;
    border-radius: 10px;
    height: 50px;
  }

  .react-tel-input .form-control {
    width: 300px !important;
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loginColWrapper {
    display: flex;
    flex-direction: column;
    min-height: 300px;
    gap: 60px;
    justify-content: center;
    align-items: center;
  }

  .phoneinputwrapper {
    /* margin-left: 27px; */
    transform: scale(0.93);
  }

  .forgotPasswordText {
    color: #7e5031;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
  }
  .react-tel-input .country-list {
    top: -235px !important;
    background: #dddddddb !important;
  }

}

@media (max-width: 379px) {
  .phoneinputwrapper {
    margin-left: 25px;
    transform: scale(0.85);
  }

  .loginColWrapper {
    display: flex;
    flex-direction: column;
    min-height: 300px;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }

  .loginOptions {
    max-width: 80%;
  }

  .otp {
    transform: scale(0.73);
  }

  .loginComponentButton {

    width: 225px;

  }

  .react-tel-input .form-control {
    width: 250px !important;
    margin-left: 50px !important;
  }

  .react-tel-input .selected-flag {
    outline: none;
    position: relative;
    width: 52px;
    height: 100%;
    padding: 0 0 0 5px;
    border-radius: 3px 0 0 3px;
  }

  .loginCol {
    width: 90%;
    text-align: center;
    padding: 25px;
    margin: 1rem;
    transform: translateY(70%);
    border-radius: 18px;
    height: 100%;
    background: #fff;
  }

  .inputWrapper {
    min-width: 250px;

    /* font-size: 22px; */
  }


  .forgotPasswordText {
    color: #7e5031;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
  }

}

@media (min-width: 380px) and (max-width: 450px) {
  .loginColWrapper {
    display: flex;
    flex-direction: column;
    min-height: 300px;
    gap: 55px;
    justify-content: center;
    align-items: center;
  }

  .loginOptions {
    max-width: 90%;
  }

  .otp {
    transform: scale(0.83) !important;
  }

  .phoneinputwrapper {
    /* margin-left: 20px; */
    transform: scale(0.85);
  }

  .react-tel-input .country-list {
    top: -235px !important;
    background: #dddddddb !important;
  }
}
 @media (min-width:1200px) and (max-width:1500px) {
.password {
  font-size: 1.125rem;
    line-height: 1.75rem;
}
.ant-input-affix-wrapper >input.ant-input {
  font-size: 1.125rem;
    line-height: 1.75rem;
}
 }

 @media (min-width:1501px) and (max-width:1900px) {
  .password {
    font-size: 1.125rem;
      line-height: 1.75rem;
  }
  .ant-input-affix-wrapper >input.ant-input {
    font-size: 1.125rem;
      line-height: 1.75rem;
  }
 }
