.EmailRedirectWrapper {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(270deg, #804C31, #67702E);
    padding-bottom: 0;
}

.emailContentWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.resetInputWrapper {
    min-width: 300px;
    border: 1px solid #dedede !important;
    border-radius: 10px !important;
    padding: 10px !important;
    margin: 10px 0 !important;
    font-weight: 500;
}

.resetPassword .ant-input-affix-wrapper >input.ant-input {
    font-size: 1.25rem;
    line-height: 1.25rem;
}

.resetSubmitBtn {
    font-weight: 500;
    background: #869031;
    color: white;
    margin: 10px 0;
    font-size: 19px;
    text-align: center;
    width: 400px;
    border-radius: 10px;
    height: 50px;
} 

.emailLogoAndTittleWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-bottom: 10px;
}
.emailText {
    font-size: 30px;
    font-weight: 500;
    /* line-height: 10px; */
    text-align: center;
}
.pointer {
    cursor: pointer;
    color: #804C31;
    font-weight: 900;
}
.emailLoginText {

}

@media (max-width: 379px) {
    .resetInputWrapper{
        width: 68dvw;
    }

    .resetSubmitBtn{
        width: 68dvw;
    }
}

@media (min-width: 380px) and (max-width: 450px) {
    .resetInputWrapper{
        width: 76dvw;
    }

    .resetSubmitBtn{
        width: 76dvw;
    }
}

@media(max-width:767px){
    .emailText{
        font-size: 25px;
    font-weight: 500;
    /* line-height: 10px; */
    text-align: center;
    }
}