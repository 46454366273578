/* .personaldetailsForm {

} */

.Multistepdesign {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
    width: 70%;

    border: 2px solid rgb(215, 215, 215);
    border-radius: 10px;
    background-color: white;
}

.btnContainer {
    display: flex;
    justify-content: start;
}

.Multistepdesign .ant-radio-button-wrapper-checked {
    z-index: 0 !important;
}

/* Labels */
/* .Multistepdesign .ant-form-item-label {
    font-weight: bold;
} */

/* .Multistepdesign {
    padding-left: 50px;
} */

.Multistepdesign .personaldetailsForm .ant-form-item-explain-error {
    font-size: 13px;
}

.Multistepdesign .ant-form-item-label label {
    font-size: 15px;
    font-family: "Poppins", sans-serif;
}

.Multistepdesign .ant-select-selection-item{
    font-size: 14px;
    font-family: "Poppins", sans-serif;
}

.Multistepdesign .sv-remaining-character-counter{
    float: right;
}

.Multistepdesign input,
.Multistepdesign .ant-select,
.Multistepdesign .ant-picker {
    /* width: 350px; */
    width: 100%;
    height: 40px;
    font-family: "Poppins", sans-serif;
}

.Multistepdesign .ant-form-item-control {
    width: 100%;
}

.Multistepdesign .ant-picker {
    border: 0px;
    /* border-bottom: 1px solid #6D3A21; */
    /* border-radius: 0px; */
    padding: 1%;
    margin-top: 3%;
    transition: all .2s ease-out
}

.Multistepdesign .ant-picker-focused {
    /* border: 0px; */
    /* border: 1px solid #706f6f8b; */
    border-radius: 5px;
}

.Multistepdesign .sideby {
    display: flex;
    gap: 30px;
}

.marital-status {
    /* width: 350px; */
    width: 100%;
}


.Multistepdesign .ant-radio-button-wrapper {
    border-radius: 5px;
    margin-right: 5px;
    border: 1px solid rgb(224, 224, 224);
}
.image-preview{
    min-height: 200px;
    max-height: 350px;
}

:where(.css-dev-only-do-not-override-18iikkb).ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
}

.existingDelete {
    position: absolute;
    top: 5px;
    background: white;
    margin-top: 0px !important;
    right: 5px;
    border-radius: 50px;
    width: 50px;
    height: 50px;
}

.Multistepdesign h2 {
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(215, 215, 215);
    font-family: "Poppins", sans-serif;
}

.Multistepdesign .ant-select-selector {
    width: 100%;
    height: 40px !important;
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif;
    border: 0px !important;
    border-bottom: 1px solid #6D3A21 !important;
    border-radius: 0px;
}

.Multistepdesign .ant-select-selector:focus {
    border: 1px solid #706f6f8b;
    border-radius: 5px;
}

.Multistepdesign input,
.Multistepdesign .ant-select {
    /* max-width: 350px;
    width: 350px; */
    width: 100%;
    height: 40px;
    font-family: "Poppins", sans-serif;
    border: none !important;
    border-bottom: 1px solid #6D3A21 !important;
    border-radius: 0px !important;
    /* transition: ease-in-out; */
    /* -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -ms-transition: all .2s ease-out;
    -o-transition: all .5s ease-out; */
    transition: all .2s ease-out
        /* border-color: #6D3A21; */
}

.Multistepdesign .ant-select-selector .ant-select-selection-search-input {
    height: 40px !important;
}

.select-field{

}
/* :where(.css-dev-only-do-not-override-18iikkb).ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {

} */

.Multistepdesign input:focus {
    border: 1px solid #706f6f8b;
    /* box-shadow: 0px; */
    border-radius: 5px;
}

.checkboxmulti {
    justify-content: end;
    font-size: 16px !important;
    /* padding: 20px 5px; */
    padding-bottom: 20px;
    display: flex;
}

.personaldetailsForm .ant-form-item-row.ant-row {
    display: flex !important;
    flex-direction: column !important;
    text-align: left;
    justify-content: flex-start;
    align-items: self-start;
    min-height: 90px;
}



.Multistepdesign .MatrimonyBrowseButton{
    border: 2px solid #686f2e;
    background: none !important;
    color: black;
    font-weight: 500;
    font-size: 19px;
    text-align: center;
    width: 120px;
    height: 50px;
    /* padding: 0.2rem; */
}

.Multistepdesign .images-item {
    /* border: 1px dotted rgb(135, 135, 135);
    height: 400px; */
    /* padding: 20px; */
}

.Multistepdesign .selectfile-delete-icon {
    margin-top: 0px;
}

.sd-title {
    font-size: 15px;
}

.sd-input {
    width: 800px;
    min-height: 300px;
    border: 1px solid rgb(215, 213, 213);
    border-radius: 5px;
}

.sd-btn--action {
    width: 100px !important;
    color: white;
    background-color: #1677FF;
    border-radius: 5px;

}

.sd-question__required-text {
    color: red;
}

.button-container {
    display: flex;
    justify-content: end;
}

.Next-btn {
    background-color: #6D3A21;
    margin-top: 10px !important;
    margin-right: 0px;
    width: 90px;
    height: 40px;
}

.draft {
    width: 110px;
}
.disabled{
    opacity: 0.2;
}


.Next-btn:last-child {
    margin-left: 10px;
}

.Next-btn:hover {
    background-color: #4c2917 !important;
}

.ant-tag-checkable {
    background-color: white;
    border: 2px solid rgb(181, 179, 179);
    padding: 3px;
    margin: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
}

.ant-tag-checkable-checked {
    color: black;
    border: 2px solid #3c2012;
    background-color: #bfa89d;
    font-weight: bold;
}

.ant-tag-checkable-checked:hover {
    background-color: rgb(157, 156, 156);
}

.ant-select{
    box-shadow: none;
    border:none;
}

.tag-content {
    display: flex;
}

.tag-content img {
    height: 17px;
    padding-right: 10px;
}

.multiselect{
    display: flex;
    flex-wrap: wrap;
}

.aboutMeButton { 
    color: white; 
    background-color: #6D3A21;
    margin-top: 10px !important;
    margin-right: 0px;
    width: 90px;
    height: 40px;
}

.aboutMeButton:hover {
    background-color: #6D3A21 !important;
    color: white !important;
    border: none !important;
}


@media (min-Width:1200px) and (max-width:1500px) {
    .button-container {margin-top: 30px;}
}

@media (max-width:767px){
    .multistepwrapper {
        width: 100%;
        background: #fff;
        position: absolute;
        top: 5px;
    }
    .Multistepdesign {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        width: auto;
        margin: 0 15px;
        border: 0;
        padding: 15px 0;
        border-radius: 10px;
        background-color: white;
    }
    .personaldetailsForm .ant-form-item-row.ant-row {
        display: flex !important;
        flex-direction: column !important;
        text-align: left;
        justify-content: flex-start;
        align-items: self-start;
        min-height: auto;
    }
    .Multistepdesign   .ant-form-item .ant-form-item-control-input{
        min-height: auto!important;
    }
    .Multistepdesign .sideby {
        display: flex;
        gap: 1rem;
        flex-flow: column wrap;
    }
    .Multistepdesign .ant-form-item-control-input input{
        width: 100%;
    }
    .Multistepdesign .ant-picker {
       width: 100%;
    }
    .Multistepdesign input, .Multistepdesign .ant-select{
        width: 100%;
    }
    .Multistepdesign .ant-select-selector{
        width: auto!important;
    }

    .backIcon{
        margin-top: -40px;
        position: absolute;
        z-index: 10;
    }
}