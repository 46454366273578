.chatSidebar{
    margin: 20px;
    margin-right: 0;
    height: 37rem;
    width: 30%;
    background-color: #efe8e1;
    border: 1px solid #edc2a6;
    border-radius: 0.5rem;
    overflow: hidden;
}

.chatsList{
    padding: 1rem;
    height: 95%;
    overflow-y: auto;

}

.chatsList::-webkit-scrollbar,
  .chatsList::-webkit-scrollbar {
    width:12px; /* Width of the scrollbar */
    /* height:50px; */
  }
  
  /* Customize the scrollbar thumb (handle) */
  .chatsList::-webkit-scrollbar-thumb,
  .chatsList::-webkit-scrollbar-thumb {
    background-color: #ded0c2; /* Color of the thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
  }
  
  /* Customize the scrollbar track (background) */
  .chatsList::-webkit-scrollbar-track,
  .chatsList::-webkit-scrollbar-track {
    background-color: #efe8e1; /* Color of the track */
    border-radius: 10px; /* Rounded corners for the track */
  }


.chat-select-user .ant-select-selection-item{
    color: white;
    border-radius: 0.5rem;
}

.chat-select-user{
    height: 100%;
}

.matrimonySelect{
    height: 3.25rem;


}
.matrimonyProfileChat{
    border-bottom: 1px solid #804C31;
    height: 10%;
}

.matrimonySelect  .ant-select-selector{
    border: none !important;
}

.OptionProfileImage{
    height: 2.5rem;
    width:2.5rem;
    border-radius: 50%;
    object-fit: cover;
}

.userChat {
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: #804C31;
        cursor: pointer;
    }

.userChat .chatUserName {
    font-size: 18px;
            font-weight: 500;
}

.userChat .lastText {
    font-size: 14px;
    color: rgb(43, 43, 43);
}

.userChat .chatImg {
    width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: cover;
}

@media (min-width:1200px) and (max-width:1500px){
    .chatSidebar{
        height: 28.5rem;
    }
}

@media(max-width:767px){
    .chatSidebar{
        width: 100%;
        margin:0;
        margin-top: -5px;
        height:100vh;
        border-radius: 0;
    }

    

    .chat-select-user .ant-select-selection-item{
    border-radius: 0rem;
}
}