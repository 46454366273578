/* KycComponent.css */

.heading {
  font-size: 30px;
  font-weight: bold;
  color: #7D5031;
}

.verifiedheading {
  font-size: 30px;
  font-weight: bold;
  color: #7D5031;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5% 0;
}

.kycFormContainer {
  width: 55%;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

.form {
  padding: 20px;
  border-radius: 30px;
  width: 60%;
  max-height: 80%;
  text-align: center;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f5f5f5;
  justify-content: space-around;
  box-shadow: 0px 3px 6px #c7a58f0d;
}

.inputGroup {
  margin-bottom: 15px;
  text-align: left;
  font-size: 20px;
}

.input {
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-top: 10px;
}

.checkboxGroup {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.verifyButton {
  padding: 10px 20px;
  background-color: #68702F;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.nextButton {
  padding: 10px 20px;
  background-color: #68702F;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.nextButton:hover {
  color: white
}

.inputStyle {
  width: 3rem !important;
  height: 3rem;
  margin: 2rem 1rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.checkcircle {
  font-size: 200px;
  color: #676F2E;
}

.kycInputBox {
  width: 40%;
  height: 30px;
  border-radius: 6px;
  box-shadow: none;
  border: 1.5px solid #e9edee;
  padding: 15px;
  margin: 10px 0px;
  box-sizing: inherit;
}

.kycLabel {
  font-size: 19px !important;
}

.kycFormItem .ant-select-selector .ant-select-selection-item {
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
}


.kycFormItem .formitem div:first-child {
  display: block;
}

.kycFormItem .formitem label {
  display: block;
  float: left;
  font-size: 20px;
}

.kycFormItem .ant-input {
  font-size: 17px !important;
}

.kycFormItem .ant-picker-input {
  display: flex !important;
  align-items: center !important;
  height: 1.95rem;

}

.kycFormItem .ant-picker .ant-picker-input>input {
  font-size: 17px !important;
}

.kycFormItem .ant-form-item .ant-form-item-explain-error {
  text-align: left;
  margin-left: 5px;
}

.kycFormItem .ant-form-item-control-input-content {
  display: flex;
}

.kycFormItem .Save-btn .ant-form-item-control-input-content {
  display: flex;
  justify-content: space-evenly;
}


.kyc-verify-img {
  width: 45px;
  cursor: pointer;
  margin-left: 10px;
}

.kyc-icon {
  font-size: 200px;
  color: #68702F;
}

.kyc-verify-thumbnail {
  position: absolute;
  background-color: #fbfbfb;
  top: 0px;
  left: 0px;
  width: 35px;
  height: 35px;
  border-radius: 6px 0 10px;
}


@media (max-width: 767px) {
  .kyc-icon {
    font-size: 140px;
  }

  .kycFormContainer {
    width: 90%;
  }

  .form {
    /* border: 1px solid #ccc; */
    padding: 20px;
    border-radius: 30px;
    width: 90%;
    text-align: center;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    justify-content: space-around;
    box-shadow: 0px 3px 6px #c7a58f0d;
    margin: 0px auto;
  }

  .inputStyle {
    width: 2.5rem !important;
    height: 3rem;
    margin: 0.5rem 0.5rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }

  .verifiedheading {
    font-size: 20px;
    font-weight: bold;
    color: #7D5031;
  }

  .checkcircle {
    font-size: 100px;
    color: #676F2E;
  }
}

@media (min-width : 901px) and (max-width : 1200px) {
  .form {
    width: 93%;
  }
}

@media (min-width : 1201px) {
  .form {
    width: 70%;
  }
}