.communityPopup {
}
.modalPopup {
  width: 1000px !important;
  height: 680px !important;
  padding: 0px !important;
}

.modalPopup .ant-modal-title {
  border-bottom: 2px solid #eee7e0 !important;
  font-size: 25px !important;
  padding: 15px;
}

.modalPopup .ant-modal-close {
  color: #7e4f31 !important;
  font-size: larger !important;
}

.modalPopup .ant-modal-close .ant-modal-close-x {
  color: #7e4f31 !important;
  font-size: 25px;
}
.communityForm {
  padding: 20px 35px 0px 40px;
  
}
.labelName {
  font-size: 30px !important;
  font-weight: 700;
  line-height: 30px;
  color: red !important;
  font-family: "poppins", sans-serif;
}
.labelName label {
  font-size: 19px !important;
  font-weight: 700;
  line-height: 30px;
  /* border: 3px solid yellow; */
  min-width: 300px !important;
}
.inputBox {
  /* width: 850px !important; */
  height: 50px;
  border: 1px solid #7e4f31;
}
.inputBoxForImage {
    width: 100% !important;
    margin: 0px ;
    font-size: 22px;
    font-weight: 400;
}
.communityForm .ant-row {
  align-items: center !important;
}
.communityDescription {
  width: 720px !important;
  height: 120px !important;
  border: 1px solid #7e4f31;
}
.communityDropDown {
  /* width: 720px !important; */
  height: 50px;
  border-radius: 4px;
}
.communityDropDown .ant-select-selector {
  height: 100% !important;
  width: 100% !important;
  border: 1px solid #7e4f31 !important;
}
.communityDropDown .ant-select-selector .ant-select-selection-item {
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 22px;
}

.selectOptions {
  font-size: 15px !important ;
  font-weight: 400;
}
.ant-select-suffix {
  font-size: 20px;
  font-weight: 500;
}
.popupButton {
  font-weight: 500;
  background: #686f2e;
  color: white;
  font-size: 19px;
  text-align: center;
  width: 110px;
  height: 50px;
  margin: 30px 0px;
  float: right;
}
.popupButton:hover {
  background: #686f2e !important;
}
.BrowseButton {
  border: 2px solid #686f2e;
  background: none !important;
  color: black;
  font-weight: 500;
  font-size: 19px;
  text-align: center;
  width: 110px;
  height: 50px;
}
.BrowseButton:hover {
  background-color: white !important ;
  color: black !important;
}

.community-image .ant-form-item-control-input-content {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-left: 4px;
  justify-content: space-between;
}

.labelName .ant-form-item-label {
/* margin-left: 100px; */
}

.popupWidth {
  width: 100%;
    /* width: 850px !important; */
    font-size: 22px;
    font-weight: 400;
}
.ant-form-item .ant-form-item-explain-error {
  font-size: 20px;
  margin-bottom: 5px;
}
.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.EditProfileImage {
  width: 40%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
}
.EditBannerImage {
  width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
}

@media(max-width:767px){
  .communityForm {
    padding: 10px 20px 0px 20px;
    
  }
  .popupTitle{
    font-size: 20px;
  }
  .modalPopup .ant-modal-close {
    color: #7e4f31 !important;
    font-size: small !important;
  }
  
  .modalPopup .ant-modal-close .ant-modal-close-x {
    font-size: 15px;
  }
  :where(.css-dev-only-do-not-override-pr0fja).ant-form-item .ant-form-item-label >label {
    margin: 0;
    width: 100%;
}
  .EditProfileImage{
    width: 50%;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  .communityDropDown .ant-select-selector .ant-select-selection-item {
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
  }
  .sv-popup__container{
    left:inherit!important;
    top:inherit!important;
    height:100vh!important;
    width:inherit!important;
    min-width: inherit!important;
  }
  .sv-popup--overlay .sv-list__filter {
    /* display:none!important */
  }
  .sv-popup--overlay .sv-popup__container{
    padding-top: 0px!important;
    z-index: 999!important;
  }
  .sv-popup--overlay .sv-popup__body-content{
    border-radius: 0px!important;
  }
  .sv-list__item.sv-list__item--focused .sv-list__item-body {
    background-color: #1ab394!important;
}
.sv-list__filter-icon {
  /* display: block!important;
  position: absolute!important;
  top: 3%!important;
  z-index: 10!important;
  right: 5px!important;
  left: 90%; */
  display: none;

}
.labelName label {
  font-size: 18px !important;
  font-weight: 600;
  min-width: 300px !important;
}
.sv-list__filter-clear-button{
  display: none;
}
.sv_main .sv_p_root .sv_q input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]), .sv_main .sv_p_root .sv_q select {
  height: calc(2em + 16px);
}
}

@media (min-width:1200px) and (max-width:1500px){
  .modalPopup {
    width:  50rem!important;
    height: 680px !important;
    padding: 0px !important;
  }
  
  .modalPopup .ant-modal-title {
    border-bottom: 2px solid #eee7e0 !important;
    font-size: 1.25rem !important;
    padding: 0.75rem;
  }
  .labelName label {
    font-size: 1rem !important;
    font-weight: 700;
    line-height: 1.5rem;
    /* border: 3px solid yellow; */
    min-width: 14rem !important;
  }
  .inputBox {
    /* width: 850px !important; */
    height: 2.5rem;
    border: 1px solid #7e4f31;
  }
  .popupWidth {
    width: 100%;
      /* width: 850px !important; */
      font-size: 1.2rem;
      font-weight: 400;
  }
  .communityDropDown .ant-select-selector .ant-select-selection-item {
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 1rem;
  }
  .communityDropDown .ant-select-selector {
    height: 90% !important;
    width: 100% !important;
    border: 1px solid #7e4f31 !important;
  }
  .popupButton {
    font-weight: 500;
    background: #686f2e;
    color: white;
    font-size: 1rem;
    text-align: center;
    width: 6rem;
    height: 2.5rem;
    margin: 30px 0px;
    float: right;
  }
  .BrowseButton {
    border: 2px solid #686f2e;
    background: none !important;
    color: black;
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    width: 5.5rem;
    height: 2.5rem;
  }
  .EditBannerImage {
    width: 100%;
      height: 11rem;
      object-fit: cover;
      border-radius: 10px;
  }
  .EditProfileImage {
    width: 40%;
      height: 11rem;
      object-fit: cover;
      border-radius: 10px;
      margin-left: auto;
      margin-right: auto;
  }
  .community-image .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-left: 4px;
    justify-content: space-between;
  }
  
}
@media (min-width:1501px) and (max-width:1900px){
  .modalPopup {
    width:  50rem!important;
    height: 680px !important;
    padding: 0px !important;
  }
  
  .modalPopup .ant-modal-title {
    border-bottom: 2px solid #eee7e0 !important;
    font-size: 1.25rem !important;
    padding: 0.75rem;
  }
  .labelName label {
    font-size: 1rem !important;
    font-weight: 700;
    line-height: 1.5rem;
    /* border: 3px solid yellow; */
    min-width: 14rem !important;
  }
  .inputBox {
    /* width: 850px !important; */
    height: 2.5rem;
    border: 1px solid #7e4f31;
  }
  .popupWidth {
    width: 100%;
      /* width: 850px !important; */
      font-size: 1.2rem;
      font-weight: 400;
  }
  .communityDropDown .ant-select-selector .ant-select-selection-item {
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 1rem;
  }
  .communityDropDown .ant-select-selector {
    height: 90% !important;
    width: 100% !important;
    border: 1px solid #7e4f31 !important;
  }
  .popupButton {
    font-weight: 500;
    background: #686f2e;
    color: white;
    font-size: 1rem;
    text-align: center;
    width: 6rem;
    height: 2.5rem;
    margin: 30px 0px;
    float: right;
  }
  .BrowseButton {
    border: 2px solid #686f2e;
    background: none !important;
    color: black;
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    width: 5.5rem;
    height: 2.5rem;
  }
  .EditBannerImage {
    width: 100%;
      height: 11rem;
      object-fit: cover;
      border-radius: 10px;
  }
  .EditProfileImage {
    width: 40%;
      height: 11rem;
      object-fit: cover;
      border-radius: 10px;
      margin-left: auto;
      margin-right: auto;
  }
  .community-image .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-left: 4px;
    justify-content: space-between;
  }
  
}