.feedsPreviewWrapper {
    height: 100vh;
    /* height: calc(100% - 50px); */
    /* height: 500px; */
}
.feeds-modal-preview {
    height: 100%;
    padding: 20px 10px;
}
.feeds-img-video-container {
    /* width: 95%; */
    width: 100%;
    padding: 0;
    /* min-height: 250px; */
    margin-top: 5px;
}
.prev-icon {
    position: absolute;
    left: 0;
    font-size: 20px;
    cursor: pointer;
    color: #9c9fa4;
}
.next-icon {
    position: absolute;
    right: 0;
    font-size: 20px;
    cursor: pointer;
    color: #9c9fa4;
}
.postsImage {
    /* width: 70%; */
    margin: 0 auto;
}
.feedsPreviewImage {
object-fit: cover;
}
.feedsHeaderWrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.PreviewFeeds-header {
    display: flex;
    align-items: flex-start;
    gap: 15px;
}
.textFont {
    font-size: 13px !important;
}
.feedsPostContent {
    color: #040100;
    display: flex;
    font-size: 16px;
    padding: 10px 0px 0px 0px;
    letter-spacing: 0.5px;
}
.feeds-header-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: #FFF;
    border-radius: 50%;
    background-color: #885d3d;
  }

  .rich-html-text ol {
  padding-left: 20px; /* Ensure there is padding to display the numbers */
  list-style-type: decimal; /* Ensure ordered list numbers are displayed */
}

.rich-html-text ul {
  padding-left: 20px; /* Ensure there is padding to display the bullets */
  list-style-type: disc; /* Ensure unordered list bullets are displayed */
}

.rich-html-text li {
  margin: 5px 0;
}

.rich-html-text a {
  color:blue;
}

.rich-html-text {
    word-wrap: break-word; /* Allows long words to be broken and wrap to the next line */
    overflow-wrap: break-word; /* Ensures compatibility with the latest CSS standards */
    overflow: hidden; /* Hides any overflow content */
}


  @media(max-width:767px){
    .mobile-view-container {
        position: relative;
        height: 70vh; /* Ensure it takes the full viewport height */
      }
  .msg-chat-input{
    /* position: fixed;
    bottom: 0px; */
    padding:2px 0px;
    width:100%;
    /* z-index: 1; */
  }
  }