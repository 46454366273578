.selectfile-delete-icon {
  top: 0px;
  right: 0px;
  background-color: white;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  margin: 2%;
}
.shareMessageInput .ql-container{
  min-height: 100px;
}

.postLabel {
  font-size: xx-large !important;
  color: brown !important;
}
.postLabel label {
  font-size: 25px !important;
}
.postPopup .ant-modal-close {
  color: #7e4f31 !important;
  font-size: larger !important;
}

.postPopup .ant-modal-close .ant-modal-close-x {
  color: #7e4f31 !important;
  font-size: 20px;
}

.react-quill{
    height: "100%";
    width: "100%";
    font-weight: normal;
}

.react-quill .ql-toolbar.ql-snow {
  border: 1px solid #7E4F31;
  border-radius: 10px 10px 0 0;
}

.react-quill .ql-container.ql-snow {
  border: 1px solid #7E4F31;
  border-radius: 0 0 10px 10px;

}

.selectDropdown .ant-select-selector {
  height: 50px !important;
}
.postPopup {
  width: 800px !important;
  height: 680px !important;
  padding: 0px !important;
}
.postPopup .ant-modal-title {
  border-bottom: 2px solid #eee7e0 !important;
  font-size: 25px !important;
  padding: 10px;
  width: 97%;
  white-space: pre;
  overflow: hidden;
  height: 60px;
  text-overflow: ellipsis;
}

.postForm {
  padding: 0px 40px 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}

.postForm::-webkit-scrollbar {
  width:12px; /* Width of the scrollbar */
  height:50px;
}

/* Customize the scrollbar thumb (handle) */
.postForm::-webkit-scrollbar-thumb {
  background-color: #d4d3d3; /* Color of the thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

/* Customize the scrollbar track (background) */
.postForm::-webkit-scrollbar-track {
  background-color: #f7f7f700; /* Color of the track */
  border-radius: 10px; /* Rounded corners for the track */
}

.postForm .ant-row {
  align-items: center !important;
}
.postForm .ant-form-item-label {
  display: contents !important;
}

.quill .ql-snow .ql-tooltip{
  z-index: 9;
}

.FeedsLabelName {
  font-size: 30px !important;
  font-weight: 700;
  line-height: 30px;
}
.FeedsLabelName label {
  font-size: 19px !important;
  font-weight: 700;
  line-height: 30px;
  min-width: 150px !important;
}
.TagsLabelName {
  font-size: 30px !important;
  /* font-weight: 700; */
  line-height: 30px;
}
.TagsLabelName label {
  font-size: 19px !important;
  font-weight: 700;
  line-height: 30px;
  min-width: 150px !important;
}
.FeedsCommunityDescription {
  width: 100%;
  height: 120px !important;
  border: 1px solid #7e4f31;
  font-size: 20px;
  font-weight: 400;
  padding: 4px 11px;
  border-radius: 6px;
}

.FeedsCommunityDescription::-webkit-scrollbar {
  display: none;
}

.popupWidth {
  /* width: 850px !important; */
  /* width: 80% !important; */
}
.FeedsDropDown {
  width: 100% !important;
  /* width: 588px !important; */
  height: 50px;
  border-radius: 4px;
}
.FeedsDropDown .ant-select-selector {
  height: 100% !important;
  width: 100% !important;
  border: 1px solid #7e4f31 !important;
}
.TagsDropDown {
  width: 100% !important;
  /* width: 588px !important; */
  height: 50px;
  border-radius: 4px;
}
.TagsDropDown .ant-select-selector {
  height: 100% !important;
  width: 100% !important;
  border: 1px solid #7e4f31 !important;
}
.FeedsDropDown .ant-select-selector .ant-select-selection-item {
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 22px;
}
.TagsDropDown .ant-select-selector .ant-select-selection-item {
  height: 100%;
  display: flex;
  align-items: center;
  /* font-weight: 400; */
  font-size: 18px;
  border-radius: 20px;
  background-color: #686f2e;
  color: white;
  font-weight: normal;
  padding: 2px 10px;
}
.BrowseButton {
  border: 2px solid #686f2e;
  background: none;
  color: black;
  font-weight: 500;
  font-size: 19px;
  text-align: center;
  width: 110px;
  height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.BrowseButton:hover {
  background-color: white !important ;
  color: black !important;
}
.popupButton {
  font-weight: 500;
  background: #686f2e;
  color: white;
  font-size: 19px;
  text-align: center;
  width: 110px;
  height: 50px;
  margin: 30px 0px;
}
.popupButton:hover {
  background: #686f2e !important;
}
.ant-btn-primary:disabled {
  opacity: 0.6 !important;
  background: #686f2e !important;
  color: white;
}
.blurLoader {
  filter: blur(3px);
}
.loaderModal {
  width: 100px !important;
  height: 300px !important;
  /* background-color: red !important; */
}
.loaderModal {
  display: none;
}
.ant-modal-close-x {
  font-size: 15px !important;
  /* padding-left: 5px; */
  margin: 0px;
}
.loaderModal .ant-modal-body {
  /* background-color: ligh; */
}
.loaderModal .ant-modal-close-x {
  display: none !important;
}
.addImage {
  font-size: 4.5rem; /* 72px */
  line-height: 1;
  align-items: center;
  display: flex;
  justify-content: center;
}
.post-type-dropdown {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.select-type-dropdown {
}

.select-type-dropdown {
  width: 100% !important;
  /* width: 588px !important; */
  height: 50px;
  border-radius: 4px;
}
.select-type-dropdown .ant-select-selector {
  height: 100% !important;
  width: 100% !important;
  border: 1px solid #7e4f31 !important;
}
.select-type-dropdown .ant-select-selector .ant-select-selection-item {
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 400 !important;
  font-size: 22px !important;
}
.select-type-dropdown-exp {
  width: 100% !important;
  height: 40px;
  border-radius: 4px;
}
.select-type-dropdown-exp .ant-select-selector {
  width: 100% !important;
  border: 1px solid #7e4f31 !important;
}

.input-box {
  height: 50px !important;
}
.poll-label {
  font-size: 19px !important;
  font-weight: 700;
  /* line-height: 30px; */
  /* min-width: 150px !important; */
}

.options-list {
  min-height: 100px;
  max-height: 315px;
  overflow: auto;
}
.dynamic-options {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.radio-options-group {
  width: 100% !important;
}
.activeTab {
 border-bottom:  2px solid #7e4f31;
 color: #7e4f31;
}
.optionsTab {
  cursor: pointer;
}
.tabsWrapper {
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 20px;
  display: flex;
  padding: nonr;
  justify-content: center;
  gap: 25px;

}
.input-box {
  font-size: 19px !important;
  font-weight: 700px !important;
}
.poll-expire {
  display: flex;
  margin-top: 10px;
  justify-content: start;
}
.switchPost .ant-row {
  flex-wrap: nowrap;
}
.switchPost label {
  white-space: nowrap;
}
.pollCheckbox{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .FeedsCommunityDescription {
    font-size: 15px;
  }
  .categoryDropdown {
    font-size: 15px;
  }
  .postForm {
    padding: 5px 20px;
  }
  /* .ant-modal-close-x{
    font-size: 12px!important;
    padding-left: 5px;
    margin: 0px;
   } */
  .ant-select .ant-select-arrow .anticon {
    font-size: 15px;
  }
  .FeedsLabelName label {
    font-size: 18px !important;
    font-weight: 600;
  }
  .TagsLabelName label {
    font-size: 18px !important;
    font-weight: 600;
  }
  .input-box {
    height: 40px !important;
  }
  .poll-label {
    font-size: 16px !important;
    font-weight: 500;
  }
  .dynamic-options {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .options-list {
    min-height: 80px !important;
    max-height: 300px !important;
  }
  .switchPost > .ant-form-item > .ant-row {
    flex-wrap: nowrap;
    width: 20vw!important;
  }
  .toggleContainer{
    width:80%
  }
  .poll-expire {
  margin-top: 5px;
    justify-content: start;
  }
  .multipleOptions {
    
  }
  .pollCheckbox > .ant-form-item .ant-form-item-control-input-content{
    display: flex;
    width: auto;
    align-items: end;
    flex-direction: column;
  }
  .pollCheckbox{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .postPopup .ant-modal-title {
    border-bottom: 2px solid #eee7e0 !important;
    font-size: 1.2rem !important;
    padding: 1rem;
    width: 97%;
    white-space: pre;
    overflow: hidden;
    height: 60px;
    text-overflow: ellipsis;
  }
  .FeedsLabelName label {
    font-size: 1rem !important;
    font-weight: 700;
    line-height: 30px;
    min-width: 150px !important;
  }
  .TagsLabelName label {
    font-size: 1rem !important;
    font-weight: 700;
    line-height: 30px;
    min-width: 150px !important;
  }

  .FeedsCommunityDescription {
    width: 100%;
    height: 110px !important;
    border: 1px solid #7e4f31;
    font-size: 1rem;
    font-weight: 400;
  }
  .FeedsDropDown .ant-select-selector {
    height: 90% !important;
    width: 100% !important;
    border: 1px solid #7e4f31 !important;
  }
  .FeedsDropDown .ant-select-selector .ant-select-selection-item {
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 1rem;
  }
  .addImage {
    font-size: 3.5rem; /* 72px */
    line-height: 1;
    align-items: center;
    display: flex;
    justify-content: center;
  }


}
@media (max-width:390px){
  .poll-label {
    font-size: 13px !important;
    font-weight: 500;
  }
}