.headtop {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    font-family: "poppins", sans-serif;
  }
  .createButton {
    font-weight: 500;
    background: #686f2e;
    color: white;
    font-size: 19px;
    text-align: center;
    width: 110px;
    height: 50px;
    margin: 30px 20px;
  }
  .createButton:hover {
    background: #686f2e !important;
  }

  .thumbnailRowForThree {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
  }
  
  .thumbnailRowForThreeColumn {
    width: calc(23% - 1%);
    border: 5px solid #f9f9f9;
    border-radius: 8px;
    margin: 1%;
    border: 1px solid #eaeaea;
    box-shadow: rgba(149, 157, 165, 0.2) 0px -1px 10px 0px;
    height: 350px;
    overflow: hidden;
    transition: transform 300ms;
  }
  
  .thumbnailRowForThreeColumn:hover {
    /* border: 1px solid #499c9f97; */
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transform: scale(1.05);
  }
  
  .thumbnailImg img {
    /* width: 50%; */
  }
  
  .thumbnailTitle {
    font-size: 22px;
    font-weight: 600;
    margin: 10px 10px 0px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .communitythumbnailTitle {
    font-size: 22px;
    font-weight: 600;
    margin: 10px 10px 0px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  
  .communitythumbnailDescription {
    font-size: 20px;
    margin: 0px 10px 0 10px;
    width: 100%;
    min-height: 70px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color:gray
  }
  
  .communitythumbnailDescription p {
    display: flex;
    flex-direction: column;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    
  }
  .joingroup {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
  }
  .joingroup button{
    border-radius: 25px;
    height: 35px;
    text-transform: uppercase;
    font-weight: 600;
  }
 
  .discoverthumbnailRowForThree {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    /* justify-content: space-between; */
  }
  
  .discoverthumbnailRowForThreeColumn {
    width: calc(25% - 8%);
    border: 5px solid #f9f9f9;
    border-radius: 20px;
    margin: 1%;
    border: 1px solid #eaeaea;
    box-shadow: rgba(149, 157, 165, 0.2) 0px -1px 10px 0px;
    /* height: 350px; */
    overflow: hidden;
    transition: transform 300ms;
  }
  
  .discoverthumbnailRowForThreeColumn:hover {
    /* border: 1px solid #499c9f97; */
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transform: scale(1.05);
  }
  
  .discoverthumbnailImg img {
    width: 50%;
  }
  
  .discoverthumbnailTitle {
    font-size: 22px;
    font-weight: 600;
    margin: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  
  .discoverthumbnailDescription {
    font-size: 18px;
    /* margin: 10px 10px 0 10px; */
    width: 100%;
    min-height: 70px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .discoverthumbnailDescription p {
    display: flex;
    flex-direction: column;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  


.topPanelHead {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  margin-right: 4%;
}

.topPanelHead h2 {
  font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    font-family: "poppins", sans-serif;
}

.communityDropDown {
  /* width: 720px !important; */
  height: 50px;
  border-radius: 4px;
}
.communityDropDown .ant-select-selector {
  height: 100% !important;
  width: 100% !important;
  border: 1px solid #7e4f31 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.communityDropDown .ant-select-selector .ant-select-selection-item {
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 22px;
}
.popupWidth {
  width: 100%;
    /* width: 850px !important; */
    font-size: 22px;
    font-weight: 400;
}
.ant-form-item .ant-form-item-explain-error {
  font-size: 20px;
  margin-bottom: 5px;
}
.communityDropDown .ant-select .ant-select-arrow{
  font-size: smaller;
}

.typeDropdown{
  border: 1px solid #686f2e !important ;
  border-radius: 20px !important;
}

.typeDropdown:hover {
  /* border-width: 2px;
  border-color: #686f2e;
  border-radius: 9px; */
}
.admin {
  top: 0px;
  right: 0px;
  background-color: white;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  margin:2%
}
.communityProfileImage{
  border-radius: 150px;
  width: 120px;
  height: 120px;
  background-color: white;
  border-color: white;
  border-width: 2px;
  object-fit: cover;
}
.adminIcon{
  font-size: 1.875rem; /* 30px */
line-height: 2.25rem; /* 36px */
}
.mobWraplist{
  width: 100%;
}

.blockedMessage{
  filter: blur(2px);
}
.blockedTextMessage{
  text-align: center;
  color: red;
}

@media (max-width : 600px){
  .communityGrid{
    display: flex;
    flex-direction: row!important;
    align-items: center;
    justify-content: center;
  }
  .communityGrid img{
    border-radius: 150px;
    width: 90px!important;
    height: 90px!important;
    background-color: white;
    border-color: white;
    border-width: 2px;
    margin: 5px;
  }
  
  .communityUI  .thumbnailRowForThree{
    flex-flow: column wrap;
  }
  .thumbnailImg img {
    width: 100%;
  }
  .communityUI .thumbnailRowForThreeColumn {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    background: #e5e1cf;
    padding: 20px 10px;
    margin: 1% 1%;
    flex-flow: row wrap;
}
  .communityUI  .thumbnailImg{
    display: none;
  }
  .communityGridParent{
    bottom: 0!important;
    width: 100%;
  }
  .communityUI .thumbnailTitle {
    width: 100%;
    white-space: break-spaces;
    overflow: inherit;
    text-align: left;
    font-size: 18px;
    padding-left: 15px;
}
.mobWraplist {
  min-height: 0;
  max-width: 70%;
  flex-flow: column wrap;
  width: 100%;
  align-items: flex-start;
  justify-content: left;
  display: flex;
  flex-direction: column;
}
  .communityUI .thumbnailDescription {
    margin: 0;
    text-align: left;
    min-height: auto;
    justify-content: space-evenly;
}
.communityUI .thumbnailDescription p {
  text-overflow: unset;
  -webkit-line-clamp: unset;
  text-align: left;
  overflow: auto;
  margin: 10px;
  padding: 0;
}
  .communityUI    .joingroup{
    justify-content: flex-end;
    padding: 0;
    width: auto;
  }

  .topPanelHead {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-end;
    margin-right: 4%;
  }
  
 
  .topPanelHeadColR.yourComCreate {
    position: fixed;
    right: 0;
    bottom: 0;
    opacity: 1;
    z-index: 9999;
}
.topPanelHead h2 {
  display: none;
}
.communitythumbnailDescription {
  min-height: auto!important;
}
.communitythumbnailDescription p{
  margin: 0 15px!important;
}
.createButton {

  box-shadow: -1px 1px 8px 4px #ccc;
}
.thumbnailRowForThreeColumn .bi.bi-person-gear{
  position: absolute;
  background-color: rgb(103, 112, 46);
  padding: 0px 4px 5px 5px!important;
  right: 0!important;
  top: 0!important;
  border-bottom-right-radius: unset!important;
  border-bottom-left-radius: 50%!important;
}
.communitythumbnailTitle {
  font-size: 20px;
  font-weight: 600;
  margin: 10px 10px 0px 10px;
  white-space: pre-wrap;
  overflow: auto;
  text-overflow: initial;
  text-align: left;
}
.thumbnailRowForThreeColumn:hover {
  /* border: 1px solid #499c9f97; */
  box-shadow: none;
  transform: none;
}
.discoverthumbnailRowForThreeColumn:hover {
  /* border: 1px solid #499c9f97; */
  box-shadow: none;
  transform: none;
}
}
@media (min-width : 601px) and (max-width: 900px){
  .thumbnailRowForThree {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .thumbnailRowForThreeColumn {
    width: calc(47% - 1%);
    border: 5px solid #f9f9f9;
    border-radius: 8px;
    margin: 1%;
    border: 1px solid #eaeaea;
    box-shadow: rgba(149, 157, 165, 0.2) 0px -1px 10px 0px;
    height: 350px;
    overflow: hidden;
    transition: transform 300ms;
  }
  .communityCategories{
    display: none!important;
  }
  .communityBanner{
    display: none;
  }
  .createButton {
    font-weight: 500;
    background: #686f2e;
    color: white;
    font-size: 15px;
    text-align: center;
    width: 75px;
    height: 35px;
    margin: 70px 0px 0px 0px!important;
}
.infoBox{
  display: none;
}
.topPanelHead{
  display: none;
}
}
@media (min-width : 901px) and (max-width: 1100px){
  .thumbnailRowForThreeColumn {
    width: calc(47% - 1%);
    border: 5px solid #f9f9f9;
    border-radius: 8px;
    margin: 1%;
    border: 1px solid #eaeaea;
    box-shadow: rgba(149, 157, 165, 0.2) 0px -1px 10px 0px;
    height: 350px;
    overflow: hidden;
    transition: transform 300ms;
  }
}
@media (min-width:1200px) and (max-width:1500px){
  .createButton {
    font-weight: 500;
    background: #686f2e;
    color: white;
    font-size: 1rem;
    text-align: center;
    width: 6rem;
    height: 2.5rem;
    margin: 30px 20px;
  }
  .topPanelHead h2 {
    font-size: 1.3rem;
      font-weight: 700;
      line-height: 30px;
      font-family: "poppins", sans-serif;
  }
  .communityProfileImage{
    border-radius: 150px;
    width: 6rem;
    height: 6rem;
    background-color: white;
    border-color: white;
    border-width: 2px;
    object-fit: cover;
  }
  .adminIcon{
    font-size: 1.4rem; /* 30px */
  line-height: 2.25rem; /* 36px */
  }
  .communitythumbnailTitle {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 10px 10px 0px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .communitythumbnailDescription {
    font-size: 0.9rem;
    margin: 0px;
    width: 100%;
    min-height: 70px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color:gray
  }
  .thumbnailRowForThreeColumn {
    width: calc(23% - 1%);
    border: 5px solid #f9f9f9;
    border-radius: 8px;
    margin: 1%;
    border: 1px solid #eaeaea;
    box-shadow: rgba(149, 157, 165, 0.2) 0px -1px 10px 0px;
    height: 300px;
    overflow: hidden;
    transition: transform 300ms;
  }
}
@media (min-width:1501px) and (max-width:1900px){
  .createButton {
    font-weight: 500;
    background: #686f2e;
    color: white;
    font-size: 1rem;
    text-align: center;
    width: 6rem;
    height: 2.5rem;
    margin: 30px 20px;
  }
  .topPanelHead h2 {
    font-size: 1.3rem;
      font-weight: 700;
      line-height: 30px;
      font-family: "poppins", sans-serif;
  }
  .communityProfileImage{
    border-radius: 150px;
    width: 6rem;
    height: 6rem;
    background-color: white;
    border-color: white;
    border-width: 2px;
    object-fit: cover;
  }
  .adminIcon{
    font-size: 1.4rem; /* 30px */
  line-height: 2.25rem; /* 36px */
  }
  .communitythumbnailTitle {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 10px 10px 0px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .communitythumbnailDescription {
    font-size: 0.9rem;
    margin: 0px;
    width: 100%;
    min-height: 70px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color:gray
  }
  .thumbnailRowForThreeColumn {
    width: calc(23% - 1%);
    border: 5px solid #f9f9f9;
    border-radius: 8px;
    margin: 1%;
    border: 1px solid #eaeaea;
    box-shadow: rgba(149, 157, 165, 0.2) 0px -1px 10px 0px;
    height: 300px;
    overflow: hidden;
    transition: transform 300ms;
  }
}