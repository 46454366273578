@media(max-width:767px){
   .showfootermobile .footerRow {
        display: flex;
        flex-flow: row wrap;
        padding: 15px 15px;
        width: 100%;
        border-top: 1px solid #f7f7f7;
        justify-content: space-between;
    }
   .showfootermobile .footerRow .footerCol{
        width: 100%;
    }
    .showfootermobile   .footerCol.copyrightWrap{
        display: flex;
    justify-content: center;
    padding: 10px 0 0;
    width: 100%;
    }
    .showfootermobile   .copyright {
        display: inline-block;
        font-weight: 600;
        display: flex;
        justify-content: center;
    }
.showfootermobile  .footerUl {
        display: flex;
        gap: 0.5rem;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        align-items: center;
    }
    .showfootermobile  .footerUl ul {
        flex-direction: column;
        gap: 0;
        align-items: center;
    }
    .showfootermobile  .footerUl a {
        font-weight: 600!important;
        margin: 0;
        padding: 0 10px;
        padding: 0;
        color: #6d7072;
        font-size: 13px;
    }
}

