.phoneVerify {
  background-color: #7c5030;
  border-radius: 0;
  width: 50%;
  /* max-width: 600px; */
  /* height: 50px; */
  font-size: 18px;
  color: #ffffff;
  margin: 10px 0px;
  padding: 5px 5px;
  margin-top: 10px;
}

.phoneModal {
  width: 350px !important;
  height: 250px !important;
  position: absolute !important;
  right: 20px !important;
  top: 40% !important;
}

.phoneTitle {
  margin-top: 10px;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.phoneNumber {
  margin-top: 10px;
  color: #0075C1;
  font-size: 18px !important;
}

.PhoneInputBox {
  border: none;
  margin-top: 10px;
  border: 0px;
  outline: 0px;
  border-bottom: 1px solid;
}

.PhoneInputBox:hover {
  border-bottom: 1px solid;
}

.PhoneInputBox:focus {
  border-bottom: 1px solid !important;


}

.react-tel-input .country-list {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  /* top: 5px;
  background: #b55e5edb; */
}

li.country {
  width: 100%;
  display: flex;
}

.react-tel-input .country-list .flag {
  top: 12px !important;
}

.react-tel-input .form-control {
  height: 50px !important;
  margin-left: 55px !important;
  padding-left: 3px !important;
  width: 340px ;
}

.expandForm {
  margin: 20px 0px;
}

.expandForm input {
  border-radius: 13px;
  width: 50px !important;
  border: 1px solid gray !important;
  /* width: 140px; */
  height: 50px;
}

.space {
  margin: 15px 10px;
}

.recaptcha-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: auto;
  /* Set a default height as needed */
}

#recaptcha-container {
  position: relative;
  width: 16%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  /* bottom: 110px !important; */
}

.grecaptcha-badge {
  /* position: absolute !important; */
  position: static !important;
  width: 100%;
  height: 100%;
  /* transform: translate(-50%, -50%) !important; */
  /* left: 0px !important; */
}

.rc-anchor-invisible-text {
  display: none !important;
}

.expandForm input::-webkit-outer-spin-button,
.expandForm input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.checkboxmultiPhone .ant-checkbox-inner{
  border:rgb(77, 77, 77) solid 1.7px!important;
}

@media (min-width:400px)and(max-width:767px) {
  .otp {
    /* width: 30px; */
    /* width: 100px!important; */
    transform: scale(0.8);
  }
  .checkboxmultiPhone {
    font-size: 14px !important;
    width: 75% !important;
    margin-bottom: 5px;
}

  .recaptcha-wrapper {
    width: 20px;
    height: 20px;
    /* Adjust the height for smaller screens */
  }

  #recaptcha-container {
    width: 100%;
    height: 100%;

    /* bottom: 110px !important; */
  }

  .react-tel-input .form-control {
    height: 50px !important;
    margin-left: 65px !important;
    padding-left: 15px !important;
    width: 320px !important;
  }

  .react-tel-input .selected-flag {
    outline: none;
    position: relative;
    width: 52px;
    height: 100%;
    padding: 0 0 0 26px;
    border-radius: 3px 0 0 3px;
  }

  
}

@media (max-width: 399px) {
  .checkboxmultiPhone {
    font-size: 14px !important;
    width: 75% !important;
    margin-bottom: 5px;
}
.expandForm input{
  width: 44px !important;
}
}