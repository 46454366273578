.singleBigImg {
  width: 100%;
  max-height: 50%;
  border-radius: 20px !important;
  object-fit: contain !important;
}

.post_img_video_width60 {
  position: relative;
  width: calc(60%) !important;
  height: 50% !important;
  align-items: center;
  display: flex;
}
.post_img_video_width45{
  width: calc(45%) !important;
}
.post_img_video_width40{
  width: calc(40%) !important;
}

.post_imgvideo_container {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.feedsWrapper {
  background-color: #ebebeb;
  height: 100%;
  position: relative;
}

.feedsContent {
  display: flex;
  flex-flow: row wrap;
}

.headingFeed {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
  align-items: center; */
  display: none;
}

.communityList {
  display: flex;
  flex-direction: row;
  /* flex-wrap: nowrap;
  overflow-x: auto; */
  padding: 10px;
}

.feedscommunitylist {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: scroll;
  padding-top: 5px;
}

.feedscommunitylist::-webkit-scrollbar {
  display: none;
}

.headingFeedLabel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2% 0px;
}

.postCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 3px 6px #c7a58f0d;
  border: 0.5px solid #c6c6c6;
  border-radius: 15px;
  padding: 18px;
  background: #fff;
  margin-bottom: 28px;
}

.feedsLeftSide {
  width: calc(100% - 415px);
  height: 100vh;
  background-color: #fafafa;
  padding: 30px;
  border-radius: 23px 0px 0px 23px;
  overflow-y: auto;
  overflow-x: hidden;
}

.feedsLeftSide::-webkit-scrollbar {
  display: none;
}

.feedsPostContainer {
  width: 100%;
  max-width: 886px;
  /* margin: 0 auto; */
}

.headtop {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  font-family: "poppins", sans-serif;
}

.requestText {
  font-weight: 300;
}

.createcommunityButtonWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 18px;
  padding-top: 5px;
}

/* .plusIcon {
  background-color: #f7f7f7;
  height: 95%;
  margin: 2px 2px 2px 2px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #040100;
  font-size: 55px;
} */

.feedscreatecommunityButton {
  border-radius: 18px;
  width: 150px;
  height: 120px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* border: 3px solid red; */
  background: linear-gradient(to bottom, #f0f0f0, #eeeeee);
  transition: transform 200ms;
  margin-top: 5px;
}

.feedscreatecommunityButton:hover {
  transform: scale(1.06);
}

.communityNames {

  font-size: 15px;
  color: #67702e;
  text-align: center;
  padding-top: 10px;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
}

.communityNamesOfPost {
  font-size: 15px;
  color: #af7d5c;
  text-align: center;
  padding-top: 10px;
  text-wrap: wrap;
}

.feedsRightSide {
  width: 415px;
  background-color: white;
  padding: 30px;
  box-shadow: -2px 0px 5px #cfcfcf57;
  border-radius: 0px 23px 23px 0px;
  position: sticky;
  /* top: 32px; */
  /* height: 740px; */
  height: 100vh;
  right: 0px;
  bottom: 0px;
}

.RightSideTextLabel {
  color: #040100;
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: 700;
  line-height: 30px;
}

.feedlistImg {
  cursor: pointer;
  /* padding: 3px; */
  /* border: 1px solid  #68702E; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 18px;
  transition: transform 200ms;
}

.feedlistImg:hover {
  transform: scale(1.06);
}

.feedslistinner {
  width: 150px;
  min-height: 128px;
  padding: 0;
  margin: 0 15px;
}

.posttitle {
  font-weight: 700;
  font-size: 14px;
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.postContent {
  color: #040100;
  display: flex;
  font-size: 16px;
  padding: 0 10px 20px;
  letter-spacing: 0.5px;
  overflow-wrap: break-word;  
    word-wrap: break-word; 
    word-break: break-word;
}

.headingfeed {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
}

.RightSideThum {
  display: flex;
  flex-flow: row wrap;
}

.RightSideThumCol {
  width: 47%;
  margin-bottom: 6%;
}

.RightSideThumImg {
  border: 1px solid #e3e3e3;
  padding: 5px;
  border-radius: 5px;
}

.RightSideThumCol:nth-child(odd) {
  margin-right: 3%;
}

.RightSideThumCol:nth-child(odd) {
  margin-right: 3%;
}

.minddiv {
  padding: 0;
  display: flex;
  margin-top: 10px;
}

.minddiv input {
  width: 545px;
  border-radius: 26px;
  padding: 0 15px;
  color: #000;
  border: 1.5px solid;
}

.noPadding {
  padding: 0;
}

.createPostWrapper {
  margin-bottom: 40px;
  box-shadow: 0px 3px 6px #c7a58f0d;
  border: 0.5px solid #c6c6c6;
  border-radius: 15px;
  padding: 18px;
  background: #fff;
  margin-bottom: 28px;
}

.postCreate {
  display: flex;
  margin-top: 10px;
  border-top: 1px solid;
  justify-content: space-around;
}

.trending-images {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.postContentWrapper {
  width: 100%;
}

.image {
  height: 100%;
  border-radius: 10px;
  transition: transform 0.3s ease;
  object-fit: cover;
}

.video {
  width: 100%;
  height: 100%;
}

.display-img-video {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: rgba(22, 22, 22, 0.68);
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 10px;
  transition: transform 0.3s ease 0s;
}

.display-img-video:hover,
.image:hover {
  transform: scale(1.02);
}

.react-image-video {
  width: 40%;
  height: 86%;
  background: #fff;
  position: fixed;
  z-index: 1;
  top: 53% !important;
  left: 50% !important;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 3px 6px #4a4a4a6b;
  transform: translate(-50%, -50%);
}

.ReactImageVideoLightbox {
  display: flex;
  justify-content: center;
  height: 65%;
}

.ReactImageVideoLightbox>div {
  width: 97% !important;
  position: relative !important;
  background-color: rgb(0 0 0 / 67%) !important;
}

.ReactImageVideoLightbox>div+div+div {
  display: none !important;
}

.comment-section {
  padding: 15px;
}

.message-textfield {
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0px;
  padding: 10px;
  display: flex;
  box-shadow: 0px -3px 8px 0px #b0b0b06b;
  overflow: hidden !important;
}

.msg-send-outlineicon {
  width: 50px;
  height: 50px;
  display: flex;
  cursor: pointer;
  color: #9c9fa4;
  font-size: 20px;
  border: 1px solid #9c9fa4;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.msg-send-outlineicon:hover {
  color: #202020;
  border: 1px solid #4785f1;
  background-color: #96c5ffc7;
}

.post-comments {
  cursor: pointer;
  font-weight: 600;
  color: black;
  display: flex;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.post-comments:hover {
  /* border-bottom: 1px solid #885d3d; */
}

.comment-content-container {
  max-width: 90%;
  min-width: 30%;
}

.comment-content {
  background-color: #fff0e8;
  padding: 13px 13px 3px;
  border: 1px solid #d1d1d1;
  border-radius: 10px;

}

.reply-button {
  font-size: 0.85rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  padding: 0.5rem 0 0 0;
}

.load-more-button {
  font-size: 0.9rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
  padding: 0.5rem 0 0 0.5rem;
}

.noOfReplies {
  font-size: 0.85rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  padding: 0.5rem 0 0 0;
}

.modalpopup {
  padding-bottom: 0px !important;
  top: 30px !important;
}

.parent-popup {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  background-color: #20202066;
}

.react-image-video-header {
  position: relative;
  width: 100%;
  height: 100px;
  background: white;
  overflow: hidden;
  border-bottom: 1px solid #d3cdcd;
  margin-bottom: 5px;
}

.popup-close-icon {
  font-size: 30px;
  height: 30px;
  color: #a19d9d;
}

.popup-close-icon:hover {
  color: #804c31;
}

.ReactImageVideoLightbox div>div:nth-child(2) {
  display: none !important;
}

/* =============================== */
.modalpopup .ant-modal-header {
  padding: 15px !important;
  padding-left: 6px !important;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
}

.feeds-img-video-container1 {
  width: 100%;
  padding: 0;
  min-height: 250px;
  margin-top: 5px;
}

.modalpopup .ant-modal-body {
  /* padding: 15px 21px !important; */
}

.msg-chat-input {
  display: flex;
  padding: 12px 10px;
  align-items: center;
}

.msg-chat-input input {
  flex: 1;
  font-size: 18px;
  height: 55px;
  margin: 0 8px;
  border-radius: 40rem;
}

.msg-comment-input {
  display: flex;
  padding: 22px 10px 0px 10px;
  align-items: center;
}

.msg-comment-input input {
  flex: 1;
  font-size: 18px;
  height: 55px;
  margin: 0 8px;
  border-radius: 40rem;
}

.reply-chat-input {
  display: flex;
  padding: 5px 10px 2px 10px;
  align-items: center;
  width: 80%;
  margin-left: 19%;
}

.replyComment {
  margin-left: 20%;
}

.reply-chat-input input {
  flex: 1;
  font-size: 18px;
  height: 55px;
  margin: 0 8px;
  border-radius: 40rem;
}

.reply-content-container {
  max-width: 75%;
  min-width: 40%;
}

.reply-content {
  background-color: #fff0e8;
  padding: 5px 10px;
  border: 1px solid #d1d1d1;
  border-radius: 10px;

}

.singleBigImg {
  width: 100%;
  max-height: 50%;
  border-radius: 10%;
  object-fit: contain !important;
}

.post_img_video_width60 {
  position: relative;
  width: calc(60%) !important;
  height: 50% !important;
  align-items: center;
  display: flex;
}
.post_img_video_width45{
  width: calc(45%) !important;
}
.post_img_video_width40{
  width: calc(40%) !important;
}

.post_imgvideo_container {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.post-action-btn {
  position: relative;
  width: 35px;
  height: 35px;
  display: flex;
  border: 1px solid lightgrey;
  justify-content: center;
  align-items: center;
  background: #ededed;
  border-radius: 50%;
  /* transform: rotate(90deg); */
}

.post-action-container {
  display: none;
  z-index: 2;
  position: absolute;
  top: 35px;
  right: 6px;
  border-radius: 6px;
  width: 150px;
  padding: 18px;
  background: #FFF;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12) !important;
}
.post-del-btn:hover {
  color: #ff6363 !important;
  border-color: #ff6363 !important;
}

.ant-popover .ant-popover-inner {
  width: 150px;
  padding: 18px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
}

/* ::-webkit-scrollbar {
  width: 0px;
} */
.ant-modal .ant-modal-content {
  padding: 0 !important;
}

.ant-modal .ant-modal-footer {
  box-shadow: rgb(0 0 0 / 11%) 0px -4px 12px;
}

.msg-send-icon {
  width: 40px !important;
  height: 40px !important;
  display: flex;
  align-items: center;
  color: rgb(0 0 0 / 42%);
  border: 1px solid;
  justify-content: center;
}

.feeds-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}

.feeds-header-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: #fff;
  border-radius: 50%;
  background-color: #885d3d;
}

.modalpopup .ant-modal-content .ant-modal-close {
  width: 35px !important;
  height: 35px !important;
  border-radius: 35px !important;
  background-color: #d8d7d7 !important;
}

.modalpopup .ant-modal-content .ant-modal-close:hover {
  background-color: rgb(181, 181, 181) !important;
}

/* -------- */
.plusPostCreate {
  width: 150px;
  height: 150px;

}

.ant-input-group .ant-input-affix-wrapper:not(:last-child):hover {
  border-color: #7C5231;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child :hover {
  background-color: #7C5231;
}

.postsImage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 120px;
  padding-bottom: 10px;
}

.prev-icon,
.next-icon {
  font-size: 30px;
  cursor: pointer;
  color: #9c9fa4;
}

.prev-icon:hover,
.next-icon:hover {
  color: #202020;
}

.post-like-share {
  display: flex;
  padding: 8px;
  justify-content: space-between !important;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
}

.like-share-icon {
  font-size: 24px !important;
  margin-right: 5px;
}

.FeedPostContent {
  font-size: 20px;
  margin: 5px 10px;
  min-height: 100px;
  display: flex;
  /* align-items: center; */
  overflow-wrap: break-word;  
  word-wrap: break-word; 
  word-break: break-word;
}
.FeedPostContent >span{
  max-height: 350px;
  overflow-y: scroll;
}
.postActualContent >span{
  display: -webkit-box; 
            -webkit-box-orient: vertical; 
            -webkit-line-clamp: 5; 
            overflow: hidden; 
            text-overflow: ellipsis;
}
.FeedPostContent > span::-webkit-scrollbar {
  display: none;
}

.hamburgerTrending {
  padding: 7px;
  width: auto;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 26px;
  position: absolute;
  top: -49px;
  right: 0;
  background: transparent;
}

.showMobileIcon {
  display: none;
}

.react-multi-carousel-track {
  padding-top: 5px;
}

.feedsPlusButton {
  font-size: 2.8rem;
}

.singlePost {
  width: 600px;
  height: 340px;
  /* height: 325px; */
  border-radius: 10px;
}

.multiplePost {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.pollContentContainer {
  width: 100%;
  border-left: 3px solid #06be51;
  padding-left: 10px;
}

.pollOptions {
  width: 98%;
  border: 0.5px solid #c6c6c6;
  border-radius: 15px;
  margin: 20px 0px 0px 10px;
  padding: 15px 0px 15px 10px;
  border-radius: 5px;
}

/* .ant-radio-checked .ant-radio-inner{
  border-color: red !important ;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: red !important;
}

.ant-radio:hover .ant-radio-inner {
  border-color: red !important ;
} */
.option-selected {
  background-color: #d8f4e3;
  border: 1px solid #0abe51;
  /* "to left" / "to right" - affects initial color */
  background: linear-gradient(to left, hsl(144, 64%, 80%) 50%, lightblue #0abe51) right;
  background-size: 200%;
  transition: .2s ease-out;
}

.postOptionContent {
  color: #040100;
  display: flex;
  font-size: 16px;
  /* padding: 0 10px 20px; */
  letter-spacing: 0.5px;
}

.select-Dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pollModal{
  width: 700px!important;
}

.poll-voting-options {
  /* min-height: 250px !important; */
  /* max-height: 500px; */
  /* overflow-x: hidden; */
  height: 250px;
  overflow: hidden;
  /* overflow: auto; */
}
.pollModal .ant-modal-body {
  margin: 0px 10px;
  display: flex;
  justify-content: center;
}
.pollModal ::-webkit-scrollbar {
  display: none;
}
.pollModalContainer {
  padding-bottom: 10px;
  max-height: 350px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  width:90%;
  margin-top: 10px;
}
.pollModalOptions {
  width: 98%;
  border: 0.5px solid #c6c6c6;
  border-radius: 15px;
  padding: 12px 0px 12px 10px;
  border-radius: 5px;
}
.two-columns-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width:100%;
  padding: 1rem;
}

/* columns */
.two-columns-grid > * {
  padding:1rem;
}
.pollUser{
  padding:0.25rem 0.75rem;
}
.pollViewAll{
  padding:0.75rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
}
.pollModal .ant-modal-close .ant-modal-close-x {
  color:#ffffff!important;
}
.pollModal .ant-modal-close {
  color: #ffffff !important;
  font-size: larger !important;
}
.pollModal .ant-modal-title{
  border-radius: 8px 0 0 8px;
}
.poll-radio-option {
  font-size: 17px;
  font-weight: 600;
}
.remainingCharacters{
  text-align: end;
  margin-top: -10px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #9b9b9b;
}
.commentRemainingCharacters{
  text-align: end;
  font-weight: 500;
  margin-right: 9%;
  font-size: 12px;
  color: #9b9b9b;
  /* letter-spacing: 0.85px; */
  margin-top: 2px;
}
.postCommentRemainingCharacters{
  text-align: end;
  font-weight: 500;
  margin-right: 7%;
  font-size: 12px;
  color: #9b9b9b;
  /* letter-spacing: 0.85px; */
  margin-top: 2px;
}
.modalCommentRemainingCharacters{
  text-align: end;
  font-weight: 500;
  margin-right: 10%;
  font-size: 12px;
  color: #9b9b9b;
  /* letter-spacing: 0.85px; */
  margin-top: -10px;
  padding-bottom: 10px;
}
.feedsLinkContainer{
    margin-left: 30%;
}

.blockedCommunityImage{
  filter: blur(2px);
}

.tag{
  border: 1px solid #67702E;
  border-radius: 20px;
  padding: 1px 10px;
  margin: 0px 2px 2.5px 2px;
  font-size: 15px;
  background-color: #67702E;
  /* background-image: linear-gradient(300deg, #804C31, #67702E); */
  color:white
}
.tagContainer{
  margin :0 0 10px 0;
}


.rich-html-text ol {
  padding-left: 20px; /* Ensure there is padding to display the numbers */
  list-style-type: decimal; /* Ensure ordered list numbers are displayed */
}

.rich-html-text ul {
  padding-left: 20px; /* Ensure there is padding to display the bullets */
  list-style-type: disc; /* Ensure unordered list bullets are displayed */
}

.rich-html-text li {
  margin: 5px 0;
}

.rich-html-text a {
  color:blue;
}

.rich-html-text {
    word-wrap: break-word; /* Allows long words to be broken and wrap to the next line */
    overflow-wrap: break-word; /* Ensures compatibility with the latest CSS standards */
    overflow: hidden; /* Hides any overflow content */
}

.filterSearchContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.custom-dropdown-menu .ant-dropdown-menu {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15)!important;
  
}
.custom-dropdown-menu{
  z-index: 0 !important;
}

.custom-menu .ant-dropdown-menu-item-selected {
  background-color: #686f2e26 !important; /* Change this to your desired background color */
  color: #686f2e !important; /* Change this to your desired highlight color */
}

.custom-menu .ant-dropdown-menu-item:hover {
  background-color: #686f2e26 !important; /* Change this to your desired hover background color */
  color: #686f2e !important; /* Change this to your desired hover text color */
}
.filterIcon{
  font-size: 25px;
  background-color: #67702E;
  color: white;
  padding: 3px 8px;
  margin: 0 2px;
  border-radius: 5px;
}

@media (max-width: 767px) {
  .filterIcon{
    padding: 5px 8px;
  }
  .filterSearchContainer{
  width: 100%;
  }
  .tag{
    font-size: 13px;

  }
  .feedsLinkContainer{
      margin-left: 0%;
  }
  .remainingCharacters{
    margin-bottom: 5px;
  }
  .commentRemainingCharacters{
    margin-right: 18%;
  }
  .infinite-scroll-component__outerdiv {
    width: 100%;
  }

  .showMobileIcon {
    display: block;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black background */
    z-index: 0;
    /* Ensure the overlay is above other content */
    display: none;
    /* Initially hidden */
  }

  .overlay.show {
    display: block;
    /* Show the overlay when needed */
  }

  .feedsRightSide {
    display: none;
  }

  .communityList {
    display: none;
  }


  .feedsRightSide.visible {
    display: block;
    position: fixed;
    width: 75%;
    height: 100vh;
    top: 61px;
    background: #f7f7f7;
    border-radius: inherit;
    right: -20px;
    transition: 1s;
  }

  /* .plusCreate {
    right: 21px;
    border: unset;
    background: unset!important;
    width: unset!important;
    height: unset!important;
    bottom: 25px;
    position: fixed;
    z-index: 999;
    box-shadow: none;
  } */
  .plusCreate {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 10px;
    z-index: 999;
    right: 12px;
    width: 70px;
    height: 70px;
    /* border-radius: 50% !important;
    background-color: rgb(255, 255, 255);
    border-width: 0.5px;
    border-color: gray; */
  }


  .feedsLeftSide {
    width: auto !important;
    border-radius: unset !important;
    flex-flow: column wrap !important;
    display: flex !important;
    padding: 10px 3px !important;
    background-color: #fff !important;
    overflow: hidden !important;
    align-items: center !important;
    justify-content: center !important;
    height: 100%
  }

  .infinite-scroll-component {
    /* display: none; */
  }

  .feedscreatecommunityButton {
    right: 21px;
    border: unset;
    background: unset !important;
    width: unset !important;
    height: unset !important;
    bottom: 25px;
    position: fixed;
    z-index: 999;
  }

  /* .plusIcon{
  background: #fff!important;
  height: 30px!important;
  padding: 25px 10px;
  font-size: 55px;
  border-radius: 50%!important;
  box-shadow: 1px 1px 10px 6px #ccc;
} */
  .hamburgerTrending {
    display: none !important;
  }

  .hamburgerTrending img {
    width: 28px;
    padding: 6px;
  }

  .post-comments {
    /* padding: 0 10px; */
  }

  .postPopup .ant-modal-title {
    font-size: 20px !important;
    line-height: 37px;
  }

  .postPopup .ant-form-item-control-input-content {
    display: flex;
    width: auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .postPopup .ant-form-item-control-input-content .ant-btn-primary:disabled {
    opacity: 0.6 !important;
    background: #686f2e !important;
    color: white;
    margin: 15px 0;
  }

  .flex.gap-5.w-full.flex-wrap.justify-center li img {
    max-height: 100px;
  }

  /* .communityList {
    display: block;
    float: right;
} */
  .feedsPostContainer .headtop {
    display: none;
  }

  .feedsPostContainer {
    padding: 0px 0px
  }

  .feedsSearch-container {
    width: 100% !important;
    /* margin-bottom: 10%; */
  }

  .feedsContainer {
    padding: 0px;
    margin: 0 auto;
  }

  .feedsExtendModal.modalpopup {
    width: 100% !important;
    top: 0 !important;
    transform: scaleY(1);
    transform-origin: bottom !important;
    transition-delay: 5s;
  }

  .feedsExtendModal .ant-modal-body {
    position: relative;
    height: auto !important;
  }

  .feedsExtendModal .post-like-share {
    display: flex;
    padding: 8px;
    background: #fff;
    z-index: 999;
    width: 380px;
    /* position: fixed; */
    justify-content: space-around;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;

  }

  .ant-modal-close {
    /* display: none; */
  }

  .feedsExtendModal .postsImage .imageWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 120px;
  }

  .feeds-header .p-0 {
    padding: 0 10px;
  }

  .titlecomm {
    display: none;
  }

  .headtop {
    display: none !important;
  }

  .feedsPage {
    padding: 0 !important;
  }

  .postsImage {
    justify-content: center !important;
  }

  .ant-col.ant-col-offset-20.ant-form-item-control {
    margin: 0 !important;
  }

  .feedsExtendModal {
    transform-origin: unset;
    bottom: 0 !important;
    height: 100%;
    top: 0 !important;
  }

  .commenticon {
    height: 23px;
  }

  .posttitle {
    font-weight: 700;
    font-size: 12px;
    padding-left: 10px;
    display: flex;
    align-items: center;
  }

  .postCommunityName {
    max-width: 130px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block !important;
    white-space: nowrap;
  }
  .userName {
    max-width: 115px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block !important;
    white-space: nowrap;
  }

  .singlePost {
    height: 195px;
    width: 350px
  }

  .msg-chat-input input {
    flex: 1;
    font-size: 15px;
    height: 40px;
    margin: 0 8px;
    border-radius: 40rem;
  }

  .reply-chat-input {
    display: flex;
    padding: 5px 0px 2px 0px;
    align-items: center;
    width: 96%;
    margin-left: 5%;
  }

  .replyComment {
    margin-left: 15%;
  }

  .reply-chat-input input {
    flex: 1;
    font-size: 15px;
    height: 40px;
    margin: 0 8px;
    border-radius: 40rem;
  }

  .msg-comment-input {
    display: flex;
    padding: 22px 10px 0px 10px;
    align-items: center;
  }

  .comment-content-container {
    max-width: 80%;
    min-width: 45%;
  }

  .comment-section {
    padding: 15px 0px;
  }

  .reply-content {
    background-color: #FFF0E8;
    padding: 5px 10px;
    border: 1px solid #d1d1d1;
    border-radius: 10px;
    font-size: 15px;
  }

  .load-more-button {
    font-size: 0.9rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);
    padding: 0.5rem 0 0.5rem 0;
  }

  .reply-content-container {
    max-width: 80%;
    min-width: 50%;
  }

  .pollOptions {
    width: 97% !important;
  }

  .postOptionContent {
    font-size: 14px !important;
  }
  .two-columns-grid {
    padding: 1rem 0;
  }
  .pollUser{
    padding:0.25rem 0rem;
    margin: 0 1rem;
  }
  .pollModal .ant-modal-body {
    margin: 0px;
  }

}

@media (max-width: 361px) {
  .tag{
    font-size: 12px;
  }
  .reply-content {
    background-color: #fff0e8;
    padding: 5px 10px;
    border: 1px solid #d1d1d1;
    border-radius: 10px;
    font-size: 13px;
  }

  .reply-chat-input input {
    flex: 1;
    font-size: 13px;
    height: 40px;
    margin: 0 8px;
    border-radius: 40rem;
  }

  .infinite-scroll-component__outerdiv {
    width: 100%;
  }

  .feedsPostContainer {
    padding: 0px 0px
  }

  .feedsSearch-container {
    width: 100% !important;
    /* margin-bottom: 10%; */
  }

  .feedsContainer {
    padding: 0px;
    margin: 0;
    width: 95%;
  }

  .singlePost {
    height: 180px;
    width: 325px;
  }

  .feedsLeftSide {
    width: auto !important;
    border-radius: unset !important;
    flex-flow: column wrap !important;
    display: flex !important;
    padding: 10px 0px !important;
    background-color: #fff !important;
    overflow: hidden !important;
    align-items: center !important;
    justify-content: center !important;
    height: 100%;
  }

  .reply-content-container {
    max-width: 75%;
    min-width: 50%;
  }
}

.hamburgerTrending {
  display: none;
}

.searchDiv {
  /* background: #9b6f57; */
  height: 44px;
  border: 1px solid #9b6f57;
  width: 545px;
  border-radius: 26px;
  font-size: 20px;
  /* color: white; */
  align-items: center;
}

.searchDiv:hover {
  outline: none !important;
  border: 1px solid #9b6f57;
  box-shadow: none !important;
}

.searchDiv:focus {
  outline: none !important;
  border: 1px solid #9b6f57;
  box-shadow: none !important;
}

.search-container {
  display: flex;
  position: relative;
}

.cancelIcon {
  position: absolute;
  right: 0px;
  color: #9b6f57;
  /* color: white; */
  cursor: pointer;
}

.feedsSearch-container {
  width: 400px;
}

.ant-input-clear-icon {
  font-size: 20px !important;
}

.carousel-container {
  width: 970px !important;
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  padding-bottom: 35px;
  margin-bottom: 10px;
}

/* .carousel-item {
  color: red;
} */

.fitImg {
  object-fit: cover;
  width: 150px;
  height: 120px;
}

.react-multi-carousel-item {
  width: 200px !important;
}

.radio-option {
  font-size: 17px;
}

.radio-option .ant-radio-checked .ant-radio-inner {
  border-color: #0abe51 !important;
  background-color: #0abe511f !important;
}

.radio-option .ant-radio-inner {
  width: 24px !important;
  height: 24px !important;
}

.radio-option .ant-radio-inner::after {
  width: 40px !important;
  height: 40px !important;
  background-color: #0abe51 !important;
  margin-block-start: -20px !important;
  margin-inline-start: -20px !important;
  border-radius: 50% !important;
}


@media (min-width : 601px) and (max-width: 900px) {
  .infinite-scroll-component__outerdiv {
    width: 100%;
  }

  .feedsLeftSide {
    width: 100%;
    height: 100vh;
    background-color: #fafafa;
    padding: 30px;
    border-radius: 23px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .feedsExtendModal {
    width: 70% !important;
  }

  .communityList {
    display: none;
  }

  .headingFeedLabel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 3% 0px;
  }

  .singlePost {
    height: 225px;
    width: 400px
  }

  .msg-chat-input input {
    flex: 1;
    font-size: 15px;
    height: 40px;
    margin: 0 8px;
    border-radius: 40rem;
  }

  .reply-chat-input {
    display: flex;
    padding: 5px 0px 10px 0px;
    align-items: center;
    width: 80%;
    margin-left: 15%;
  }

  .replyComment {
    margin-left: 15%;
  }

  .reply-chat-input input {
    flex: 1;
    font-size: 15px;
    height: 40px;
    margin: 0 8px;
    border-radius: 40rem;
  }

  .msg-comment-input {
    display: flex;
    padding: 22px 10px 0px 10px;
    align-items: center;
  }

  .comment-content-container {
    max-width: 80%;
    min-width: 40%;
  }

  .comment-section {
    padding: 15px 0px;
  }

  .reply-content {
    background-color: #FFF0E8;
    padding: 5px 10px;
    border: 1px solid #d1d1d1;
    border-radius: 10px;
    font-size: 15px;
  }

  .reply-content-container {
    max-width: 75%;
    min-width: 45%;
  }
  .commentRemainingCharacters{
    text-align: end;
    font-weight: 500;
    margin-right: 15%;
    font-size: 10px;
    color: #9b9b9b;
    /* letter-spacing: 0.85px; */
    margin-top: -5px;
  }

}

@media (min-width : 901px) and (max-width: 1100px) {
  .feedsRightSide {
    display: none;
  }

  .infinite-scroll-component__outerdiv {
    width: 100%;
  }

  .feedsLeftSide {
    width: 100%;
    height: 100vh;
    background-color: #fafafa;
    padding: 30px;
    border-radius: 23px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .feedsExtendModal {
    width: 70% !important;
  }

  .communityList {
    display: none;
  }

  .headingFeedLabel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 3% 0px;
  }

  .showMobileIcon {
    display: block;
  }

  .plusCreate {
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 10px;
    z-index: 999;
    right: 12px;
    width: 110px;
    height: 110px;
    /* border-radius: 50% !important;
    background-color: rgb(255, 255, 255);
    border-width: 0.5px;
    border-color: gray; */
  }

  .msg-chat-input input {
    flex: 1;
    font-size: 15px;
    height: 40px;
    margin: 0 8px;
    border-radius: 40rem;
  }

  .reply-chat-input {
    display: flex;
    padding: 5px 0px 10px 0px;
    align-items: center;
    width: 80%;
    margin-left: 15%;
  }

  .replyComment {
    margin-left: 20%;
  }

  .reply-chat-input input {
    flex: 1;
    font-size: 15px;
    height: 40px;
    margin: 0 8px;
    border-radius: 40rem;
  }

  .msg-comment-input {
    display: flex;
    padding: 22px 10px 0px 10px;
    align-items: center;
  }

  .comment-content-container {
    max-width: 85%;
    min-width: 40%;
  }

  .comment-section {
    padding: 15px 0px;
  }

  .reply-content {
    background-color: #FFF0E8;
    padding: 10px 10px;
    border: 1px solid #d1d1d1;
    border-radius: 10px;
    font-size: 15px;
  }

  .reply-content-container {
    max-width: 75%;
    min-width: 45%;
  }
  .commentRemainingCharacters{
    text-align: end;
    font-weight: 500;
    margin-right: 14%;
    font-size: 10px;
    color: #9b9b9b;
    /* letter-spacing: 0.85px; */
    margin-top: -5px;
  }

}

@media (min-width:1200px) and (max-width:1500px) {
  .tag{
    font-size: 10px;
  }
  .feedsLinkContainer{
    margin-left: 25%;
}
.commentRemainingCharacters{
  text-align: end;
  font-weight: 500;
  margin-right: 11%;
  font-size: 10px;
  color: #9b9b9b;
  /* letter-spacing: 0.85px; */
  margin-top: 2px;
}
.modalCommentRemainingCharacters{
  text-align: end;
  font-weight: 500;
  margin-right: 15%;
  font-size: 10px;
  color: #9b9b9b;
  /* letter-spacing: 0.85px; */
  margin-top: -10px;
  padding-bottom: 10px;
}
.postCommentRemainingCharacters{
  text-align: end;
  font-weight: 500;
  margin-right: 10%;
  font-size: 10px;
  color: #9b9b9b;
  /* letter-spacing: 0.85px; */
  margin-top: 2px;
}
  .feedsLeftSide {
    width: calc(100% - 25%);
    height: 100vh;
    background-color: #fafafa;
    /* background-color: ##fafafa; */
    padding: 1rem;
    border-radius: 23px 0px 0px 23px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .feedsRightSide {
    width: 25% !important;
    background-color: white;
    padding: 1rem;
    box-shadow: -2px 0px 5px #cfcfcf57;
    border-radius: 0px 23px 23px 0px;
  }

  .trending-images {
    width: 100%;
    height: 100px !important;
    object-fit: cover;
  }

  .createcommunityButtonWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: 10px;
    padding-top: 5px;
  }

  .communityNames {

    font-size: 14px;
    color: #67702e;
    text-align: center;
    padding-top: 5px;
    line-height: 26px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
  }

  .communityList {
    display: flex;
    flex-direction: row;
    /* flex-wrap: nowrap;
    overflow-x: auto; */
    padding: 0.5rem;
  }

  .feedscreatecommunityButton {
    border-radius: 18px;
    width: 7rem !important;
    height: 6rem !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    /* border: 3px solid red; */
    background: linear-gradient(to bottom, #f0f0f0, #eeeeee);
    transition: transform 200ms;
    margin-top: 5px;
  }

  .feedscreatecommunityButton:hover {
    transform: scale(1.06);
  }

  .feedslistinner {
    width: 7rem;
    /* height: 10rem; */
    padding: 0;
    margin: 0 1rem;
  }

  .react-multi-carousel-item {
    width: 8.5rem !important;
  }

  .fitImg {
    object-fit: cover;
    width: 7rem !important;
    height: 6rem !important;
  }

  .carousel-container {
    width: 40rem !important;
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    padding-bottom: 35px;
    margin-bottom: 10px;
  }

  .ant-input-affix-wrapper-lg {
    padding: 0.15rem 0.5rem;
    font-size: 16px;
    line-height: 1.5;
  }

  .like-share-icon {
    font-size: 1.1rem !important;
    margin-right: 5px;
  }

  .feedsSearch-container {
    width: 20rem;
  }

  .react-multi-carousel-dot button {
    display: inline-block;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    opacity: 1;
    padding: 5px 5px 5px 5px;
    box-shadow: none;
    transition: background 0.5s;
    border-width: 1.5px;
    border-style: solid;
    border-color: grey;
    padding: 0;
    margin: 0;
    margin-right: 6px;
    outline: 0;
    cursor: pointer;
  }

  .feedsPlusButton {
    font-size: 2rem;
  }

  .msg-chat-input input {
    flex: 1;
    font-size: 15px;
    height: 40px;
    margin: 0 8px;
    border-radius: 40rem;
  }

  .msg-comment-input input {
    flex: 1;
    font-size: 15px;
    height: 40px;
    margin: 0 8px;
    border-radius: 40rem;
  }

  .reply-chat-input input {
    flex: 1;
    font-size: 15px;
    height: 40px;
    margin: 0 8px;
    border-radius: 40rem;
  }
  .pollModal{
    zoom: 0.8;
  }
}

@media (width:1400px) {
  .carousel-container {
    width: 45rem !important;
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    padding-bottom: 35px;
    margin-bottom: 10px;
  }

  .react-multi-carousel-item {
    width: 9.5rem !important;
  }

  .feedslistinner {
    width: 8rem;
    /* height: 10rem; */
    padding: 0;
    margin: 0 1rem;
  }

  .fitImg {
    object-fit: cover;
    width: 8rem !important;
    height: 6.5rem !important;
  }

  .feedscreatecommunityButton {
    border-radius: 18px;
    width: 8rem !important;
    height: 6.5rem !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    /* border: 3px solid red; */
    background: linear-gradient(to bottom, #f0f0f0, #eeeeee);
    transition: transform 200ms;
    margin-top: 5px;
  }

  .msg-chat-input input {
    flex: 1;
    font-size: 15px;
    height: 40px;
    margin: 0 8px;
    border-radius: 40rem;
  }

  .msg-comment-input input {
    flex: 1;
    font-size: 15px;
    height: 40px;
    margin: 0 8px;
    border-radius: 40rem;
  }

  .reply-chat-input input {
    flex: 1;
    font-size: 15px;
    height: 40px;
    margin: 0 8px;
    border-radius: 40rem;
  }
}

@media (width:1440px) {
  .modalCommentRemainingCharacters{
    text-align: end;
    font-weight: 500;
    margin-right: 13%;
    font-size: 10px;
    color: #9b9b9b;
    /* letter-spacing: 0.85px; */
    margin-top: -10px;
    padding-bottom: 10px;
  }
  .postCommentRemainingCharacters{
    text-align: end;
    font-weight: 500;
    margin-right: 8%;
    font-size: 10px;
    color: #9b9b9b;
    /* letter-spacing: 0.85px; */
    margin-top: 2px;
  }
  .carousel-container {
    width: 47rem !important;
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    padding-bottom: 35px;
    margin-bottom: 10px;
  }

  .react-multi-carousel-item {
    width: 9.5rem !important;
  }

  .feedslistinner {
    width: 8rem;
    /* height: 10rem; */
    padding: 0;
    margin: 0 1rem;
  }

  .fitImg {
    object-fit: cover;
    width: 8rem !important;
    height: 6.5rem !important;
  }

  .feedscreatecommunityButton {
    border-radius: 18px;
    width: 8rem !important;
    height: 6.5rem !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    /* border: 3px solid red; */
    background: linear-gradient(to bottom, #f0f0f0, #eeeeee);
    transition: transform 200ms;
    margin-top: 5px;
  }

  .msg-chat-input input {
    flex: 1;
    font-size: 15px;
    height: 40px;
    margin: 0 8px;
    border-radius: 40rem;
  }

  .msg-comment-input input {
    flex: 1;
    font-size: 15px;
    height: 40px;
    margin: 0 8px;
    border-radius: 40rem;
  }

  .reply-chat-input input {
    flex: 1;
    font-size: 15px;
    height: 40px;
    margin: 0 8px;
    border-radius: 40rem;
  }
}

@media (min-width:1501px) and (max-width:1900px) {
  
  .tag{
    font-size: 12px;
  }
  .feedsLeftSide {
    width: calc(100% - 25%);
    height: 100vh;
    background-color: #fafafa;
    /* background-color: ##fafafa; */
    padding: 1rem;
    border-radius: 23px 0px 0px 23px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .feedsRightSide {
    width: 25% !important;
    background-color: white;
    padding: 1rem;
    box-shadow: -2px 0px 5px #cfcfcf57;
    border-radius: 0px 23px 23px 0px;
  }

  .trending-images {
    width: 100%;
    height: 125px !important;
    object-fit: cover;
  }

  .communityList {
    display: flex;
    flex-direction: row;
    /* flex-wrap: nowrap;
    overflow-x: auto; */
    padding: 0.5rem;
  }

  .feedscreatecommunityButton {
    border-radius: 18px;
    width: 9rem;
    height: 7rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    /* border: 3px solid red; */
    background: linear-gradient(to bottom, #f0f0f0, #eeeeee);
    transition: transform 200ms;
    margin-top: 5px;
  }

  .feedscreatecommunityButton:hover {
    transform: scale(1.06);
  }

  .feedslistinner {
    width: 9rem;
    /* height: 10rem; */
    padding: 0;
    margin: 0 1rem;
  }

  .react-multi-carousel-item {
    width: 11rem !important;
  }

  .fitImg {
    object-fit: cover;
    width: 9rem !important;
    height: 7rem !important;
  }

  .carousel-container {
    width: 50rem !important;
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    padding-bottom: 35px;
    margin-bottom: 10px;
  }

  .ant-input-affix-wrapper-lg {
    padding: 0.15rem 0.5rem;
    font-size: 16px;
    line-height: 1.5;
  }

  .like-share-icon {
    font-size: 1.1rem !important;
    margin-right: 5px;
  }

  .feedsSearch-container {
    width: 20rem;
  }

  .react-multi-carousel-dot button {
    display: inline-block;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    opacity: 1;
    padding: 5px 5px 5px 5px;
    box-shadow: none;
    transition: background 0.5s;
    border-width: 1.5px;
    border-style: solid;
    border-color: grey;
    padding: 0;
    margin: 0;
    margin-right: 6px;
    outline: 0;
    cursor: pointer;
  }

  .feedsPlusButton {
    font-size: 2rem;
  }

  .msg-chat-input input {
    flex: 1;
    font-size: 15px;
    height: 40px;
    margin: 0 8px;
    border-radius: 40rem;
  }

  .msg-comment-input input {
    flex: 1;
    font-size: 15px;
    height: 40px;
    margin: 0 8px;
    border-radius: 40rem;
  }

  .reply-chat-input input {
    flex: 1;
    font-size: 15px;
    height: 40px;
    margin: 0 8px;
    border-radius: 40rem;
  }
  .pollModal{
    zoom: 0.8;
  }
}