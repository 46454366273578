.admin-kyc-avatar {
    width: 80px;
    height: 80px;
    padding: 4px;
    object-fit: cover;
    border-radius: 50%;
}

.kyc-modal-popup {
    width: 35% !important;
    top: 100px;
    height: 45% !important;
}

.kyc-modal-popup .ant-modal-close {
    color: #7e4f31 !important;
    font-size: larger !important;
}

.kyc-modal-popup .ant-modal-close .ant-modal-close-x {
    color: #7e4f31 !important;
    font-size: 20px;
}

.selectDropdown .ant-select-selector {
    height: 50px !important;
}
.adminCommunityListContainer{
    width:75%
  }
  .adminCommunityList{
    height: 55vh;
    overflow-y: scroll;
  }
  .adminCommunityList::-webkit-scrollbar{
    display: none;
  }
.adminCommunitySearchBar{
    width: 75%!important;
    margin: 10px 0px;
}
.kyc-modal-popup .ant-modal-title {
    border-bottom: 2px solid #eee7e0 !important;
    font-size: 25px !important;
    padding: 10px;
    padding-left: 30px;
    width: 97%;
    white-space: pre;
    overflow: hidden;
    color: #7e4f31 !important;
    height: 60px;
    text-overflow: ellipsis;

}

.kycSelectOptions {
    font-size: 17px !important;
    font-weight: 400;
}

.kyc-modal-popup .ant-select-selector .ant-select-selection-item {
    font-size: 19px;
}
.placeholderText{
    height: 60vh;
}
.placeholderText > p{
    font-size: 22px;
    color: gray;
    font-weight: 500;
}
.adminSettingsSaveButton {
    font-weight: 500;
    background: #686f2e;
    color: white;
    font-size: 19px;
    text-align: center;
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  .adminSettingsSaveButton:disabled {
    background: #686f2e !important;
  opacity: 0.5 !important;
  color: white !important;
  
  }
  .adminSettingsSaveButton:hover {
    background: #686f2e !important;
    color: white !important;
    outline: none !important;
    border: none !important;
  }
  .adminSettingsSaveButtonContainer{
    width: 75%;
  }
  .adminTabsWrapper {
    font-weight: 500;
    letter-spacing: 0.5px;
    font-size: 20px;
    display: flex;
    padding: nonr;
    justify-content: center;
    gap: 25px;
  
  }
  .adminpostMembertitle {
    font-weight: 600;
    font-size: 14px;
    padding-left: 0px;
    display: flex;
    letter-spacing: 1.7px;
    align-items: center;
    text-align: center;
  }
  .activeTab {
    border-bottom:  2px solid #7e4f31;
    color: #7e4f31;
   }
   .optionsTab {
     cursor: pointer;
   }
   .modalmember{
    width: 50px;
    height: 50px;
  }
  .modalmember > img{
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
  .activeDropdown {
    border: 2px solid #06be51 !important;
    border-radius: 20px !important;
  }
  
  .blockedDropdown {
    border: 2px solid #ff0000 !important;
    border-radius: 20px !important;
  }
  .loaderModal {
    width: 100px !important;
    height: 300px !important;
    /* background-color: red !important; */
  }
  .loaderModal {
    display: none;
  }
  .loaderModal .ant-modal-close-x {
    display: none !important;
  }
  .noCommunitiesText{
    font-size: 2rem;
    color: rgb(169, 167, 167);
    padding: 10px 0;
  }

  @media (max-width: 767px) {
    .adminSettingsSaveButton {
        width: auto;
        height: auto;
    
      }
      .adminCommunityListContainer{
        width: 100%;
      }
      .adminCommunitySearchBar{
        width: 100%!important;
        margin: 10px 0px;
    }
    .adminTabsWrapper {
        font-weight: 500;
        letter-spacing: 0.5px;
        font-size: 17px;
        display: flex;
        padding: nonr;
        justify-content: center;
        gap: 25px;
      
      }
      .adminSettingsSaveButtonContainer{
        width: 100%;
        margin: 10px 10px;
      }
      .adminCommunityList{
        height: 65vh;
        overflow-y: scroll;
      }
  }
  @media (max-width: 400px) {
    .adminTabsWrapper {
        font-weight: 500;
        letter-spacing: 0.5px;
        font-size: 15px;
        display: flex;
        padding: nonr;
        justify-content: center;
        gap: 25px;
      
      }
      .adminpostMembertitle {
        font-weight: 500;
        font-size: 12px;
        padding-left: 0px;
        display: flex;
        letter-spacing: 1.7px;
        align-items: center;
        text-align: start;
      }
      .adminCommunityList{
        height: 60vh;
        overflow-y: scroll;
      }
  }
  @media (min-width:1200px) and (max-width:1500px){
    .dpnamecontainer{
        zoom: 0.75;
    }
    .adminTabsWrapper {
        font-weight: 500;
        letter-spacing: 0.5px;
        font-size: 15px;
        display: flex;
        padding: nonr;
        justify-content: center;
        gap: 25px;
      
      }
      .noCommunitiesText{
        font-size: 1.5rem;
        color: rgb(169, 167, 167);
        padding: 10px 0;
      }
      .adminSettingsSaveButton {
        font-weight: 500;
        background: #686f2e;
        color: white;
        font-size: 15px;
        text-align: center;
        width: 70px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        margin-top: 10px;
      }
  }

  @media (min-width:1501px) and (max-width:1900px){
    .dpnamecontainer{
        zoom: 0.85;
    }
    .adminTabsWrapper {
        font-weight: 500;
        letter-spacing: 0.5px;
        font-size: 15px;
        display: flex;
        padding: 5px 0 0 0;
        justify-content: center;
        gap: 25px;
      
      }
      .noCommunitiesText{
        font-size: 1.75rem;
        color: rgb(169, 167, 167);
        padding: 10px 0;
      }
      .adminSettingsSaveButton {
        font-weight: 500;
        background: #686f2e;
        color: white;
        font-size: 17px;
        text-align: center;
        width: 80px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        margin-top: 10px;
      }
  }