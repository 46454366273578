.userfeedsContainer {
    display: flex;
    flex-direction: row; /* Make sure the containers are next to each other horizontally */
    width: 100%;
  }
  
  .userfeedsLeftSide {
    width: 73%;
    height: 100%;
    background-color: white;
    padding: 0px;
    border-radius: 0px 23px 23px 0px;
    overflow: auto;
    min-height: 750px;
    padding:0px 20px 0px 20px
  }
  .antdInterests .ant-row{
    align-items: center;
  }
  
  .editButton > a:hover{
    color: #676F2E
  }

  .emailAlert{
    position: absolute;
    margin-top: 5px;
    z-index: 999;
  }
  
  .userfeedsRightContainer {
    flex: 1;
    order: -1;
    position: sticky;
    top: 32px;
    /* height: calc(100vh - 32px); */
    min-height: 750px;
  }
  
  .userfeedsRight {
    /* background-color: #5a5a5a; */
    border-radius: 23px 0px 0px 23px;
    width: 98%;
    height: 100%;
    min-height: 750px;
    margin-right: 0.5rem;
  }
  
  /* Add the rest of your CSS as needed */
  
    
    .userfeedsRightSideTop {
        width: 415px;
        background-color: white;
        padding: 30px 1rem 30px 30px;
        box-shadow: -2px 0px 5px #cfcfcf57;
        border-radius: 23px 0px 0px 0px;
        position: sticky;
        /* top: 32px; */
        /* min-height: 340px; */
        max-height: 460px;
        /* min-height: 450px; */
        /* height: 100vh; */
        right: 0px;
        bottom: 0px;
        overflow-y: scroll;
        padding-top: 25px;
        padding-bottom: 30px;
      }
    
      .userfeedsRightSideMiddle {
        width: 415px;
        background-color: white;
        padding: 30px;
        box-shadow: -2px 0px 5px #cfcfcf57;
        border-radius: 0px 0px 0px 0px;
        overflow-y: scroll;
        max-height: 460px;
        margin-top: 10px;
        position: sticky;
        padding-top: 40px;
      }
      .mobilefeedsRightSideMiddle {
        width: 100%;
        background-color: white;
        /* padding: 30px; */
        box-shadow: -2px 0px 5px #cfcfcf57;
        border-radius: 0px 0px 0px 0px;
        overflow-y: scroll;
        max-height: 460px;
        margin-top: 10px;
        position: sticky;
        padding-top: 10px;
      }
    
    
      .userfeedsRightSideTop::-webkit-scrollbar,
    .userfeedsRightSideMiddle::-webkit-scrollbar {
      width:8px; /* Width of the scrollbar */
      height:40px;
    }
    
    /* Customize the scrollbar thumb (handle) */
    .userfeedsRightSideTop::-webkit-scrollbar-thumb,
    .userfeedsRightSideMiddle::-webkit-scrollbar-thumb {
      background-color: #e1e0e0; /* Color of the thumb */
      border-radius: 10px; /* Rounded corners for the thumb */
    }
    
    /* Customize the scrollbar track (background) */
    .userfeedsRightSideTop::-webkit-scrollbar-track,
    .userfeedsRightSideMiddle::-webkit-scrollbar-track {
      background-color: #f7f7f700; /* Color of the track */
      border-radius: 10px; /* Rounded corners for the track */
    }
    
    /* You can also add similar styles for Firefox */
    /* .userfeedsRightSideTop {
      scrollbar-color: #333 #F7F7F7; /* Thumb and track colors for Firefox */
    /* } */
    
    /* .userfeedsRightSideMiddle {
      scrollbar-color: #333 #F7F7F7; /* Thumb and track colors for Firefox */
    /* }  */
      .userRightSideTextLabel {
        color: #040100;
        font-size: 20px;
        margin-bottom: 20px;
        font-weight: 700;
        line-height: 30px;
      }
    
      .headtop {
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        font-family: "poppins", sans-serif;
      }
    
      .usercontainerWithOutSideBar {
        padding: 50px 0;
      }
    
      .mainPreviewContentCol {
        background-color: #f7f7f7;
        margin: 0px auto;
        width: calc(100% - 100px);
        border-radius: 10px;
        padding: 0px;
        min-height: calc(100vh - 215px);
      }
      .userfeedsPostContainer {
        margin:0px 0px 0px 0px;
        min-width:100%
    }
    
    .greenBorder {
      border: 2px solid green;
    }
    .editProfilepopupButton {
      font-weight: 500;
      background: #686f2e;
      color: white;
      font-size: 19px;
      text-align: center;
      width: 100px;
      height: 40px;
      margin: 5px 0px;
    }
    .editProfilepopupButton:hover {
      background: #686f2e !important;
    }
    .disabled {
      opacity: 0.2;
    }
    .profileBrowseButton {
      border: 2px solid #686f2e;
      background: none;
      color: black;
      font-weight: 500;
      font-size: 15px;
      text-align: center;
      /* width: 110px; */
      height: 35px;
      /* text-align: center; */
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .profileBrowseButton:hover {
      background-color: white !important ;
      color: white !important;
    }
    .ProfilePopupButton {
      font-weight: 500;
      background: #686f2e;
      color: white;
      font-size: 15px;
      text-align: center;
      /* width: 110px; */
      /* height: 50px; */
      /* margin: 30px 0px; */
    }
    .ProfilePopupButton:hover {
      background: #686f2e !important;
    }

    .profileContainer{
      min-height: 700px;
    }
    .popConfirm .ant-btn-primary {
      background-color: #686f2e !important;
    }
   
     /* .ant-popover-content {
      position: absolute !important;
      width: 300px !important;
      right: 0px !important;
    } */
    .popConfirmYes {
        width: 100% !important;
      background-color: #686f2e !important;
    }
    .ant-btn-primary {
      background: #686f2e;
    }
    .ant-btn-primary::selection{
      background: #686f2e;
    }
    .updateProfilePopConfirm  .ant-popover-content {
      position: absolute !important;
      width: 300px !important;
      right: 0px !important;
    
    }
    .Profilephoneinputwrapper .react-tel-input .selected-flag {
      outline: none;
      position: relative;
      width: 52px;
      height: 100%;
      padding: 0 0 0 7px;
      border-radius: 3px 0 0 3px}
    .Profilephoneinputwrapper .react-tel-input .form-control {
      font-size: 14px!important;
      height: 3px !important;
      margin-left: 50px !important;
      padding-left: 7px !important;
      width: 175px !important;
  }
    .profileImgContainer{
      display: flex;
      flex-direction: column;
      position: relative;
      bottom: 100px;
      left: 20px;
    }
    .profileImage{
      width: 200px;
      height: 200px;
      border-radius: 50%;
      border-width: 1px;
      border-color: white;
    }
    .dropdownProfileImage{
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border-width: 1px;
      border-color: white;
    }
    .dropdown-search-results{
      position: absolute;
    background-color: white;
    width: fit-content;
    margin: 5px 0px 0px 0px;
    padding: 10px 15px;
    border-radius: 10px;
    max-height: 200px;
    overflow-y: scroll;
    z-index: 10;
    }
    .dropdown-search-results::-webkit-scrollbar {
      width: 12px; /* Width of the scrollbar */
    }
    
    /* Customize the scrollbar thumb (handle) for .search-results */
    .dropdown-search-results::-webkit-scrollbar-thumb {
      background-color: #d4d3d3; /* Color of the thumb */
      border-radius: 10px; /* Rounded corners for the thumb */
    }
    
    /* Customize the scrollbar track (background) for .search-results */
    .dropdown-search-results::-webkit-scrollbar-track {
      background-color: #f7f7f7; /* Color of the track */
      border-radius: 10px; /* Rounded corners for the track */
    }
    
    /* You can also add similar styles for Firefox */
    .dropdown-search-results {
      scrollbar-color: #333 #f7f7f7; /* Thumb and track colors for Firefox */
    }
    .profileCover{
      width: 100%;
      object-fit: cover;
      height: 400px;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
    }
    .profileModal{
      padding: 12px 20px
    }
    .profileForm{
      padding:24px
    }
    .contactForm{
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .modalHeader{
      font-size: 24px; /* 24px */
line-height: 32px; /* 32px */
      font-weight: bold;
      text-align: center;
      padding-top: 20px;
      padding-bottom: 8px;
    }
    .formHeading{
      font-size: 20px; /* 20px */
line-height: 28px; /* 28px */
font-weight: bold;
    }
    .ant-select .ant-select-arrow .anticon >svg{
      font-size: 15px;
    }
    .dropdownName{
      font-size: 15px;
      font-weight: 600;
    }
    .dropdownEmail{
      font-size: 12px;
      font-weight: 400px;
    }
    .familymember .ant-select-single  {
      display: flex;
      align-items: center;
    }
    .familymember .ant-select-clear .anticon .anticon-close-circle{
      margin-right: 20px!important;
    }
    .familymember .ant-select-clear{
      margin-right: 25px!important;
      font-size: 15px;
    }
    .relationship  .ant-select-clear{
      margin-right: 25px!important;
      font-size: 15px;
    }
    .familymember .ant-select-single:not(.ant-select-customize-input) .ant-select-selector  {
      height: min-content;
      cursor: pointer;
    }
    .relationship{
      margin-bottom: 30px;
    }
    .familymemberOption{
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    .profileName{
      font-size: 2.25rem; /* 36px */
line-height: 2.5rem; /* 40px */
margin-left: 2rem; /* 32px */
margin-right: 2rem; /* 32px */
margin-top: 2.5rem; /* 40px */
    }
    .introItem{
      font-size: 1.125rem; /* 18px */
line-height: 1.75rem; /* 28px */
/* margin-top: 0.75rem;  */
    }
    .familyMemberThumbnail{
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    .familyMemberRelationship{
      font-size: 0.875rem; /* 14px */
line-height: 1.25rem; /* 20px */
    }
    .familyMemberName{
      font-size: 1rem;
    }
    .userHasLockedProfile{
      font-size: 1.125rem; /* 18px */
line-height: 1.75rem; /* 28px */
    }
    .privateAccount{
      font-size: 2.25rem; /* 36px */
line-height: 2.5rem; /* 40px */
    }

    .profileModal .ant-select-selection-item{
    font-size: 14px;
    font-family: "Poppins", sans-serif;
}
.ant-btn-default.ant-btn-dangerous {
  color: white;
  border-color: #ff4d4f;
  background-color: #ff4d4f;
}
.deleteUserButton{
  color:#ff4d4f;
  font-size: medium;
  height: 40px;
}
.deleteYesButton > span:hover{
  color: #f7f7f7;
}
.deleteYesButton :hover{
  color: #f7f7f7;
}
.desktop-only {
  display: flex;
}

    @media(max-width:896px ){
      .desktop-only {
        display: none;
      }
      .backBtn{
        display: none;
      }
      .updateprofilechild{
        flex-direction: column!important;
      }
      .profileImgContainer{
        display: flex;
        flex-direction: column;
        position: relative;
        bottom: 50px;
        left: 5px;
      }
      .joingroupProfile button {
        border-radius: 25px;
        height: 35px;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 5px;
        transform: scale(0.8);
    }
      .profileImage{
        width: 120px;
        height: 120px;
        border-radius: 50%;
        border-width: 1px;
        border-color: white;
      }
      .usercontainerWithOutSideBar{
        padding-top: 0px;
        padding-bottom: 0px;
      }
      .feedsRightSideMiddle{
        display: none;
      }
      .userfeedsRight {
        /* background-color: #5a5a5a; */
        border-radius: 0px 23px 0px 0px;
        width: 420px;
        height: auto;
        min-height: auto;
      }
      .userfeedsRightSideTop {
        width: 420px;
        background-color: white;
        /* padding:0px 10px 10px 10px; */
        padding-right: 0px!important;
        padding-left: 0px!important;
        box-shadow: -2px 0px 5px #cfcfcf57;
        border-radius: 0px 23px 0px 0px;
        position: sticky;
        /* top: 32px; */
        /* min-height: 340px; */
        max-height: 460px;
        /* min-height: 450px; */
        /* height: 100vh; */
        right: 0px;
        bottom: 0px;
        overflow-y: scroll;
        margin-top: -25px;
        padding-bottom: 30px;
      }
      .userfeedsLeftSide {
        width: 100%;
        height: 100%;
        background-color: white;
        padding: 0px;
        border-radius: 23px 0px 0px 23px;
        overflow: auto;
        min-height: 750px;
      }
      .userRightSideTextLabel {
        color: #040100;
        font-size: 20px;
        margin-bottom: 5px;
        font-weight: 700;
        line-height: 30px;
      }
      .profileCover{
        width: 100%;
        object-fit: cover;
        height: 200px;
        border-top-right-radius: 0px; 
         border-top-left-radius: 0px;
      }
      .profileModal{
        padding: 5px 10px
      }
      .profileForm{
        padding:15px 0px
      }
      .contactForm{
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .modalHeader{
        font-size: 20px; /* 24px */
  line-height: 32px; /* 32px */
  padding-bottom: 4px;
      }
      .formHeading{
        font-size: 18px; /* 20px */
  line-height: 28px; /* 28px */
  font-weight: bold;
      }
      .selectOptions{
        font-size: 15px!important;
      }
      .profileContainer{
        min-height: 0px;
      }
      .Profilephoneinputwrapper .react-tel-input .form-control {
        font-size: 14px!important;
        height: 3px !important;
        margin-left: 50px !important;
        padding-left: 7px !important;
        width: 240px !important;
    }
   .familymember { .ant-row .ant-form-item-row .css-dev-only-do-not-override-8wu13p  {.ant-col .ant-form-item-label .css-dev-only-do-not-override-8wu13p{
      margin-bottom: 20px!important;}
    }}
    .familymemberOption{
      margin-top: 0;
      margin-bottom: 0;
    }
 
  
    }
    @media (min-width : 601px) and (max-width: 900px){
      .usercontainerWithOutSideBar {
        padding-top: 5%;
        padding-bottom: 5%;
    }
    .profileCover {
      width: 100%;
      object-fit: cover;
      height: 200px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
  }
    }
    @media (min-width : 901px) and (max-width: 1100px){
      .profileImageThumbnail{
        width: 200px!important;
        height: 200px!important;
        border-radius: 50%!important;
      }
      .profileInfo{
        margin-left: 2%!important;
      }
      .joinProfileButton{
        border-radius: 50px!important;
      }
      
    }
 .ant-form-item .ant-form-item-explain-error {
      font-size: 15px;
      margin-bottom: 5px;
  }

  @media (min-width:1200px) and (max-width:1500px){
    .headtop {
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.5rem;
      font-family: "poppins", sans-serif;
    }
    .profileCover{
      width: 100%;
      object-fit: cover;
      height: 275px;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
    }
    .usercontainerWithOutSideBar {
      padding: 2.5rem 0;
    }
    .profileImage{
      width: 150px;
      height: 150px;
      border-radius: 50%;
      border-width: 1px;
      border-color: white;
    }
    .profileImgContainer{
      display: flex;
      flex-direction: column;
      position: relative;
      bottom: 5rem;
      left: 20px;
    }
    .profileName{
      font-size: 1.75rem; /* 36px */
line-height: 1.75rem; /* 40px */
margin-left: 2rem; /* 32px */
margin-right: 2rem; /* 32px */
margin-top: 2.5rem; /* 40px */
    }
    .introItem{
      font-size: 0.9rem; /* 18px */
line-height: 1.75rem; /* 28px */
/* margin-top: 0.75rem;  */
    }
    .userRightSideTextLabel {
      color: #040100;
      font-size: 1rem;
      margin-bottom: 1rem;
      font-weight: 700;
      line-height: 1.2rem;
    }
    .dropdownProfileImage{
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      border-width: 1px;
      border-color: white;
    }
    .familyMemberThumbnail{
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
    }
    .familyMemberRelationship{
      font-size: 0.65rem; /* 14px */
line-height: 1.25rem; /* 20px */
    }
    .familyMemberName{
      font-size: 0.85rem;
    }
    .userfeedsLeftSide {
      width: calc(100% - 30%);
      height: 100%;
      background-color: white;
      padding: 0px;
      border-radius: 0px 23px 23px 0px;
      overflow: auto;
      min-height: 750px;
      padding:0px 20px 0px 20px
    }
    
    .userfeedsRight {
      /* background-color: #5a5a5a; */
      border-radius: 23px 0px 0px 23px;
      width: 98%;
      height: 100%;
      min-height: 750px;
      margin-right: 0.5rem;
    }
    
    /* Add the rest of your CSS as needed */
    
      
      .userfeedsRightSideTop {
          width: 100%;
          background-color: white;
          padding: 30px 1rem 30px 30px;
          box-shadow: -2px 0px 5px #cfcfcf57;
          border-radius: 23px 0px 0px 0px;
          position: sticky;
          /* top: 32px; */
          /* min-height: 340px; */
          max-height: 460px;
          /* min-height: 450px; */
          /* height: 100vh; */
          right: 0px;
          bottom: 0px;
          overflow-y: scroll;
          padding-top: 25px;
          padding-bottom: 30px;
        }
      
        .userfeedsRightSideMiddle {
          width: 100%;
          background-color: white;
          padding: 30px;
          box-shadow: -2px 0px 5px #cfcfcf57;
          border-radius: 0px 0px 0px 0px;
          overflow-y: scroll;
          max-height: 460px;
          margin-top: 10px;
          position: sticky;
          padding-top: 40px;
        }
        .userHasLockedProfile{
          font-size: 1rem; /* 18px */
    line-height: 1.75rem; /* 28px */
        }
        .privateAccount{
          font-size: 1.75rem; /* 36px */
    line-height: 2.5rem; /* 40px */
        }
        .modalHeader{
          font-size: 1.25rem; /* 24px */
    line-height: 32px; /* 32px */
          font-weight: bold;
          text-align: center;
          padding-top: 20px;
          padding-bottom: 8px;
        }
        .formHeading{
          font-size: 1.1rem; /* 20px */
    line-height: 28px; /* 28px */
    font-weight: bold;
        }
        .editProfilepopupButton {
          font-weight: 500;
          background: #686f2e;
          color: white;
          font-size: 12px;
          text-align: center;
          width: 80px;
          height: 35px;
          margin: 5px 0px;
        }
        .deleteUserButton{
          color:#ff4d4f;
          font-size: 12px;
          height: 35px;
        }
        .religionDropdown > .ant-select-selector > .ant-select-selection-item {
          font-size: 14px!important;
      }
        .casteDropdown > .ant-select-selector > .ant-select-selection-item {
          font-size: 14px!important;
      }
        .nameDropdown > .ant-select-selector > .ant-select-selection-item {
          font-size: 14px!important;
      }
        .relationshipDropdown > .ant-select-selector > .ant-select-selection-item {
          font-size: 14px!important;
      }
  }
  @media (min-width:1501px) and (max-width:1900px){
    .headtop {
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.5rem;
      font-family: "poppins", sans-serif;
    }
    .profileCover{
      width: 100%;
      object-fit: cover;
      height: 275px;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
    }
    .usercontainerWithOutSideBar {
      padding: 2.5rem 0;
    }
    .profileImage{
      width: 150px;
      height: 150px;
      border-radius: 50%;
      border-width: 1px;
      border-color: white;
    }
    .profileImgContainer{
      display: flex;
      flex-direction: column;
      position: relative;
      bottom: 5rem;
      left: 20px;
    }
    .profileName{
      font-size: 1.75rem; /* 36px */
line-height: 1.75rem; /* 40px */
margin-left: 2rem; /* 32px */
margin-right: 2rem; /* 32px */
margin-top: 2.5rem; /* 40px */
    }
    .introItem{
      font-size: 0.9rem; /* 18px */
line-height: 1.75rem; /* 28px */
/* margin-top: 0.75rem;  */
    }
    .userRightSideTextLabel {
      color: #040100;
      font-size: 1rem;
      margin-bottom: 1rem;
      font-weight: 700;
      line-height: 1.2rem;
    }
    .dropdownProfileImage{
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      border-width: 1px;
      border-color: white;
    }
    .familyMemberThumbnail{
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
    }
    .familyMemberRelationship{
      font-size: 0.65rem; /* 14px */
line-height: 1.25rem; /* 20px */
    }
    .familyMemberName{
      font-size: 0.85rem;
    }
    .userfeedsLeftSide {
      width: calc(100% - 30%);
      height: 100%;
      background-color: white;
      padding: 0px;
      border-radius: 0px 23px 23px 0px;
      overflow: auto;
      min-height: 750px;
      padding:0px 20px 0px 20px
    }
    
    .userfeedsRight {
      /* background-color: #5a5a5a; */
      border-radius: 23px 0px 0px 23px;
      width: 98%;
      height: 100%;
      min-height: 750px;
      margin-right: 0.5rem;
    }
    
    /* Add the rest of your CSS as needed */
    
      
      .userfeedsRightSideTop {
          width: 100%;
          background-color: white;
          padding: 30px 1rem 30px 30px;
          box-shadow: -2px 0px 5px #cfcfcf57;
          border-radius: 23px 0px 0px 0px;
          position: sticky;
          /* top: 32px; */
          /* min-height: 340px; */
          max-height: 460px;
          /* min-height: 450px; */
          /* height: 100vh; */
          right: 0px;
          bottom: 0px;
          overflow-y: scroll;
          padding-top: 25px;
          padding-bottom: 30px;
        }
      
        .userfeedsRightSideMiddle {
          width: 100%;
          background-color: white;
          padding: 30px;
          box-shadow: -2px 0px 5px #cfcfcf57;
          border-radius: 0px 0px 0px 0px;
          overflow-y: scroll;
          max-height: 460px;
          margin-top: 10px;
          position: sticky;
          padding-top: 40px;
        }
        .userHasLockedProfile{
          font-size: 1rem; /* 18px */
    line-height: 1.75rem; /* 28px */
        }
        .privateAccount{
          font-size: 1.75rem; /* 36px */
    line-height: 2.5rem; /* 40px */
        }
        .modalHeader{
          font-size: 1.25rem; /* 24px */
    line-height: 32px; /* 32px */
          font-weight: bold;
          text-align: center;
          padding-top: 20px;
          padding-bottom: 8px;
        }
        .formHeading{
          font-size: 1.1rem; /* 20px */
    line-height: 28px; /* 28px */
    font-weight: bold;
        }
        .editProfilepopupButton {
          font-weight: 500;
          background: #686f2e;
          color: white;
          font-size: 15px;
          text-align: center;
          width: 80px;
          height: 35px;
          margin: 5px 0px;
        }
        .deleteUserButton{
          color:#ff4d4f;
          font-size: 15px;
          height: 35px;
        }
  }