@media(max-width:896px){
   .profileContainer{
    display: flex;
    justify-content: center;
    flex-flow: column wrap;
    width: 100%;
    min-height: 0px!important;
   } 
   .userfeedsContainer {
    display: flex;
    flex-flow: column wrap;
    flex-direction: column;
    width: auto;
}
.userfeedsLeftSide {
    /* width: auto; */
    height: auto;
    background-color: white;
    padding: 0px;
    border-radius: 23px 0px 0px 23px;
    overflow: auto;
    min-height: auto;
    /* padding: 0px 10px 0px 10px; */
}
.userfeedsRightContainer {
    flex: auto;
    order: -1;
    position: relative;
    top: 32px;
    /* height: calc(100vh - 32px); */
    min-height: auto;
}

.backBtn{
        position: absolute;
        width: 7%!important;
        margin: 2%!important;
        background-color: white;
        border-radius: 50%;
        cursor: pointer;
    
}
.multistepwrapper .backBtn {
  z-index: 99;
  position: absolute;
  top: 0;
  position: relative;
  width: auto!important;
  margin: 2%!important;
  background-color: #686f2e!important;
  border-radius: 5px!important;
  cursor: pointer;
}
.profileImgContainer{
    align-items: center!important;
    flex-flow: column wrap!important;
    width: auto!important;
}
.mt1{
    align-items: center;
    display: flex;
    flex-direction: column;  
}
.joingroup {
  margin-top: 13px;
  align-items: baseline;
  padding: 0px 0;
  width: auto;
}
.slider {
    width: 0;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    background-color: #f1f1f1;
    z-index: 1;
  }
  
  .slider.open {
    width: 250px; /* Adjust the width as needed */
  }
  
  .slider.closed {
    width: 0;
  }
  .aboutwrap {
    flex-direction: column;
    align-items: baseline;
    justify-content: space-evenly;
}
  .aboutwrap div{
    margin: 0;
    font-size: 11px;
  }
  .communitypreviewdesc p.text-xl.mt-3.mb-5.mx-5{
    margin: 10px 15px;
  }
}