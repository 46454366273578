.communityPopup {}

.modalPopup {
  width: 1000px !important;
  height: 680px !important;
  padding: 0px !important;
}

.modalPopup .ant-modal-title {
  border-bottom: 2px solid #eee7e0 !important;
  font-size: 25px !important;
  padding: 15px;
}

.modalPopup .ant-modal-close {
  color: #7e4f31 !important;
  font-size: larger !important;
}

.modalPopup .ant-modal-close .ant-modal-close-x {
  color: #7e4f31 !important;
  font-size: 25px;
}

.communityForm {
  padding: 20px 35px 0px 40px;

}

.labelName {
  font-size: 30px !important;
  font-weight: 700;
  line-height: 30px;
  color: red !important;
  font-family: "poppins", sans-serif;
}

.labelName label {
  font-size: 19px !important;
  font-weight: 700;
  line-height: 30px;
  /* border: 3px solid yellow; */
  min-width: 300px !important;
}

.inputBox {
  /* width: 850px !important; */
  height: 50px;
  border: 1px solid #7e4f31;
}

.inputBoxForImage {
  width: 100% !important;
  margin: 0px;
  font-size: 22px;
  font-weight: 400;
}

.communityForm .ant-row {
  align-items: center !important;
}

.communityDescription {
  width: 720px !important;
  height: 120px !important;
  border: 1px solid #7e4f31;
}

.communityDropDown {
  /* width: 720px !important; */
  height: 50px;
  border-radius: 4px;
}

.communityDropDown .ant-select-selector {
  height: 100% !important;
  width: 100% !important;
  border: 1px solid #7e4f31 !important;
}

.communityDropDown .ant-select-selector .ant-select-selection-item {
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 22px;
}

.selectOptions {
  font-size: 19px !important;
  font-weight: 400;
}

.ant-select-suffix {
  font-size: 20px;
  font-weight: 500;
}

.popupButton {
  font-weight: 500;
  background: #686f2e;
  color: white;
  font-size: 19px;
  text-align: center;
  width: 110px;
  height: 50px;
  margin: 30px 0px;
}

.popupButton:hover {
  background: #686f2e !important;
}

.BrowseButton {
  border: 2px solid #686f2e;
  background: none;
  color: black;
  font-weight: 500;
  font-size: 19px;
  text-align: center;
  width: 110px;
  height: 50px;
}

.BrowseButton:hover {
  background-color: white !important;
  color: black !important;
}

.community-image .ant-form-item-control-input-content {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-left: 4px;
}

.labelName .ant-form-item-label {
  /* margin-left: 100px; */
}

.popupWidth {
  width: 100%;
  /* width: 850px !important; */
  font-size: 22px;
  font-weight: 400;
}

.ant-form-item .ant-form-item-explain-error {
  font-size: 20px;
  margin-bottom: 5px;
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

/* SurveyJS CSS */
.sv_main .sv_row {
  background-color: white !important;
}

.sv_main .sv_body {
  border-top: 0px !important;
}

.sv_q_dropdown_control,
.sv_q_text_root {
  height: 40px !important;
  align-items: center;
}
.sv_q_dropdown__hint-prefix{
  display: none;
}

/* .sv_q_dropdown_control:focus,
.sv_q_text_root:focus {
  border: 1px solid #7e4f31 !important;
} */

.sv_main .sv_p_root .sv_q_title {
  font-size: 18px !important;
}

.sv_main .sv_p_root .sv_q .sv_select_wrapper:before {
  height: 100%;
  background-color: #7e4f31 !important;
  top: 0px !important;
}

.sv_main.sv_default_css .sv_q_dropdown_control:focus {
  border-color: #7e4f31 !important;
}

.sv_main.sv_default_css input[type=button],
.sv_main.sv_default_css button {
  background-color: #7e4f31 !important;
}
.backbtn{
  font-weight: 700;
}
@media (max-width : 767px){
  .BrowseButton{
    color: #fff;
  }
  .communityForm {
    padding: 0 15px;
}
  .popupButton{
    display: inline-block!important;
  }
  .createbtn .ant-col.ant-col-offset-21.ant-form-item-control{
    display: inline-block;
    margin: 0;
    float: none;
  }
  .communityPopup {
    padding: 0;
    display: block;
    position: absolute!important;
    width: 100%!important;
    right: 0;
    background: #fff!important;
    top: 0!important;
    margin: 0;
    left: 0;
    z-index: 9999;
}
/* .sv-popup__content {
  z-index: 9999999999 !important;
  position: relative;
  border: 2px solid blue;
} */
/* .sv_p_root { */
    /* z-index: 9999999 !important;
    position: relative; */
    /* border: 2px solid blue; */
/* } */

/* .sv-list {
  z-index: 99999999 !important;
  position: relative;
}
.sv_row {
  position: relative !important;
  z-index: 2 !important;
} */
/* .sv_row {
  z-index: 0 !important;
  position: relative;
}
.surveyForm {
  z-index: 999999 !important;
  position: relative;
} */
.modalPopup .ant-modal-title {
  border-bottom: 2px solid #eee7e0 !important;
  font-size: 25px !important;
  padding: 5px;
  background: #d7d7d7;
}
.ant-wave .css-dev-only-do-not-override-pr0fja .wave-motion-appear .wave-motion-appear-active .wave-motion{
  display: none!important;
}
/* .sv-popup__container{
  left:inherit!important;
  top:inherit!important;
  height:100vh!important;
  width:inherit!important;
  min-width: inherit!important;
} */
/* .sv-popup--overlay .sv-list__filter {
}
.sv-popup--overlay .sv-popup__container{
  padding-top: 0px!important;
  z-index: 999!important;
  position: absolute!important;
}
.sv-popup--overlay .sv-popup__body-content{
  border-radius: 0px!important;
  z-index: 999!important;
}
.sv-list__item.sv-list__item--focused .sv-list__item-body {
  background-color: #1ab394!important;
} */
/* .sv-list__filter-icon { */
/* display: block!important;
position: absolute!important;
top: 3%!important;
z-index: 10!important;
right: 5px!important;
left: 90%; */
/* display: none; */

/* } */
.labelName label {
font-size: 18px !important;
font-weight: 600;
min-width: 300px !important;
}
/* .sv-list__filter-clear-button{
display: none;
}
.sv_main .sv_p_root .sv_q input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]), .sv_main .sv_p_root .sv_q select {
height: calc(2em + 16px);
} */
}
.communityPopup {
  background: #f7f7f7;
  position: relative;
  top: -50px;
}